import { Box, Text } from 'native-base'
import React from 'react'

type BannerProps = {
  message: string
}

export const Banner: React.FC<BannerProps> = ({ message }) => {
  return (
    <Box width="100%" backgroundColor="blueNight" paddingY={2} zIndex={1}>
      <Text color="white" fontSize="md" textAlign="center">
        {message}
      </Text>
    </Box>
  )
}
