import { LinearGradient } from 'expo-linear-gradient'
import { View, Text, Box } from 'native-base'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleProp, ViewStyle } from 'react-native'

import MaintenanceSvg from 'components/icons/Maintenance'
import { IS_WEB } from 'services/platform'

const GRADIENT = !IS_WEB ? 'rgba(204,209,240,0.9)' : 'rgba(0,0,0,0)'

export const MaintenanceScreen: React.FC = () => {
  const { t } = useTranslation()
  return (
    <View style={viewStyle} width="100%" minW="300px" maxW="800px">
      <LinearGradient
        style={gradientStyle}
        colors={[GRADIENT, 'transparent']}
        start={{ x: 0, y: 1 }}
        end={{ x: 0, y: 0.3 }}>
        <Box h={20} />
        <Text fontSize="4xl" color="blueNight" fontWeight="medium">
          {t('maintenance.title')}
        </Text>
        <Box h={20} />
        <MaintenanceSvg />
        <Box h={20} />
        <Text fontSize="lg" color="blueNight" textAlign="center">
          {t('maintenance.message')}
        </Text>
      </LinearGradient>
    </View>
  )
}

const viewStyle: StyleProp<ViewStyle> = {
  flex: 1,
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingTop: 20,
}

const gradientStyle: StyleProp<ViewStyle> = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 20,
  paddingRight: 20,
}
