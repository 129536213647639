import { useState } from 'react'
import { useQuery } from 'react-query'

import { useApi } from 'api'
import { ContractBalance, Organization } from 'api/gen'
import { QueryKey } from 'services/queryClient'

export function useGetContractBalance(
  contractId: string | undefined,
  organizationId: string | undefined
): { balance: ContractBalance | null; payPeriodStart: number | null; isLoading: boolean } {
  const [balance, setBalance] = useState<ContractBalance | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [organization, setOrganization] = useState<Organization | null>(null)
  const api = useApi()

  useQuery(
    QueryKey.ContractBalance,
    async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const result = await api.contractControllerGetBalance(contractId!)
      setBalance(result)
      setIsLoading(false)
    },
    {
      onError: (error) => {
        console.error(error)
        setIsLoading(false)
      },
      enabled: Boolean(contractId),
    }
  )

  useQuery(
    QueryKey.Organization,
    async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const result = await api.getOneBaseOrganizationControllerOrganization(organizationId!)
      setOrganization(result)
    },
    {
      onError: (error) => {
        console.error(error)
      },
      enabled: Boolean(organizationId),
    }
  )

  return { balance, payPeriodStart: organization?.payPeriodStart || null, isLoading }
}
