import { Translations } from './types'

export const frTranslations: Translations = {
  version: 'Version : {{version}}',
  error: {
    title: 'Erreur',
    invalidEmail: 'Veuillez saisir une adresse email valide',
    invalidPassword: 'Veuillez saisir un mot de passe valide',
    generic: 'Il y a eu un problème. Veuillez réessayer plus tard.',
    auth: "Votre email ou mot de passe n'est pas valide, veuillez ré-essayer",
    serviceDeactivated:
      'Votre compte a été temporairement désactivé par votre entreprise. Veuillez vous rapprocher de votre responsable RH.',
  },
  errorBoundary: {
    global: {
      goToWebApp: "Aller sur l'application web",
      issueWithNativeApp: "Un problème avec l'application mobile ?",
      issueWithWebApp: "Un problème avec l'application ?",
      contactSupport: "Veuillez contacter l'équipe support de NessPay.",
    },
  },
  success: {
    title: 'Succès',
  },
  signIn: {
    title: 'Accéder à Nesspay',
    continue: 'Continuer',
    email: {
      emailInputLabel: 'Votre email',
      emailInputErrorTitle: 'Email invalide',
      emailInputErrorHelper: 'Veuillez utiliser un email valide',
      emailInputNotFound: "Votre email n'est pas reconnu",
    },
    otc: {
      otcTitle: 'Vérifiez vos emails',
      otcMessage: 'Nous vous avons envoyé un code de 6 chiffres sur',
      otcResend: 'Envoyer un nouveau code',
      otcResent: 'Nous vous avons envoyé un nouveau code',
      otcIsRequired: 'Le code est requis',
      otcInputHelperText: 'Code invalide',
      otcPaste: 'Coller le code',
    },
  },
  home: {
    welcome: 'Bonjour',
    information: 'votre solde disponible est de',
    action: 'Je souhaite retirer',
    actionButton: 'Valider',
    banner: "Votre employeur a désactivé les transactions jusqu'au {{date}} du mois",
    modalSummary: {
      validation: 'Voulez-vous demander un retrait de {{amount}} € ?',
      information:
        'Cette somme sera disponible dans les prochaines heures sur votre compte bancaire et sera déduite de votre prochaine paie.',
      transactionsPendingInfo: "Vous avez déjà une demande d'acompte en cours !",
      transactionsPendingWantToProceed:
        'Voulez-vous valider cette nouvelle demande de {{amount}} € ?',
      rightButton: 'Valider',
      leftButton: 'Annuler',
    },
    modalCongrats: {
      title: 'Félicitations !',
      validation: "Vous venez de faire une demande d'acompte de",
      information:
        'Cette somme sera disponible dans les prochaines heures sur votre compte bancaire et sera déduite de votre prochaine paie.',
      rightButton: 'Fermer',
      leftButton: 'Mes transactions',
    },
    modalInfos: {
      title: 'Comment calculons-nous votre solde affiché ?',
      helperText:
        'Bon à savoir : le cycle de paie chez {{organizationName}} commence le {{payPeriodStart}} du mois.',
      calculations: {
        first: {
          statement: '1 - Commençons par votre salaire gagné',
          content:
            '( Votre salaire mensuel net / 30 jours ) * nombre de jours travaillés depuis le début du cycle de paie',
        },
        second: {
          statement: '2 - Votre employeur libère X% de votre salaire gagné ainsi :',
          content: "(salaire gagné) * (pourcentage d'acompte autorisé)",
        },
        third: {
          statement:
            '3 - Dernière étape, ne pas oublier le salaire que vous auriez déjà retiré et ainsi votre solde disponible affiché équivaut à :',
          content: '(salaire libéré) - (montant du salaire déjà retiré)',
        },
      },
    },
  },
  productTour: {
    steps: {
      1: 'Bienvenue ',
      2: "En glissant le curseur vers la droite, vous pouvez choisir le montant d'acompte que vous voulez retirer",
      3: "Il ne vous reste plus qu'à valider pour que le montant soit transféré sur votre compte bancaire !",
    },
    buttons: {
      prev: 'Précédent',
      next: 'Suivant',
      skip: 'Passer',
      last: 'Terminé',
    },
  },
  welcomeTutorial: {
    pageOne: {
      title: 'BIENVENUE SUR NESSPAY !',
      description: "N'attendez plus la fin du mois pour être payé.",
    },
    pageTwo: {
      title: 'VOTRE SALAIRE À LA DEMANDE',
      description: 'Une partie de votre salaire disponible à tout moment dans le mois.',
    },
    pageThree: {
      title: 'VOTRE SALAIRE VOUS APPARTIENT',
      description:
        'Choisissez un montant, valider votre demande et recevez le virement sur votre compte.',
    },
    nextButton: 'Suivant',
    hasBeenInvited:
      "Pour vous connecter, utilisez l'adresse mail sur laquelle vous avez reçu l'invitation Nesspay",
  },
  login: {
    title: 'CONNEXION',
    email: {
      label: 'Email',
      placeholder: 'Email',
    },
    password: {
      label: 'Mot de passe',
      placeholder: 'Mot de passe',
    },
    resetPasswordMessage: 'Mot de passe oublié ?',
    signInButton: 'Accéder à Nesspay',
    signUpButton: 'Non, je crée un compte',
    forgotEmailLink: "Mon adresse mail n'est pas reconnue",
    notBeenInvitedLink: 'Mon entreprise ne propose pas encore Nesspay',
    otc: {
      label: 'Code à usage unique',
      placeholder: '123456',
      requestCode: 'Continuer',
      requestCodeSent: "Nous avons envoyé un code à l'email {{email}}.",
      requestCodeAgain: 'Renvoyer le code.',
      enterCodeInstructions: 'Entrez le code à six chiffres ci-dessous :',
      signInWithCode: 'Connexion',
      goBack: 'Retour',
    },
  },
  emailForgotten: {
    title: "Comment trouver l'adresse mail avec laquelle me connecter",
    firstStep: "Retrouvez l'email d'invitation qui a été envoyé par : hello@nesspay.co",
    secondStep: "Entrez l'adresse email sur laquelle vous avez reçu l'invitation",
    helperMessage:
      'Si vous rencontrez un problème lors de votre connexion, contactez-nous via le chat',
    openChat: 'Ouvrir le chat',
  },
  signUp: {
    title: 'Inscription',
    firstName: 'Prénom',
    lastName: 'Nom',
    email: 'Email professionnel',
    company: 'Votre employeur',
    termsOfService: "En cochant cette case, j'accepte les conditions générales d'utilisation.",
    buttons: {
      signUp: "Je m'inscris",
      alreadyInvited: "Mon entreprise m'a invité",
    },
  },
  becomeAmbassador: {
    mainText: "Votre entreprise ne vous permet pas encore d'utiliser NessPay",
    secondaryText: 'Vous pouvez rendre cela possible, devenez ambassadeur !',
    buttonText: 'Je veux Nesspay',
  },
  resetPassword: {
    title: 'Mot de passe oublié',
    sendEmail: "Envoyer l'email",
  },
  utilities: {
    loadingMessage: 'Chargement...',
    errorMessage: 'Une erreur est survenue',
  },
  transactions: {
    title: 'Mon Historique',
    listTitle: 'Transactions',
    noTransactionsMessage: 'Aucune transaction',
  },
  blog: {
    title: 'Les fiches pratiques',
  },
  account: {
    title: 'Mon compte',
    profile: 'Profil',
    appsToDiscover: 'Nos services',
    blogTitle: 'Le Blog',
    blogDescription: 'Venez découvrir nos articles et fiches pratiques sur l’actualité RH.',
    toutesMesAidesTitle: 'Mes aides',
    toutesMesAidesDescription: 'Ne passez plus à côté des aides auxquelles vous êtes éligible !',
    toutesMesAidesDeactivated:
      "Désolé, votre employeur n'a pas encore souscrit au service. Nous vous invitons à le contacter.",
    needHelp: 'Une question ?',
    consultHelpCenter: 'Consulter la FAQ',
    cgu: "Conditions générales d'utilisation",
    cookies: 'Gestion des cookies',
    logout: 'Déconnexion',
    unauthorized: {
      title: "Vous n'êtes pas autorisé à utiliser cette application 😢",
      alternativeTitle: "Vous n'êtes plus autorisé à utiliser cette application 😢",
      loggedAs: 'Vous êtes connecté en tant que : ',
      inactive: 'Votre compte a été désactivé par un administrateur',
      helperText:
        "Si vous pensez qu'il s'agit d'une erreur, nous vous invitons à prendre contact avec un responsable.",
      organization: "Changer d'organisation : ",
      contractEnded: 'Votre contract est arrivé à son terme !',
      contractEndDate: 'Votre date de fin de contract était prévu le : ',
    },
  },
  toutesMesAides: {
    title: 'Mes aides',
    topText: 'Trouvez toutes les aides auxquelles vous avez droit',
    buttonText: "Se rendre sur l'application",
    buttonError: 'Erreur',
    buttonLoading: 'Chargement',
    bottomText:
      "Ce service est un simulateur qui vous permet d'évaluer vos droits à plus de 1000 aides financières",
  },
  transaction: {
    inProgress: '⏳  Transaction en cours de traitement',
    done: '✅  Transaction effectuée le',
    cancel: '❌  Transaction annulée le',
    helpButton: "Besoin d'aide ?",
    modalSummary: {
      validation: 'Souhaitez-vous annuler cette transaction ?',
      leftButton: 'Fermer',
      rightButton: 'Confirmer',
    },
  },
  maintenance: {
    title: 'Maintenance',
    message: "Nous sommes désolés, l'application NessPay est actuellement en maintenance...",
  },
  unsupported: {
    web: {
      firstHint: "L'appareil ou le navigateur que vous utilisé n'est pas supporté.",
      secondHint: 'Nous vous invitons à réessayer depuis un autre appareil.',
    },
    native: {
      firstHint: "L'appareil que vous utilisez n'est pas supporté.",
      secondHint:
        'Nous vous invitons à réessayer depuis un autre appareil ou depuis un ordinateur.',
    },
  },
}
