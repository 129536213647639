import { useMemo } from 'react'
import { StyleSheet, useWindowDimensions } from 'react-native'

export function useScrollViewStyles() {
  const { height, width } = useWindowDimensions()
  const styles = useMemo(
    () =>
      StyleSheet.create({
        style: { height, width },
        contentContainerStyle: { alignItems: 'center' },
      }),
    [height, width]
  )
  return styles
}
