import { Text } from 'native-base'
import React from 'react'

import { AuthContext } from 'auth/authProvider'
import { config } from 'config'
import { UserContext } from 'user/context'

export class DebugErrorBoundary extends React.Component<any, any> {
  state = { error: false }
  static getDerivedStateFromError() {
    return { error: true }
  }
  componentDidCatch() {
    // deal with errorInfo if needed
  }

  render() {
    if (!this.state.error || config.isProd()) return this.props.children
    return (
      <AuthContext.Consumer>
        {({ accessToken }) => (
          <UserContext.Consumer>
            {({ contract, loading }) => (
              <>
                <Text>{`User email : ${contract?.user.email || 'none'}`}</Text>
                <Text>{`User state : ${loading ? 'loading user' : 'user loaded'}`}</Text>
                <Text>{`Access token : ${accessToken ? accessToken : 'none'}`}</Text>
              </>
            )}
          </UserContext.Consumer>
        )}
      </AuthContext.Consumer>
    )
  }
}
