import { useState } from 'react'
import { useQuery } from 'react-query'

import { unauthorizedApi } from 'api'
import { useAuthContext } from 'auth/authProvider'
import { QueryKey } from 'services/queryClient'

export function useGetMaintenance() {
  const [maintenance, setMaintenance] = useState<boolean>(false)
  const { accessToken, isAuthenticated } = useAuthContext()
  useQuery(
    [QueryKey.Maintenance, isAuthenticated],
    async () => {
      const maintenance = await unauthorizedApi.meControllerGetMaintenanceState({
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      setMaintenance(maintenance)
    },
    {
      onError: (error) => {
        console.error(error)
      },
      enabled: Boolean(accessToken) && Boolean(isAuthenticated),
    }
  )
  return { maintenance }
}
