import { useMemo } from 'react'

import { useAuthContext } from 'auth/authProvider'
import { config } from 'config'
import { useUserContext } from 'user/context'

import { Configuration } from './gen'
import { DefaultApi } from './gen/api'

const configuration: Configuration = { basePath: config.BACKEND_URL }

export const unauthorizedApi = new DefaultApi(configuration)

export function useApi() {
  const { accessToken } = useAuthContext()
  const { contract } = useUserContext()
  const api = useMemo(() => {
    return new DefaultApi({
      ...configuration,
      accessToken: accessToken || '',
      organizationId: contract?.organizationId,
    })
  }, [accessToken, contract?.organizationId])
  return api
}
