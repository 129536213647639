import { useNavigation } from '@react-navigation/native'
import * as Linking from 'expo-linking'
import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { unauthorizedApi } from 'api'
import { useAuthContext } from 'auth/authProvider'
import { useGetEmail } from 'hooks/useGetEmail'
import { monitoring } from 'services/monitoring'
import { QueryKey } from 'services/queryClient'

import { UserContext } from './context'
import { isEmployee } from './roles'

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient()
  const { navigate } = useNavigation()
  const { userEmail } = useGetEmail()
  const { accessToken, isAuthenticated } = useAuthContext()

  const {
    data: contract,
    isLoading: loading,
    error,
  } = useQuery(
    [QueryKey.Me, accessToken, isAuthenticated],
    async () =>
      await unauthorizedApi.meControllerGet({
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    { enabled: Boolean(accessToken) && Boolean(isAuthenticated) }
  )

  useEffect(() => {
    const currentDate = Date.parse(new Date().toISOString())
    const contractEndDate = Date.parse(contract?.contractEndDate || '')
    const isAppAccessDisabled = contract?.isAppAccessEnabled === false

    if ((contract?.roles && !isEmployee(contract.roles)) || isAppAccessDisabled) {
      navigate('Unauthorized')
    }
    if (contract?.active === false) {
      navigate('InactiveAccount')
    }
    if (contract?.contractEndDate && currentDate >= contractEndDate) {
      navigate('ContractEnded')
    }
  }, [navigate, contract])

  useEffect(() => {
    if (navigate && error) {
      monitoring.captureException(error)
      // @ts-ignore : error is of type unknown
      if (error.status === 401) {
        navigate('SignIn', { login_hint: userEmail })
      }
    }
  }, [navigate, error, userEmail])

  useEffect(() => {
    const handleDeepLink = () => {
      if (!isAuthenticated) {
        navigate('SignIn')
      } else if (isAuthenticated && contract?.isAppAccessEnabled) {
        navigate('TabNav', { screen: 'Home' })
      }
    }
    Linking.getInitialURL().then((url) => {
      if (url && url.length) handleDeepLink()
    })
    const subscription = Linking.addEventListener('url', handleDeepLink)
    return () => {
      subscription.remove()
    }
  }, [navigate, isAuthenticated])

  const logout = useCallback(async () => {
    try {
      navigate('SignOut')
    } catch (error) {
      console.error(error)
    } finally {
      queryClient.removeQueries(QueryKey.Me)
    }
  }, [queryClient, navigate])

  const contextValue = useMemo(() => ({ contract, loading, logout }), [contract, loading, logout])

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}
