import { CondOperator, RequestQueryBuilder } from '@dataui/crud-request'
import { Box, Center, FlatList, Text, View } from 'native-base'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Loading } from 'components/Loading'
import { ScreenHeader } from 'components/ScreenHeader'
import { useHeights } from 'components/TabBar'
import { useCrudApi } from 'services/crudApi'
import { QueryKey } from 'services/queryClient'
import { TabScreenProps } from 'types/navigation'
import { useUserContext } from 'user/context'

import TransactionDetails from './TransactionDetails'

type Props = TabScreenProps<'Transactions'>

export const TransactionsScreen: React.FC<Props> = () => {
  const [refreshState, setRefreshState] = useState(0)

  const { contentHeightMinusHeader } = useHeights()
  const { t } = useTranslation()
  const { contract } = useUserContext()
  const { getMany: getManyTransactions } = useCrudApi('transactions')
  const {
    data: transactionsResponse,
    isFetching,
    error,
  } = useQuery(
    QueryKey.Transactions,
    async () =>
      await getManyTransactions({
        crudQuery: RequestQueryBuilder.create({
          filter: [{ field: 'userId', operator: CondOperator.EQUALS, value: contract?.userId }],
          sort: { field: 'createdAt', order: 'DESC' },
        }),
      })
  )

  useEffect(() => {
    if (!isFetching) {
      setRefreshState((value) => value + 1)
    }
  }, [isFetching])

  const transactions = transactionsResponse?.data || []
  const totalNbOfTransactions = transactionsResponse?.total || 0

  function renderContent() {
    if (isFetching)
      return (
        <Center>
          <Loading size={50} />
        </Center>
      )
    if (error) return <Center> {t('utilities.errorMessage')}</Center>
    if (totalNbOfTransactions == 0) {
      return <Center> {t('transactions.noTransactionsMessage')} </Center>
    }
    return (
      <FlatList
        contentContainerStyle={{ flexGrow: 1, justifyContent: 'flex-start', paddingRight: 10 }}
        keyExtractor={(item) => item.id}
        data={transactions}
        extraData={refreshState}
        renderItem={({ item }) => <TransactionDetails transaction={item} />}></FlatList>
    )
  }

  return (
    <Box height={contentHeightMinusHeader}>
      <ScreenHeader title={t('transactions.title')} />
      <View
        p="5"
        alignSelf="center"
        w="90%"
        h="100%"
        maxW={{ base: 400, md: 500 }}
        background="white"
        rounded="2xl"
        shadow={4}>
        <Text fontSize="xl" fontWeight="semibold" mb="8px" textAlign="left">
          {t('transactions.listTitle')}
        </Text>
        {renderContent()}
      </View>
    </Box>
  )
}
