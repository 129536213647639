import { BottomTabBarProps } from '@react-navigation/bottom-tabs'
import { BlurView } from 'expo-blur'
import { Box, HStack, IconButton, Center } from 'native-base'
import React, { useCallback, useMemo } from 'react'
import { useWindowDimensions } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { TabRoute } from 'types/navigation'

import { SCREEN_HEADER_HEIGHT } from './ScreenHeader'

export function useHeights() {
  const safeAreaInsets = useSafeAreaInsets()
  const { height } = useWindowDimensions()
  return useMemo(() => {
    const tabBarHeight = Math.round(0.1 * height)
    const bottomSafeHeight = safeAreaInsets.bottom
    const topSafeHeight = safeAreaInsets.top
    const notUsableVerticalSpace = tabBarHeight + bottomSafeHeight + topSafeHeight
    const contentHeight = height - notUsableVerticalSpace
    return {
      tabBarHeight: `${tabBarHeight}px`,
      contentHeight: `${contentHeight}px`,
      contentHeightMinusHeader: `${contentHeight - SCREEN_HEADER_HEIGHT}px`,
      bottomSafeHeight: `${bottomSafeHeight}px`,
      topSafeHeight: `${topSafeHeight}px`,
    }
  }, [height, safeAreaInsets.bottom, safeAreaInsets.top])
}

export const TabBarSpacer = () => {
  const { tabBarHeight } = useHeights()
  return <Box height={tabBarHeight} />
}

const tabScreenIndexes: Record<TabRoute, number> = {
  Home: 0,
  Transactions: 1,
  AccountNav: 2,
}
const tabScreenOrder: TabRoute[] = ['Transactions', 'Home', 'AccountNav']

export const TabBar: React.FC<BottomTabBarProps> = ({ state, descriptors, navigation }) => {
  const { tabBarHeight, bottomSafeHeight } = useHeights()
  const getTabButton = useCallback(
    (screen: TabRoute) => {
      const key = state.routes.find((route) => route.key.includes(screen))?.key as string
      const options = descriptors[key].options
      const isFocused = state.index === tabScreenIndexes[screen]
      const onPress = () => {
        if (isFocused) return
        navigation.navigate('TabNav', { screen })
      }
      return (
        <IconButton size="lg" key={screen} onPress={onPress}>
          {/* @ts-ignore: color and size props are not needed */}
          {options.tabBarIcon({ focused: isFocused })}
        </IconButton>
      )
    },
    [state, descriptors, navigation]
  )
  const TabIconButtons = useMemo(() => tabScreenOrder.map(getTabButton), [getTabButton])
  return (
    <Center
      position="absolute"
      bottom={{ base: bottomSafeHeight }}
      width="100%"
      height={tabBarHeight}>
      <BlurView
        intensity={5}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <HStack height="50px" space={8} paddingX={5} borderRadius="40px" shadow="3" bg="white">
          {TabIconButtons}
        </HStack>
      </BlurView>
    </Center>
  )
}
