import { RequestQueryBuilder } from '@dataui/crud-request'
import axios, { AxiosRequestConfig } from 'axios'
import { useCallback, useMemo } from 'react'

import { useAuthContext } from 'auth/authProvider'
import { config } from 'config'
import { useUserContext } from 'user/context'
import { deepIterateOnObject } from 'utils/object'

import { ResourceEntity, Resource } from './types'

type AuthParams = { token?: string; organizationId?: string }

type RequestConfig = Omit<AxiosRequestConfig, 'url'> & AuthParams

async function backendClient<ResponseType = any>(path: string, requestConfig: RequestConfig = {}) {
  requestConfig.headers = requestConfig.headers || {}
  if (requestConfig.token) {
    requestConfig.headers['Authorization'] = `Bearer ${requestConfig.token}`
  }
  if (requestConfig.organizationId) {
    requestConfig.headers['Organization'] = requestConfig.organizationId
  }
  return axios.request<ResponseType>({
    url: config.BACKEND_URL + '/api' + path,
    ...requestConfig,
  })
}

export function useCrudApi<R extends Resource>(resource: R) {
  type Entity = ResourceEntity[R]
  const { accessToken } = useAuthContext()
  const { contract } = useUserContext()

  const authParamsWithDefault = useCallback(
    ({ token = accessToken || '', organizationId = contract?.organizationId }: AuthParams) => {
      return { token, organizationId }
    },
    [accessToken, contract?.organizationId]
  )

  const getMany = useCallback(
    async (params: { crudQuery?: RequestQueryBuilder } & AuthParams) => {
      const { crudQuery = new RequestQueryBuilder() } = params
      const path = `/${resource}?${crudQuery.query()}`
      const { data: response } = await backendClient(path, {
        method: 'GET',
        ...authParamsWithDefault(params),
      })
      return response as {
        count: number
        total: number
        page: number
        pageCount: number
        data: Array<Entity>
      }
    },
    [resource, authParamsWithDefault]
  )

  const getOne = useCallback(
    async (params: { id: string; crudQuery?: RequestQueryBuilder } & AuthParams) => {
      const { id, crudQuery = new RequestQueryBuilder() } = params
      const path = `/${resource}/${id}?${crudQuery.query()}`
      const response = await backendClient(path, {
        method: 'GET',
        ...authParamsWithDefault(params),
      })
      return response as { data: Entity }
    },
    [resource, authParamsWithDefault]
  )

  const update = useCallback(
    async (params: { id: string; data: any } & AuthParams) => {
      const { id, data } = params
      deepIterateOnObject(data, setFalsyFieldsToNull)
      const response = await backendClient(`/${resource}/${id}`, {
        method: 'PATCH',
        data,
        ...authParamsWithDefault(params),
      })
      return response as { data: Entity }
    },
    [resource, authParamsWithDefault]
  )

  const create = useCallback(
    async (params: { data: any } & AuthParams) => {
      const { data } = params
      deepIterateOnObject(data, setFalsyFieldsToNull)
      const response = await backendClient(`/${resource}`, {
        method: 'POST',
        data,
        ...authParamsWithDefault(params),
      })
      return response as { data: Entity }
    },
    [resource, authParamsWithDefault]
  )

  const crudDataProvider = useMemo(
    () => ({ getOne, getMany, update, create }),
    [getOne, getMany, update, create]
  )

  return crudDataProvider
}

function setFalsyFieldsToNull(element: any, parent: any, key: string) {
  if ((typeof element !== 'string' && typeof element !== undefined) || element.length) return
  parent[key] = null
}
