import Constants from 'expo-constants'

function getVar(name: string) {
  if (process.env && process.env[name]) {
    return process.env[name]
  }
  if (Constants.expoConfig && Constants.expoConfig.extra && Constants.expoConfig.extra[name]) {
    return Constants.expoConfig.extra[name]
  }
  return undefined
}

const isJest = () => getVar('ENV') === 'jest'
const isDev = () => getVar('ENV') === 'development'
const isTesting = () => getVar('ENV') === 'testing'
const isProd = () => getVar('ENV') === 'production'

function selectEnv<T = any>(values: { jest?: T; dev?: T; test: T; prod: T }): T {
  if (isTesting()) {
    if (values.test) return values.test
    else if (values.dev) return values.dev
  }
  if (isJest()) {
    if (values.jest) return values.jest
    else if (values.dev) return values.dev
    else return values.test
  }
  if (isDev()) {
    if (values.dev) return values.dev
    else return values.test
  }
  return values.prod
}

export const config = {
  isJest,
  isDev,
  isTesting,
  isProd,
  FRONTEGG_DOMAIN: selectEnv({ test: 'testing-nesspay', prod: 'nesspay' }),
  FRONTEGG_BASE_URL: selectEnv({
    test: 'https://testing-frontegg.nesspay.co',
    prod: 'https://frontegg.nesspay.co',
  }),
  FRONTEGG_VENDOR_ID: selectEnv({
    test: '656173f6cb10-49d0-b96e-c586815ccd38',
    prod: '9e07455a6956-49e0-8d85-09ff9ec682fd',
  }),
  EMPLOYEE_APP_URL: selectEnv({
    test: 'https://testing-team.nesspay.co',
    prod: 'https://team.nesspay.co',
  }),
  CLIENT_APP_URL: selectEnv({
    test: 'https://testing-admin.nesspay.co',
    prod: 'https://admin.nesspay.co',
  }),
  EMPLOYEE_HELP_CENTER_URL:
    'https://intercom.help/nesspay-b7927642140c/fr/collections/3947119-faq-nesspay',
  BACKEND_URL: selectEnv({
    dev: 'http://localhost:8080',
    test: 'https://nesspay-backend-testing-x2xxa.ondigitalocean.app',
    prod: 'https://nesspay-backend-production-8v9w4.ondigitalocean.app',
  }),
  AXEPTIO_CLIENT_ID: selectEnv({
    test: '63c68495d7cd217c2365fa81',
    prod: '639829879a63c4d21617a288',
  }),
  MIXPANEL_TOKEN: selectEnv({
    test: 'cee81a8450090ecc4655dfebfe12e41f',
    prod: '6a43a9696c4e0eb22bd0ec19886992c9',
  }),
  INTERCOM_APP_ID: selectEnv({
    test: 'p63388y2',
    prod: 'r45heggr',
  }),
  PUBLIC_URL: window?.location?.origin,
  PRIVACY_POLICY_URL: 'https://www.nesspay.co/politique-de-confidentialite',
  SENTRY_DSN: 'https://3f97ff532fd34511a7eb07120cc17920@o1202552.ingest.sentry.io/4504050812846080',
  AXEPTIO_URL: 'https://www.axeptio.eu/fr/home',
  AXEPTIO_API_URL: 'https://api.axept.io/v1/client/',
  AXEPTIO_COOKIES_VERSION: 'nesspay-fr',
  SIGNUP_TYPEFORM_URL: 'https://s6xiqdourgh.typeform.com/to/JamGS6vj',
  INTERCOM_API_URL: 'https://api-iam.intercom.io',
  INTERCOM_BASE_URL: 'https://app.intercom.com/',
  INTERCOM_WIDGET_URL: 'https://widget.intercom.io/widget/',
  IOS_INTENT_URL: 'https://apps.apple.com/app/id1585842280',
  ANDROID_INTENT_URL:
    'intent://details?id=com.nesspay.employeeapp&url=https://team.nesspay.co#Intent;scheme=market;package=com.android.vending;end',
}
