import React, { PropsWithChildren, useMemo, useState, useCallback } from 'react'

type IntercomContextType = {
  isChatOpen: boolean
  isChatLoading: boolean
  openChat: () => void
  closeChat: () => void
  setChatLoading: (value: boolean) => void
}

const INITIAL_INTERCOM_CONTEXT: IntercomContextType = {
  isChatOpen: false,
  isChatLoading: true,
  openChat: () => {
    // do nothing
  },
  closeChat: () => {
    // do nothing
  },
  setChatLoading: () => {
    // do nothing
  },
}

export const IntercomContext = React.createContext<IntercomContextType>(INITIAL_INTERCOM_CONTEXT)

export const IntercomProvider: React.FC<PropsWithChildren> = (props) => {
  const [isChatOpen, setChatOpen] = useState<boolean>(INITIAL_INTERCOM_CONTEXT.isChatOpen)
  const [isChatLoading, setChatLoading] = useState<boolean>(INITIAL_INTERCOM_CONTEXT.isChatLoading)

  const openChat = useCallback(() => {
    setChatOpen(true)
  }, [setChatOpen])

  const closeChat = useCallback(() => {
    setChatOpen(false)
  }, [setChatOpen])

  const contextValue = useMemo(
    () => ({ isChatOpen, isChatLoading, openChat, closeChat, setChatLoading }),
    [isChatOpen, isChatLoading, openChat, closeChat, setChatLoading]
  )

  return <IntercomContext.Provider value={contextValue}>{props.children}</IntercomContext.Provider>
}
