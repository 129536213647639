import { MaterialIcons } from '@expo/vector-icons'
import { Box, Divider, IconButton, Row, Text } from 'native-base'
import React from 'react'

import { Color } from 'theme'

type Props = {
  title: string
  onGoBack?: () => void
}

const TEXT_HEIGHT = 35
const TEXT_MARGIN_TOP = 15
const TEXT_MARGIN_BOTTOM = 10
const DIVIDER_HEIGHT = 1
const DIVIDER_MARGIN_BOTTOM = 20
const ICON_SIZE = 48
export const SCREEN_HEADER_HEIGHT =
  TEXT_HEIGHT + TEXT_MARGIN_TOP + TEXT_MARGIN_BOTTOM + DIVIDER_HEIGHT + DIVIDER_MARGIN_BOTTOM

export const ScreenHeader: React.FC<Props> = (props) => {
  return (
    <>
      <Row width="100%" justifyContent="space-around" alignItems="center">
        {props.onGoBack ? (
          <IconButton
            variant="ghost"
            onPress={props.onGoBack}
            padding={0}
            icon={
              <MaterialIcons color={Color.blueNight} name="keyboard-arrow-left" size={ICON_SIZE} />
            }
          />
        ) : null}
        <Text
          height={`${TEXT_HEIGHT}px`}
          mt={`${TEXT_MARGIN_TOP}px`}
          mb={`${TEXT_MARGIN_BOTTOM}px`}
          fontSize="md"
          fontWeight="normal">
          {props.title}
        </Text>
        {props.onGoBack ? <Box width={`${ICON_SIZE}px`} /> : null}
      </Row>
      <Divider height={`${DIVIDER_HEIGHT}px`} mb={`${DIVIDER_MARGIN_BOTTOM}px`} />
    </>
  )
}
