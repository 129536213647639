import React, { useEffect, useContext } from 'react'

import { config } from 'config'
import { useUserContext } from 'user/context'

import { IntercomContext } from './provider'

function isIntercomInitialized() {
  return window && window.Intercom && window.Intercom.booted
}

export const IntercomChat = () => {
  const { contract } = useUserContext()
  const { isChatOpen, closeChat } = useContext(IntercomContext)

  useEffect(() => {
    if (window.isReactNativeWebView) {
      return
    }
    function initializeIntercom() {
      const company = contract?.organizationId
      if (company) {
        window.intercomSettings = {
          api_base: config.INTERCOM_API_URL,
          app_id: config.INTERCOM_APP_ID,
          hide_default_launcher: true,
          user_id: contract?.userId || '',
          email: contract?.user.email || '',
          name: contract?.user.firstName + ' ' + contract?.user.lastName,
          company: {
            company_id: contract?.organizationId || '',
            name: contract?.organization.name || '',
          },
        }
      } else {
        window.intercomSettings = {
          api_base: config.INTERCOM_API_URL,
          app_id: config.INTERCOM_APP_ID,
          hide_default_launcher: true,
        }
      }

      ;(function () {
        const w = window
        const ic = w.Intercom
        if (typeof ic === 'function') {
          ic('reattach_activator')
          ic('update', w.intercomSettings)
        } else {
          const d = document
          const i: any = function () {
            // eslint-disable-next-line prefer-rest-params
            i.c(arguments)
          }
          i.q = []
          i.c = function (args: any) {
            i.q.push(args)
          }
          w.Intercom = i
          const l = function () {
            const s = d.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.src = config.INTERCOM_WIDGET_URL + config.INTERCOM_APP_ID
            const x = d.getElementsByTagName('script')[0]
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            x.parentNode!.insertBefore(s, x)
          }
          if (document.readyState === 'complete') {
            l()
          } else if (w.attachEvent) {
            w.attachEvent('onload', l)
          } else {
            w.addEventListener('load', l, false)
          }
        }
      })()
    }
    initializeIntercom()
  }, [contract])

  useEffect(() => {
    if (window.isReactNativeWebView) {
      return
    }
    if (isIntercomInitialized() && isChatOpen) {
      window.Intercom('showMessages')
      closeChat()
    }
  }, [isChatOpen, closeChat])

  return <></>
}
