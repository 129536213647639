import { useEffect, useState } from 'react'

import { isWebView } from 'services/platform'
import { storage } from 'services/storage'

export function useHasLoggedInAtLeastOnce() {
  const { hasLoggedInAtLeastOnce, hasLoggedInAtLeastOnceLoading } = useHasLoggedInAtLeastOnceBase()
  // Do not show welcome screens in webview
  if (isWebView()) {
    return { hasLoggedInAtLeastOnce: true, hasLoggedInAtLeastOnceLoading: false }
  }
  return { hasLoggedInAtLeastOnce, hasLoggedInAtLeastOnceLoading }
}

function useHasLoggedInAtLeastOnceBase() {
  const [hasLoggedInAtLeastOnce, setHasLoggedInAtLeastOnce] = useState<boolean | null | undefined>(
    undefined
  )
  useEffect(() => {
    storage
      .getObject<boolean>('@UserHasLoggedInAtLeastOnce')
      .then((value) => setHasLoggedInAtLeastOnce(value === null ? false : value))
  }, [])
  return {
    hasLoggedInAtLeastOnce,
    hasLoggedInAtLeastOnceLoading: hasLoggedInAtLeastOnce === undefined,
  }
}
