import { modelName } from 'expo-device'
import { LinearGradient } from 'expo-linear-gradient'
import { Text, View, Center, Box } from 'native-base'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { IS_WEB } from 'services/platform'

interface WelcomeTutorialTemplateProps {
  title: string
  description: string
  logo: JSX.Element
  number: number
}

function getGradientColors(): string {
  if (modelName?.includes('iPad') === true || IS_WEB) return 'rgba(0,0,0,0)'
  return 'rgba(204,209,240,0.9)'
}

function getGradientProgress(templateNumber: number): number {
  if (templateNumber === 1) return 0.7
  if (templateNumber === 2) return 0.3
  return 0
}

const WelcomeTutorialTemplate: FC<WelcomeTutorialTemplateProps> = ({
  title,
  description,
  logo,
  number,
}) => {
  const { t } = useTranslation()
  return (
    <View h="100%" key="test">
      <LinearGradient
        style={{ height: '100%', width: '100%' }}
        colors={[getGradientColors(), 'transparent']}
        start={{ x: 0, y: 1 }}
        end={{ x: 0, y: getGradientProgress(number) }}>
        <Center h="100%" w="100%">
          {!IS_WEB && <Box h="10%" />}
          <View w="90%" h="70%">
            <Text fontSize="3xl" fontWeight="medium" textAlign="center">
              {title}
            </Text>

            <Text
              fontSize="lg"
              color="blueNight"
              fontWeight="normal"
              textAlign="center"
              padding={3}>
              {description}
            </Text>

            <Box h={IS_WEB ? '15%' : '5%'} />

            <Box alignItems="center">{logo}</Box>

            <Box h={IS_WEB ? '20%' : '15%'} />

            {number === 3 && (
              <Text
                textAlign="center"
                fontSize="lg"
                fontWeight="normal"
                paddingX={IS_WEB ? 130 : 15}>
                {t('welcomeTutorial.hasBeenInvited')}
              </Text>
            )}
          </View>

          <Box h="20%" />
        </Center>
      </LinearGradient>
    </View>
  )
}

export default WelcomeTutorialTemplate
