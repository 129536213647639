import { LogBox } from 'react-native'

const overloadedConsole = {
  log: console.log,
  info: console.info,
  warn: console.warn,
  error: console.error,
}

type LogType = keyof typeof overloadedConsole

function ignoreConsoleLogs(type: LogType, ignoreMessages: string[]) {
  console[type] = (...args) => {
    let log = true
    if (!ignoreMessages) {
      log = false
    }
    ignoreMessages.forEach((ignoreMessage) => {
      const message = args.join(' ').slice(0, -1)
      if (/^\/.*\/[a-z]*$/.test(ignoreMessage)) {
        const regex = new RegExp(ignoreMessage)
        if (regex.test(message)) log = false
      } else if (message.indexOf(ignoreMessage) > -1) {
        log = false
      }
      if (!log) return false
      return true
    })
    if (log) overloadedConsole[type](...args)
  }
}

export function ignoreLogs(type: LogType, ignoreMessages: string[]) {
  LogBox.ignoreLogs(ignoreMessages)
  ignoreConsoleLogs(type, ignoreMessages)
}
