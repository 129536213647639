import { Contract } from 'api/gen'

export type RoleType = `${Contract.RolesEnum}` | Contract.RolesEnum
export const ROLES = Object.values(Contract.RolesEnum)
export const ROLES_WITHOUT_EMPLOYEE = ROLES.filter((role) => role !== Contract.RolesEnum.Employee)

export function isEmployee(roles?: RoleType[]) {
  if (!roles || !Array.isArray(roles)) return false
  return roles.includes('employee')
}

export function isNonEmployee(roles?: RoleType[]) {
  if (!roles || !Array.isArray(roles)) return false
  for (const role of ROLES_WITHOUT_EMPLOYEE) {
    if (roles.includes(role)) return true
  }
  return false
}
