import { Modal, useBreakpointValue } from 'native-base'
import React, { ComponentProps } from 'react'

import { useHeights } from 'components/TabBar'

type ModalPlacement = 'top' | 'bottom' | 'left' | 'right' | 'center'

type Props = {
  modalContentProps?: ComponentProps<typeof Modal.Content>
  modalHeaderProps?: ComponentProps<typeof Modal.Header>
  placement?: ModalPlacement
  showCloseButton?: boolean
  header?: React.ReactNode
  content?: React.ReactNode
  footer?: React.ReactNode
} & ComponentProps<typeof Modal>

export const CustomModal: React.FC<Props> = ({
  modalContentProps,
  modalHeaderProps,
  placement,
  showCloseButton = true, // Default value is set here
  header,
  content,
  footer,
  ...modalProps
}) => {
  const { bottomSafeHeight } = useHeights()
  const responsivePlacement: ModalPlacement = useBreakpointValue({ base: 'bottom', md: 'center' })
  const finalPlacement = placement ?? responsivePlacement

  return (
    <Modal size="full" animationPreset="slide" {...modalProps}>
      <Modal.Content
        maxWidth="500"
        width="100%"
        {...getPlacementProps(finalPlacement, bottomSafeHeight)}
        {...modalContentProps}>
        {showCloseButton ? <Modal.CloseButton /> : null}
        {header ? <Modal.Header {...modalHeaderProps}>{header}</Modal.Header> : null}
        {content ? <Modal.Body>{content}</Modal.Body> : null}
        {footer ? <Modal.Footer borderTopWidth={0}>{footer}</Modal.Footer> : null}
      </Modal.Content>
    </Modal>
  )
}

function getPlacementProps(
  placement: ModalPlacement,
  bottomSafeHeight: string
): ComponentProps<typeof Modal.Content> {
  return {
    top: {
      paddingBottom: 4,
      marginBottom: 'auto',
      marginTop: 0,
      borderTopRadius: 0,
      borderBottomRadius: 20,
    },
    bottom: {
      paddingBottom: bottomSafeHeight,
      marginBottom: 0,
      marginTop: 'auto',
      borderTopRadius: 20,
      borderBottomRadius: 0,
    },
    left: {
      paddingBottom: 4,
      marginLeft: 0,
      marginRight: 'auto',
      borderRadius: 20,
    },
    right: {
      paddingBottom: 4,
      marginLeft: 'auto',
      marginRight: 0,
      borderRadius: 20,
    },
    center: {
      paddingBottom: 4,
      borderRadius: 20,
    },
  }[placement]
}
