import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

import { Color } from '../../theme'

interface Props {
  colored: boolean
}

const TransactionIcon = ({ colored }: Props) => (
  <Svg width={22} height={22} fill="none">
    <Path
      id="first"
      d="m14.762 9.894 2.655-2.655-2.655-2.655"
      stroke={colored ? Color.blueNight : Color.grey}
      strokeWidth={1.353}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M4.584 7.242h12.833"
      stroke={colored ? Color.blueNight : Color.grey}
      strokeWidth={1.353}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="m7.24 12.106-2.656 2.655 2.656 2.656"
      stroke={colored ? Color.yellow : Color.grey}
      strokeWidth={1.353}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M17.417 14.759H4.584"
      stroke={colored ? Color.yellow : Color.grey}
      strokeWidth={1.353}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default TransactionIcon
