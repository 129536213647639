import { Box } from 'native-base'
import React, { ComponentProps, useCallback, useState } from 'react'
import { ImageBackground, LayoutChangeEvent, Pressable, ViewStyle } from 'react-native'

type CardProps = {
  height?: number
  onPress?: () => void
  backgroundUri?: string
} & ComponentProps<typeof Box>

export const Card: React.FC<CardProps> = (props) => {
  const { height = 150, onPress, backgroundUri, ...boxProps } = props

  const [backgroundSize, setBackgroundSize] = useState({ width: 0, height: 0 })
  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setBackgroundSize({
      width: event.nativeEvent.layout.width,
      height: event.nativeEvent.layout.height,
    })
  }, [])

  return (
    <Box
      onLayout={backgroundUri ? onLayout : undefined}
      height={height}
      width="100%"
      shadow="3"
      bg="white"
      rounded="lg"
      {...boxProps}>
      {backgroundUri ? (
        <ImageBackground
          resizeMode="cover"
          style={{
            position: 'absolute',
            overflow: 'hidden',
            borderRadius: 10,
            width: backgroundSize.width,
            height: backgroundSize.height,
          }}
          source={{ uri: props.backgroundUri }}
        />
      ) : null}
      {onPress ? (
        <Pressable style={contentStyle} onPress={onPress}>
          {props.children}
        </Pressable>
      ) : (
        <Box style={contentStyle}>{props.children}</Box>
      )}
    </Box>
  )
}

const contentStyle: ViewStyle = {
  padding: 20,
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}
