import { Translations } from './types'

export const enTranslations: Translations = {
  version: 'Version : {{version}}',
  error: {
    title: 'Error',
    invalidEmail: 'Choose a valid email address',
    invalidPassword: 'Choose a valid password',
    generic: 'There was a problem. Please try again later.',
    auth: 'Your email address or password is not valid.',
    serviceDeactivated:
      'Your account has been temporarily disabled by your company.\nPlease contact your HR manager.',
  },
  errorBoundary: {
    global: {
      goToWebApp: 'Go to web app',
      issueWithNativeApp: 'An issue with the mobile app ?',
      issueWithWebApp: 'An issue with the app ?',
      contactSupport: 'Please contact the support team of NessPay.',
    },
  },
  success: {
    title: 'Success',
  },
  signIn: {
    title: 'Access Nesspay',
    continue: 'Continue',
    email: {
      emailInputLabel: 'Your email',
      emailInputErrorTitle: 'Invalid email',
      emailInputErrorHelper: 'Please use a valid email',
      emailInputNotFound: "Your email can't be found",
    },
    otc: {
      otcTitle: 'Check your email',
      otcMessage: 'We sent you a six digit code at',
      otcResend: 'Resend a new code',
      otcResent: 'We sent you a new six digit code',
      otcIsRequired: 'The code is required',
      otcInputHelperText: 'Invalid code',
      otcPaste: 'Paste code',
    },
  },
  home: {
    welcome: 'Welcome',
    banner: 'Your employer has deactivated advances until the {{date}} of the month',
    information: 'your available balance is',
    action: 'I want to withdraw',
    actionButton: 'Validate',
    modalSummary: {
      validation: 'Do you want to withdraw {{amount}} € ?',
      information:
        'This sum will be available in the next few hours in your bank account and will be deducted from your next pay',
      transactionsPendingInfo: 'You already have an advance request in progress !',
      transactionsPendingWantToProceed:
        'Do you want to validate this new advance request of {{amount}} € ?',
      rightButton: 'Approve',
      leftButton: 'Cancel',
    },
    modalCongrats: {
      title: 'Congratulations!',
      validation: 'You have just requested a deposit of',
      information:
        'This sum will be available in the next few hours in your bank account and will be deducted from your next salary.',
      rightButton: 'Close',
      leftButton: 'See transactions',
    },
    modalInfos: {
      title: 'How do we calculate your displayed balance?',
      helperText:
        'Good to know: the pay cycle at {{organizationName}} begins on the {{payPeriodStart}} of the month.',
      calculations: {
        first: {
          statement: "1 - Let's start with your earned salary",
          content:
            '( Your net monthly salary / 30 days ) * number of days worked since start of pay cycle',
        },
        second: {
          statement: '2 - Your employer releases X% of your earned salary as follows:',
          content: '(earned salary) * (down payment percentage allowed)',
        },
        third: {
          statement:
            '3 - Last step, do not forget the salary that you already withdrawn and thus your displayed available balance is equivalent to:',
          content: '(released salary) - (amount of salary already withdrawn)',
        },
      },
    },
  },
  productTour: {
    steps: {
      1: 'Welcome ',
      2: 'By moving the slider to the right, you can choose the advance payment amount you want to withdraw',
      3: 'All you have to do is validate for the amount to be transferred to your bank account!',
    },
    buttons: {
      prev: 'Previous',
      next: 'Next',
      skip: 'Skip',
      last: 'Finish',
    },
  },
  welcomeTutorial: {
    pageOne: {
      title: 'WELCOME TO NESSPAY!',
      description: 'No more waiting until the end of the month to get paid.',
    },
    pageTwo: {
      title: 'YOUR ON-DEMAND SALARY',
      description: 'A portion of your salary available at any time during the month.',
    },
    pageThree: {
      title: 'YOUR SALARY BELONGS TO YOU',
      description:
        'Choose an amount, validate your request, and receive the transfer within minutes into your account.',
    },
    nextButton: 'Next',
    hasBeenInvited: 'To log in, use the email address where you received the Nesspay invitation',
  },
  login: {
    title: 'SIGN IN',
    email: {
      label: 'Email',
      placeholder: 'Email',
    },
    password: {
      label: 'Password',
      placeholder: 'Password',
    },
    resetPasswordMessage: 'forgot password?',
    signInButton: 'Access Nesspay',
    signUpButton: 'No, I create an account',
    forgotEmailLink: 'My email address is not recognized',
    notBeenInvitedLink: 'My company does not yet offer Nesspay',
    otc: {
      label: 'One-Time Code',
      placeholder: '123456',
      requestCode: 'Continue',
      requestCodeSent: 'We sent you a six digit code to {{email}}.',
      requestCodeAgain: 'Resend a code.',
      enterCodeInstructions: 'Enter the 6-digit code below :',
      signInWithCode: 'Sign in',
      goBack: 'Go back',
    },
  },
  emailForgotten: {
    title: 'How to find your e-mail address?',
    firstStep: 'Locate the invitation email that was sent from hello@nesspay.co',
    secondStep: 'Enter the email address where you received the invitation',
    helperMessage: 'If you encounter any problems while logging in, please contact us via chat',
    openChat: 'Open chat',
  },
  signUp: {
    title: 'Registration',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Professional email address',
    company: 'Your employer',
    termsOfService: 'By checking this box, I accept the terms of service.',
    buttons: {
      signUp: 'Sign Up',
      alreadyInvited: 'My company invited me',
    },
  },
  becomeAmbassador: {
    mainText: 'Your company does not yet allow you to use NessPay',
    secondaryText: 'You can make it possible, become an ambassador!',
    buttonText: 'I want Nesspay',
  },
  resetPassword: {
    title: 'Password forgotten',
    sendEmail: 'Reset my password',
  },
  utilities: {
    loadingMessage: 'Loading...',
    errorMessage: 'An error occurred',
  },
  transactions: {
    title: 'My History',
    listTitle: 'Transactions',
    noTransactionsMessage: 'No transaction found',
  },
  blog: {
    title: 'Practical sheets',
  },
  account: {
    title: 'My account',
    profile: 'Profile',
    appsToDiscover: 'Our services',
    blogTitle: 'Blog',
    blogDescription: 'Come and discover our articles and practical sheets on HR.',
    toutesMesAidesTitle: 'Subsidies',
    toutesMesAidesDescription: 'Do not miss any subsidies for which you are eligible !',
    toutesMesAidesDeactivated:
      'Sorry, your employer has not yet subscribed to the service. You may contact them.',
    needHelp: 'Do you have a question ?',
    consultHelpCenter: 'Consult the FAQ',
    cgu: 'Termes and conditions',
    cookies: 'Cookie management',
    logout: 'Logout',
    unauthorized: {
      title: 'You are not authorized to use this app 😢',
      alternativeTitle: 'You are not anymore authorized to use this app 😢',
      loggedAs: 'You are logged in as :',
      inactive: 'Your account has been deactivated by an administrator',
      helperText: 'If you think this is an error, we invite you to contact a manager.',
      organization: 'Change organization : ',
      contractEnded: 'Your contract has come to an end!',
      contractEndDate: 'Your contract end date was scheduled for:',
    },
  },
  toutesMesAides: {
    title: 'Subsidies',
    topText: 'Find all the help you are entitled to',
    buttonText: 'Go to the app',
    buttonError: 'Error',
    buttonLoading: 'Loading',
    bottomText:
      'This service is a simulator that allows you to assess your rights to more than 1000 financial aids',
  },
  transaction: {
    inProgress: '⏳ Transaction being processed',
    done: '✅ Transaction made on',
    cancel: '❌ Transaction canceled on',
    helpButton: 'Need help?',
    modalSummary: {
      validation: 'Would you like to cancel this transaction?',
      leftButton: 'Close',
      rightButton: 'Confirm',
    },
  },
  maintenance: {
    title: 'Maintenance',
    message: "We're sorry, the NessPay app is currently undergoing maintenance...",
  },
  unsupported: {
    web: {
      firstHint: 'The device or browser you are using is not supported.',
      secondHint: 'We invite you to try again from another device.',
    },
    native: {
      firstHint: 'The device you are using is not supported.',
      secondHint: 'We invite you to try again from another device or from a computer.',
    },
  },
}
