import { MaterialCommunityIcons } from '@expo/vector-icons'
import { FormControl, IconButton, IInputProps, Input } from 'native-base'
import React, { ComponentProps, useCallback } from 'react'

import { Color } from 'theme'

type InputProps = {
  controlProps?: ComponentProps<typeof FormControl>
  label?: string
  invalidMessage?: string
} & IInputProps

export const InputOutline: React.FC<InputProps> = ({
  label,
  invalidMessage,
  controlProps,
  ...inputProps
}) => {
  const isInvalid = Boolean(invalidMessage)
  const borderColor = isInvalid ? 'error.400' : 'blueNight'
  const variantProps: IInputProps = {
    focusOutlineColor: borderColor,
    _focus: { borderColor: borderColor },
    _hover: { borderColor: borderColor },
  }
  return (
    <FormControl {...controlProps}>
      {label ? (
        <FormControl.Label
          margin={0}
          _text={{ color: 'blueNight', fontSize: 'sm', fontWeight: 'normal' }}>
          {label}
        </FormControl.Label>
      ) : null}
      <Input fontSize="sm" fontWeight="light" {...inputProps} {...variantProps} variant="outline" />
      <FormControl.ErrorMessage
        _text={{ fontSize: 'xs', color: 'danger.600', fontWeight: 200 }}
        isInvalid={isInvalid}>
        {invalidMessage}
      </FormControl.ErrorMessage>
    </FormControl>
  )
}

export const InputUnderlined: React.FC<InputProps> = ({
  invalidMessage,
  label,
  controlProps,
  ...inputProps
}) => {
  const isInvalid = Boolean(invalidMessage)
  const borderColor = isInvalid ? 'error.400' : 'blueNight'
  const variantProps: IInputProps = {
    focusOutlineColor: borderColor,
    _focus: { borderColor: borderColor },
    _hover: { borderColor: borderColor },
  }
  return (
    <FormControl {...controlProps}>
      {label ? (
        <FormControl.Label
          margin={0}
          _text={{ color: 'blueNight', fontWeight: 'semibold', fontSize: 'sm' }}>
          {label}
        </FormControl.Label>
      ) : null}
      <Input
        fontSize="sm"
        fontWeight="light"
        {...inputProps}
        {...variantProps}
        variant="underlined"
      />
      <FormControl.ErrorMessage
        _text={{ fontSize: 'sm', color: 'danger.600', fontWeight: 200 }}
        isInvalid={isInvalid}>
        {invalidMessage}
      </FormControl.ErrorMessage>
    </FormControl>
  )
}

export const InputEmail: React.FC<InputProps> = (props) => {
  const emailProps: InputProps = {
    autoComplete: 'email',
    keyboardType: 'email-address',
  }
  return <InputOutline {...props} {...emailProps} />
}

export const InputPassword: React.FC<InputProps> = (props) => {
  const [show, setShow] = React.useState(false)
  const handleClick = useCallback(() => setShow((previousShow) => !previousShow), [])
  return (
    <InputOutline
      {...props}
      autoComplete="password"
      type={show ? 'text' : 'password'}
      InputRightElement={
        <IconButton
          size="xs"
          variant="ghost"
          onPress={handleClick}
          marginRight={1}
          icon={
            <MaterialCommunityIcons
              name={show ? 'eye-off' : 'eye'}
              size={24}
              color={Color.blueNight}
            />
          }
        />
      }
    />
  )
}
