export enum QueryKey {
  Me = 'Me',
  ContractBalance = 'ContractBalance',
  Transactions = 'Transactions',
  FronteggTenants = 'FronteggTenants',
  ToutesMesAides = 'ToutesMesAides',
  Axeptio = 'Axeptio',
  Maintenance = 'Maintenance',
  BlogItems = 'BlogItems',
  Organization = 'Organization',
  RefreshToken = 'RefreshToken',
}
