import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

import { Color } from 'theme'

const ArrowPrevIcon = () => (
  <Svg fill="none">
    <Path
      d="m4.151 8.955-.545.545.545.545 5.982 5.982.545-.545-.545.545c.3.3.3.783 0 1.083-.3.3-.784.3-1.084 0l-7.076-7.076c-.3-.3-.3-.783 0-1.084l7.076-7.076a.764.764 0 0 1 1.08-.003c.3.31.294.812.004 1.102L4.15 8.955Z"
      fill="#000"
      fillOpacity={0.54}
      stroke={Color.blueNight}
      strokeWidth={1.542}
    />
  </Svg>
)

export default ArrowPrevIcon
