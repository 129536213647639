import React, { FC } from 'react'
import Svg, { Circle, Path } from 'react-native-svg'

const Bank: FC = () => {
  return (
    <Svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <Circle cx="20" cy="20" r="19.25" fill="white" stroke="#A9AFC5" strokeWidth="1.5" />
      <Path
        d="M31.05 28.3785V28.3785C31.0498 28.1554 30.8879 27.9635 30.6761 27.9635H30.6758H9.32374C9.11195 27.9635 8.95 28.1554 8.95 28.3785C8.95 28.6016 9.11184 28.793 9.32374 28.793H30.6763C30.888 28.793 31.05 28.6016 31.05 28.3785Z"
        fill="#A9AFC5"
        stroke="#A9AFC5"
        strokeWidth="0.1"
      />
      <Path
        d="M10.97 26.9168L10.97 26.9169C10.9702 27.1398 11.1318 27.3317 11.3438 27.3317H28.656C28.8678 27.3317 29.0297 27.1398 29.0297 26.9168C29.0297 26.6936 28.8679 26.5024 28.656 26.5024H11.3438C11.1321 26.5024 10.97 26.6936 10.97 26.9168Z"
        fill="#A9AFC5"
        stroke="#A9AFC5"
        strokeWidth="0.1"
      />
      <Path
        d="M12.7283 25.456C12.728 25.6788 12.8896 25.8705 13.1014 25.8705H26.8986C27.1101 25.8705 27.272 25.679 27.272 25.456C27.272 25.2325 27.1101 25.0415 26.8986 25.0415H26.4086L26.4088 17.0487C26.4088 16.8253 26.2466 16.6342 26.0349 16.6342C25.8233 16.6342 25.6615 16.8254 25.6615 17.0487V25.0415H14.3386V17.0487C14.3386 16.8255 14.177 16.6342 13.9652 16.6342C13.7534 16.6342 13.5918 16.8255 13.5918 17.0487V25.0415H13.1017C12.89 25.0415 12.7283 25.2325 12.7283 25.456ZM12.7283 25.456C12.7283 25.4559 12.7283 25.4559 12.7283 25.4559L12.7783 25.456H12.7283C12.7283 25.456 12.7283 25.456 12.7283 25.456Z"
        fill="#A9AFC5"
        stroke="#A9AFC5"
        strokeWidth="0.1"
      />
      <Path
        d="M22.4292 18.4598L22.4291 18.4598C21.9781 17.992 21.3965 17.7343 20.7917 17.7306H20.7784C19.8434 17.7306 19.0281 18.3293 18.6158 19.2107L21.5296 19.2105C21.7412 19.2105 21.9028 19.402 21.9028 19.6254C21.9028 19.8484 21.7412 20.0399 21.5296 20.0399H18.3665C18.3439 20.1901 18.3307 20.3439 18.3307 20.5011C18.3307 20.5334 18.3325 20.5641 18.3344 20.5964C18.3345 20.5966 18.3345 20.5969 18.3345 20.5971L21.5291 20.5973C21.7407 20.5973 21.9023 20.7888 21.9023 21.0117C21.9023 21.2353 21.7408 21.4268 21.5291 21.4268H18.4724C18.811 22.5032 19.7189 23.271 20.7786 23.271C21.3883 23.271 21.9747 23.0124 22.4291 22.5413L22.4292 18.4598ZM22.4292 18.4598C22.5816 18.6177 22.8212 18.6062 22.9608 18.4346C23.0976 18.2671 23.0881 18.008 22.9389 17.8532L22.9388 17.8532M22.4292 18.4598L22.9388 17.8532M22.9388 17.8532C22.347 17.2401 21.5797 16.9017 20.7784 16.9017C19.4171 16.9017 18.2601 17.8656 17.7994 19.2103H17.1188C16.907 19.2103 16.7454 19.4016 16.7454 19.6252C16.7454 19.8482 16.907 20.0396 17.1188 20.0396H17.6133C17.5955 20.1903 17.5834 20.3436 17.5834 20.5009C17.5834 20.5321 17.5854 20.5653 17.5872 20.5965C17.5872 20.5966 17.5873 20.5967 17.5873 20.5969H17.119C16.9072 20.5969 16.7456 20.7883 16.7456 21.0113C16.7456 21.2347 16.9071 21.4264 17.119 21.4264H17.6943C18.0572 22.9587 19.297 24.0995 20.7786 24.0995C21.5799 24.0995 22.3473 23.7603 22.9392 23.1475L22.9392 23.1474M22.9388 17.8532L22.9392 23.1474M22.9392 23.1474C23.0882 22.9926 23.0979 22.7335 22.9608 22.566M22.9392 23.1474L22.9608 22.566M22.9608 22.566C22.8218 22.3945 22.5815 22.383 22.4291 22.5412L22.9608 22.566Z"
        fill="#A9AFC5"
        stroke="#A9AFC5"
        strokeWidth="0.1"
      />
      <Path
        d="M24.5466 16.6342C24.3352 16.6338 24.1731 16.8255 24.1731 17.0487V23.8999C24.1731 24.123 24.335 24.3142 24.5465 24.3142C24.7582 24.3142 24.9204 24.1231 24.9204 23.8999V17.0487C24.9204 16.8254 24.7582 16.6342 24.5466 16.6342ZM24.5466 16.6342C24.5466 16.6342 24.5466 16.6342 24.5465 16.6342V16.6842L24.5466 16.6342C24.5466 16.6342 24.5466 16.6342 24.5466 16.6342Z"
        fill="#A9AFC5"
        stroke="#A9AFC5"
        strokeWidth="0.1"
      />
      <Path
        d="M13.4013 15.9595C13.3282 15.9594 13.1935 15.9583 13.0652 15.9114C12.93 15.8619 12.7998 15.7604 12.7551 15.56C12.7551 15.56 12.7551 15.56 12.7551 15.56L12.8039 15.5491C12.7659 15.3794 12.8007 15.1285 13.1822 14.8974L13.4013 15.9595ZM13.4013 15.9595L13.4013 15.9595H13.4084H15.0794M13.4013 15.9595L15.0794 15.9595M15.0794 15.9595V23.9005C15.0794 24.1236 15.241 24.3148 15.4528 24.3148C15.6645 24.3148 15.8262 24.1236 15.8262 23.9005V15.9595H15.0794ZM19.6387 11.8735L19.6387 11.8735C19.7421 11.8108 19.856 11.7789 19.9783 11.7789C20.0847 11.7789 20.1803 11.8028 20.2496 11.827C20.2842 11.8391 20.3119 11.8511 20.3308 11.86C20.3402 11.8645 20.3475 11.8682 20.3522 11.8706L20.3574 11.8734L20.3583 11.8739L20.3585 11.874L25.7353 15.1301H14.2618L19.6387 11.8735Z"
        fill="#A9AFC5"
        stroke="#A9AFC5"
        strokeWidth="0.1"
      />
    </Svg>
  )
}

export default Bank
