import { Asset } from 'expo-asset'
import * as WebBrowser from 'expo-web-browser'
import { Box, Center, Spacer, Text, useDisclose } from 'native-base'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { useApi } from 'api'
import { analytics } from 'services/analytics'
import { AnalyticsKey } from 'services/analytics/types'
import { QueryKey } from 'services/queryClient'
import { Color } from 'theme'
import { ButtonSolid } from 'ui/Buttons'
import { CustomModal } from 'ui/Modals'
import { useUserContext } from 'user/context'

import { Card } from './Card'

export const ToutesMesAidesCard: React.FC = () => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclose()
  const { contract } = useUserContext()

  const api = useApi()

  const { data, isFetching, error } = useQuery(
    QueryKey.ToutesMesAides,
    async () =>
      await api.toutesMesAidesControllerGetConnectionUrl({
        uid: contract!.user.id,
        email: contract!.user.email,
      }),
    { enabled: contract?.organization.isToutesMesAidesEnabled }
  )

  function handleOpen() {
    analytics.log(AnalyticsKey.OPEN_CLARO_MODAL)
    onOpen()
  }

  function renderContent() {
    if (contract?.organization.isToutesMesAidesEnabled) {
      return (
        <ButtonSolid
          isDisabled={isFetching || !!error}
          isLoading={isFetching}
          isLoadingText={t('toutesMesAides.buttonLoading')}
          title={error ? t('toutesMesAides.buttonError') : t('toutesMesAides.buttonText')}
          onPress={() => {
            WebBrowser.openBrowserAsync(data!.url)
            onClose()
          }}
          backgroundColor={Color.toutesMesAides}
          borderColor={Color.toutesMesAides}
          colorScheme="primary"
          _text={{ fontSize: 'lg' }}
        />
      )
    }
    return <Text fontSize="md">{t('account.toutesMesAidesDeactivated')}</Text>
  }

  return (
    <>
      <Card
        backgroundUri={Asset.fromModule(require('./klaro_card_dark.jpeg')).uri}
        onPress={handleOpen}>
        <Spacer />
        <Text fontSize="xl" fontWeight="bold" color="white">
          {t('account.toutesMesAidesTitle')}
        </Text>
        <Box h={2} />
        <Text fontSize="md" color="white">
          {t('account.toutesMesAidesDescription')}
        </Text>
      </Card>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        header={<Text color="white">{t('toutesMesAides.title')}</Text>}
        modalHeaderProps={{ backgroundColor: Color.toutesMesAides, textAlign: 'center' }}
        content={
          <Center>
            <Text fontSize="lg" color={Color.toutesMesAides}>
              {t('toutesMesAides.topText')}
            </Text>
            <Box h={6} />
            {renderContent()}
            <Box h={6} />
            <Text color="black" fontSize="sm">
              {t('toutesMesAides.bottomText')}
            </Text>
          </Center>
        }
      />
    </>
  )
}
