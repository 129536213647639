import { IconButton } from 'native-base'
import React from 'react'

import { ButtonSolid } from 'ui/Buttons'

import ArrowPrevIcon from './icons/ArrowPrevIcon'

interface SwiperButtonProps {
  onPress?: () => void
  title: string
}
export interface SwiperNextButtonProps extends SwiperButtonProps {}
export interface SwiperPrevButtonProps extends SwiperButtonProps {
  type: 'prev'
}

export const SwiperNextButton: React.FC<SwiperNextButtonProps> = (props) => {
  const { onPress, title } = props
  return (
    <ButtonSolid
      title={title}
      rounded={15}
      colorScheme="primary"
      onPress={onPress}
      position="absolute"
      bottom="40px"
      width={{ base: '300px', md: '400px' }}
    />
  )
}

export const SwiperPrevButton: React.FC<SwiperPrevButtonProps> = (props) => {
  const { onPress } = props
  return (
    <IconButton onPress={onPress} position="absolute" top={2} left={2}>
      <ArrowPrevIcon />
    </IconButton>
  )
}
