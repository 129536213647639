import { openBrowserAsync } from 'expo-web-browser'
import { Box, Column, ScrollView, Text } from 'native-base'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenHeader } from 'components/ScreenHeader'
import { TabBarSpacer } from 'components/TabBar'
import { config } from 'config'
import { AuthedScreenProps } from 'types/navigation'
import { ButtonSolid } from 'ui/Buttons'
import { useUserContext } from 'user/context'
import { isNonEmployee } from 'user/roles'

type Props = AuthedScreenProps<'Unauthorized'>

export const UnauthorizedScreen: React.FC<Props> = () => {
  const { contract, logout } = useUserContext()
  const { t } = useTranslation()
  async function goToClientApp() {
    await openBrowserAsync(config.CLIENT_APP_URL)
  }

  const hasAccessToClientApp =
    isNonEmployee(contract?.roles) && contract?.isAppAccessEnabled === true

  return (
    <ScrollView contentContainerStyle={{ alignItems: 'center' }}>
      <ScreenHeader title={t('account.title')} />
      <Column width="100%" alignItems="center" paddingX={5} maxW={400}>
        <Box h={5} />

        <Text fontSize="md" textAlign="center">
          {t('account.unauthorized.title')}
        </Text>

        <Box h={10} />

        <Text fontSize="sm" textAlign="left" width="100%">
          {t('account.unauthorized.loggedAs')}
        </Text>
        <Text fontSize="sm" textAlign="left" width="100%" fontWeight="medium">
          {contract?.user?.email}
        </Text>

        {hasAccessToClientApp ? (
          <>
            <Box h={5} />
            <Text fontSize="sm" textAlign="left" width="100%">
              {t("Aller sur l'application administrateur : ")}
            </Text>
            <Box h={3} />
            <ButtonSolid
              title={t('Administration 🚀')}
              onPress={goToClientApp}
              colorScheme="secondary"
              rounded="lg"
              width="100%"
            />
          </>
        ) : null}

        <Box h={10} />

        <ButtonSolid
          title={t('account.logout')}
          onPress={logout}
          colorScheme="primary"
          rounded="lg"
          width="100%"
        />
      </Column>
      <TabBarSpacer />
    </ScrollView>
  )
}
