import clamp from 'lodash/clamp'
import throttle from 'lodash/throttle'
import { Center, View, Text } from 'native-base'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Animated,
  GestureResponderEvent,
  LayoutChangeEvent,
  Platform,
  TextStyle,
  ViewStyle,
} from 'react-native'
import { CopilotStep, useCopilot, walkthroughable } from 'react-native-copilot'
import Svg, { Circle, Defs, LinearGradient, Path, Stop } from 'react-native-svg'

import { analytics } from 'services/analytics'
import { AnalyticsKey } from 'services/analytics/types'
import { Color } from 'theme'
import { ZIndexMember } from 'utils/position'

type Props = {
  userBalance: number
  selectedAmount: number
  setSelectedAmount: React.Dispatch<React.SetStateAction<number>>
}

const AnimatedCircle = Animated.createAnimatedComponent(Circle)
const progress = new Animated.Value(0)

const dx = 36
const dy = 12
const sliderShadowOffset = 0.1
const circleShadowOffset = -0.2
const curvedPath = 'M2 3 Q 18 10 34 3'
const circlePath = 'M18 2.0845 a 16 16 0 0 1 0 32 a 16 16 0 0 1 0 -32'
const shadowColor = 'rgba(200,200,200,0.5)'

function computeAdvanceRequestStep(availableAmount?: number) {
  if (availableAmount && !isNaN(availableAmount)) {
    if (availableAmount > 1000) return 25
    if (availableAmount > 500) return 20
  }
  return 10
}

const THROTTLE_DURATION = Platform.select({ default: 10, web: 50 }) // in milliseconds

export type SelectDepositAmountHandle = {
  reset: () => void
}

export const SelectDepositAmount = forwardRef<SelectDepositAmountHandle, Props>(
  ({ userBalance, selectedAmount, setSelectedAmount }, ref) => {
    const { t } = useTranslation()

    const copilot = useCopilot()
    const CopilotCenter = walkthroughable(Center)

    const [percent, setPercent] = useState(0)
    const [svgWidth, setSvgWidth] = useState(0)

    const onStartShouldSetResponder = useCallback(() => true, [])
    const onResponderEnd = useCallback(() => {
      analytics.log(AnalyticsKey.UPDATE_SLIDER_AMOUNT, { amount: selectedAmount })
      return true
    }, [selectedAmount])
    const onSvgLayout = useCallback((event: LayoutChangeEvent) => {
      const value = event.nativeEvent.layout.width
      setSvgWidth(value)
    }, [])
    const throttledMeasure = useCallback(
      throttle((locationX: number) => {
        if (!locationX || !userBalance) return
        const buffer = 40
        const totalValue = svgWidth - buffer
        const value = clamp(locationX - buffer, 0, totalValue)
        let amount = (value * userBalance) / totalValue
        const step = computeAdvanceRequestStep(userBalance)
        amount = Math.ceil(amount / step) * step
        amount = clamp(amount, 0, userBalance)
        if (userBalance >= 10 && amount < 10) amount = 10
        const percent = userBalance > 0 ? amount / userBalance : 0
        setSelectedAmount(amount)
        setPercent(percent)
        progress.setValue(percent)
      }, THROTTLE_DURATION),
      [svgWidth, userBalance, setSelectedAmount]
    )
    const onResponderMove = useCallback(
      (event: GestureResponderEvent) => {
        throttledMeasure(event?.nativeEvent?.locationX)
      },
      [throttledMeasure]
    )

    const setInitialValues = useCallback(() => {
      throttledMeasure(svgWidth / 5)
    }, [svgWidth, throttledMeasure])

    useEffect(() => {
      setInitialValues()
    }, [setInitialValues])

    useImperativeHandle(ref, () => ({ reset: setInitialValues }), [setInitialValues])

    const circleProgressStroke = useMemo(
      () => `${100 * percent}, ${100 * (1 - percent)}`,
      [percent]
    )
    const sliderStroke = useMemo(() => `${33 * percent}, ${33 * (1 - percent)}`, [percent])

    const CircleSVG: JSX.Element = useMemo(
      () => (
        <Svg viewBox="-0.5 -0.5 37 37">
          <Path
            d={circlePath}
            stroke={shadowColor}
            fill="none"
            strokeWidth={4.5}
            strokeLinecap="round"
            transform={`translate(0, ${circleShadowOffset})`}
          />
          <Path
            d={circlePath}
            fill="none"
            stroke={Color.white}
            strokeWidth={4.1}
            strokeLinecap="round"
          />
          <Path
            d={circlePath}
            fill="none"
            stroke={Color.blueNight}
            strokeWidth={4.5}
            strokeDasharray={circleProgressStroke}
            strokeLinecap="round"
          />
        </Svg>
      ),
      [circleProgressStroke]
    )

    const SliderSVG: JSX.Element = useMemo(
      () => (
        <Svg viewBox={`0 0 ${dx} ${dy}`}>
          <Defs>
            <LinearGradient id="yellowGradient" x1="0" y1="0" x2="1" y2="0">
              <Stop offset="0%" stopColor={Color.yellow} />
              <Stop offset="25%" stopColor={Color.yellow} />
              <Stop offset="100%" stopColor={Color.white} />
            </LinearGradient>
          </Defs>
          <Path
            d={curvedPath}
            stroke={shadowColor}
            fill="none"
            strokeWidth={4}
            strokeLinecap="round"
          />
          <Path
            d={curvedPath}
            fill="none"
            stroke={Color.darkYellow}
            strokeWidth={4}
            strokeDasharray={sliderStroke}
            strokeLinecap="round"
          />
          <Path
            d={curvedPath}
            fill="none"
            stroke={Color.lightGrey}
            strokeWidth={3.6}
            strokeLinecap="round"
            transform={`translate(0, ${sliderShadowOffset})`}
          />
          <Path
            d={curvedPath}
            fill="none"
            stroke={`url(#yellowGradient)`}
            strokeWidth={3.6}
            strokeDasharray={sliderStroke}
            strokeLinecap="round"
            transform={`translate(0, ${sliderShadowOffset})`}
          />
          <AnimatedLogo progress={progress} />
        </Svg>
      ),
      [sliderStroke]
    )

    return (
      <View width="100%" height="100%">
        <Text position="absolute" fontSize="md" style={styles.action}>
          {t('home.action')}
        </Text>
        <Text position="absolute" fontSize="4xl" fontWeight="semibold" style={styles.amount}>
          {selectedAmount} €
        </Text>
        <View position="absolute" style={styles.svg}>
          <Center height="4/6">{CircleSVG}</Center>
          {/* Component <CopilotCenter> prevent <SliderSVG> from working */}
          {/* We use it only if product tour is visible */}
          {!copilot.visible ? (
            <CopilotStep name="2" order={2} text={t('productTour.steps.2')}>
              <Center
                height="2/6"
                onStartShouldSetResponder={onStartShouldSetResponder}
                onResponderMove={onResponderMove}
                onResponderEnd={onResponderEnd}
                onLayout={onSvgLayout}>
                {SliderSVG}
              </Center>
            </CopilotStep>
          ) : (
            <CopilotStep name="2" order={2} text={t('productTour.steps.2')}>
              <CopilotCenter copilot={{}} height="2/6" onLayout={onSvgLayout}>
                {SliderSVG}
              </CopilotCenter>
            </CopilotStep>
          )}
        </View>
      </View>
    )
  }
)

const CX = { inputRange: [0, 0.5, 1], outputRange: [2, 19, 34] }
const cyUnit = 1 / 6
const CY = {
  inputRange: [0, cyUnit, 2 * cyUnit, 3 * cyUnit, 4 * cyUnit, 5 * cyUnit, 1],
  outputRange: [3, 5, 6.2, 6.6, 6.2, 4.9, 3],
}
export const AnimatedLogo = ({ progress }: { progress: Animated.Value }) => {
  const cx = useMemo(
    () => progress.interpolate({ inputRange: CX.inputRange, outputRange: CX.outputRange }),
    [progress]
  )
  const cy = useMemo(
    () => progress.interpolate({ inputRange: CY.inputRange, outputRange: CY.outputRange }),
    [progress]
  )
  return (
    <>
      <AnimatedCircle cx={cx} cy={cy} r="2" fill={Color.blueNight} />
      <AnimatedCircle cx={cx} cy={cy} r="1.33" fill="white" />
      <AnimatedCircle cx={cx} cy={cy} r="0.66" fill={Color.yellow} />
    </>
  )
}

const styles: Record<string, TextStyle | ViewStyle> = {
  action: { textAlign: 'center', left: 0, right: 0, top: '25%' },
  amount: { zIndex: ZIndexMember.reqAmount, textAlign: 'center', left: 0, right: 0, top: '30%' },
  svg: { zIndex: ZIndexMember.svgCircle, left: 0, right: 0, top: 0, bottom: 0 },
}
