import { format } from 'date-fns'
import { fr, enUS } from 'date-fns/locale'
import { locale } from 'expo-localization'
import { ScrollView, Text, Box, Column } from 'native-base'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenHeader } from 'components/ScreenHeader'
import { TabBarSpacer } from 'components/TabBar'
import { AuthedScreenProps } from 'types/navigation'
import { ButtonSolid } from 'ui/Buttons'
import { useUserContext } from 'user/context'

type Props = AuthedScreenProps<'ContractEnded'>

export const ContractEndedScreen: React.FC<Props> = () => {
  const { t } = useTranslation()
  const { contract, logout } = useUserContext()

  const contractEndDate = new Date(contract?.contractEndDate || '')
  const datestring = format(contractEndDate, 'PPPP', { locale: locale === 'fr-FR' ? fr : enUS })

  return (
    <ScrollView contentContainerStyle={{ alignItems: 'center' }}>
      <ScreenHeader title={t('account.title')} />
      <Column width="100%" alignItems="center" paddingX={5} maxW={400}>
        <Box h={5} />

        <Text fontSize="md" textAlign="center">
          {t('account.unauthorized.alternativeTitle')}
        </Text>

        <Box h={10} />

        <Text fontSize="sm" textAlign="left" width="100%">
          {t('account.unauthorized.loggedAs')}
        </Text>
        <Text fontSize="sm" textAlign="left" width="100%" fontWeight="medium">
          {contract?.user.email}
        </Text>

        <Box h={10} />

        <Text fontSize="sm" textAlign="left" width="100%" fontWeight={600}>
          {t('account.unauthorized.contractEnded')}
        </Text>
        <Box h={10} />
        <Text fontSize="sm" textAlign="left" width="100%">
          {t('account.unauthorized.contractEndDate')}
        </Text>
        <Text fontSize="sm" textAlign="left" width="100%" fontWeight="medium">
          {datestring}
        </Text>

        <Box h={10} />

        <Text fontSize="sm" textAlign="left" width="100%">
          {t('account.unauthorized.helperText')}
        </Text>

        <Box h={20} />

        <ButtonSolid
          title={t('account.logout')}
          onPress={logout}
          colorScheme="primary"
          rounded="lg"
          width="100%"
        />
      </Column>
      <TabBarSpacer />
    </ScrollView>
  )
}
