import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { TransitionPresets, createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { Platform } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { CloudyBackground } from 'components/Background'
import HelpIcon from 'components/icons/HelpIcon'
import TransactionIcon from 'components/icons/TransactionIcon'
import NesspayIcon from 'components/NesspaySvgIconSmall'
import { DefaultStatusBar } from 'components/StatusBar'
import { TabBar } from 'components/TabBar'
import { AccountScreen } from 'screens/Account/AccountScreen'
import { BlogArticlesScreen } from 'screens/Account/BlogArticlesScreen'
import { ContractEndedScreen } from 'screens/Account/ContractEndedScreen'
import { InactiveAccountScreen } from 'screens/Account/InactiveAccountScreen'
import { ProfileScreen } from 'screens/Account/ProfileScreen'
import { UnauthorizedScreen } from 'screens/Account/UnauthorizedScreen'
import { HomeScreen } from 'screens/HomeScreen'
import { SignOutScreen } from 'screens/SignOutScreen'
import { TransactionsScreen } from 'screens/Transaction/TransactionsScreen'
import { AccountStackParamList, AuthedStackParamList, TabStackParamList } from 'types/navigation'

const AuthedStackNav = createStackNavigator<AuthedStackParamList>()
const Tab = createBottomTabNavigator<TabStackParamList>()

export const AuthedStack: React.FC = () => {
  const safeAreaInsets = useSafeAreaInsets()

  return (
    <>
      <DefaultStatusBar />
      <CloudyBackground />
      <AuthedStackNav.Navigator
        initialRouteName="TabNav"
        screenOptions={{
          headerShown: false,
          ...transitionPresets,
          cardStyle: { paddingTop: safeAreaInsets.top },
        }}>
        <AuthedStackNav.Screen name="TabNav" component={TabNav} />
        <AuthedStackNav.Screen name="Unauthorized" component={UnauthorizedScreen} />
        <AuthedStackNav.Screen name="SignOut" component={SignOutScreen} />
        <AuthedStackNav.Screen name="InactiveAccount" component={InactiveAccountScreen} />
        <AuthedStackNav.Screen name="ContractEnded" component={ContractEndedScreen} />
      </AuthedStackNav.Navigator>
    </>
  )
}

export const TabNav: React.FC = () => {
  return (
    <Tab.Navigator screenOptions={{ headerShown: false }} tabBar={(props) => <TabBar {...props} />}>
      <Tab.Screen
        name="Home"
        component={HomeScreen}
        options={{ tabBarIcon: ({ focused }) => <NesspayIcon colored={focused} /> }}
      />
      <Tab.Screen
        name="Transactions"
        component={TransactionsScreen}
        options={{ tabBarIcon: ({ focused }) => <TransactionIcon colored={focused} /> }}
      />
      <Tab.Screen
        name="AccountNav"
        component={AccountStackNavigator}
        options={{ tabBarIcon: ({ focused }) => <HelpIcon colored={focused} /> }}
      />
    </Tab.Navigator>
  )
}

const AccountStack = createStackNavigator<AccountStackParamList>()

const transitionPresets = Platform.select({
  default: TransitionPresets.DefaultTransition,
  ios: TransitionPresets.ModalFadeTransition,
})
const AccountStackNavigator: React.FC = () => {
  return (
    <AccountStack.Navigator
      initialRouteName="Account"
      screenOptions={{ headerShown: false, ...transitionPresets }}>
      <AccountStack.Screen name="Account" component={AccountScreen} />
      <AccountStack.Screen name="Profile" component={ProfileScreen} />
      <AccountStack.Screen name="BlogArticles" component={BlogArticlesScreen} />
    </AccountStack.Navigator>
  )
}
