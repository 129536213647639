import { useAssets } from 'expo-asset'
import { View } from 'native-base'
import React, { FC, PropsWithChildren } from 'react'
import { useWindowDimensions, ImageBackground } from 'react-native'

export const CloudyBackground: FC<PropsWithChildren> = (props) => {
  const { width, height } = useWindowDimensions()
  const [assets] = useAssets(require('../../assets/icon-background.png'))
  const backgroundImage = assets ? assets[0] : null
  if (!backgroundImage) {
    return null
  }
  return (
    <ImageBackground
      blurRadius={60}
      style={{
        opacity: 0.1,
        justifyContent: 'center',
        position: 'absolute',
        width,
        height,
      }}
      resizeMode="cover"
      source={{
        uri: backgroundImage.uri,
      }}>
      {props.children}
    </ImageBackground>
  )
}

export const WhiteBackground: FC<PropsWithChildren> = (props) => {
  const { width, height } = useWindowDimensions()
  return (
    <View
      style={{
        opacity: 0.1,
        justifyContent: 'center',
        position: 'absolute',
        width,
        height,
      }}>
      {props.children}
    </View>
  )
}
