import * as Linking from 'expo-linking'
import { Box, Column, Text } from 'native-base'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { config } from 'config'
import { IS_NATIVE } from 'services/platform'
import { ButtonSolid } from 'ui/Buttons'

export class GlobalErrorBoundary extends React.Component<PropsWithChildren> {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error('Uncaught error:', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Column style={{ width: 340, justifyContent: 'center' }}>
            {IS_NATIVE ? <ErrorMessageNative /> : <ErrorMessageWeb />}
          </Column>
        </Box>
      )
    }
    return this.props.children
  }
}

const ErrorMessageNative = () => {
  const { t } = useTranslation()
  return (
    <>
      <Text>{t('errorBoundary.global.issueWithNativeApp')}</Text>
      <ButtonSolid
        title={t('errorBoundary.global.goToWebApp')}
        colorScheme="primary"
        onPress={() => Linking.openURL(config.EMPLOYEE_APP_URL)}
        marginTop={10}
      />
    </>
  )
}

const ErrorMessageWeb = () => {
  const { t } = useTranslation()
  return (
    <>
      <Text>{t('errorBoundary.global.issueWithWebApp')}</Text>
      <Text>{t('errorBoundary.global.contactSupport')}</Text>
    </>
  )
}
