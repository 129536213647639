import { useNavigation } from '@react-navigation/native'
import { Asset } from 'expo-asset'
import { Box, Text } from 'native-base'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { analytics } from 'services/analytics'
import { AnalyticsKey } from 'services/analytics/types'
import { Color } from 'theme'

import { Card } from './Card'

export const BlogCard: React.FC = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigation()

  function handleOpen() {
    analytics.log(AnalyticsKey.OPEN_BLOG)
    navigate('TabNav', { screen: 'AccountNav', params: { screen: 'BlogArticles' } })
  }

  return (
    <Card
      onPress={handleOpen}
      backgroundColor={Color.blueNight}
      backgroundUri={Asset.fromModule(require('./blog_card_dark.jpeg')).uri}>
      <Text fontSize="md" fontWeight="bold" color="white" style={{ margin: 0 }}>
        {t('account.blogTitle')}
      </Text>
      <Box h={2} />
      <Text fontSize="sm" color="white">
        {t('account.blogDescription')}
      </Text>
    </Card>
  )
}
