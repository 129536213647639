import { View } from 'native-base'
import React, { ComponentClass, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Swiper from 'react-native-web-swiper'

import WelcomeTutorialSvgPageOne from 'components/icons/WelcomeTutorialPageOne'
import WelcomeTutorialSvgPageThree from 'components/icons/WelcomeTutorialPageThree'
import WelcomeTutorialSvgPageTwo from 'components/icons/WelcomeTutorialPageTwo'
import {
  SwiperNextButton,
  SwiperPrevButton,
  SwiperNextButtonProps,
  SwiperPrevButtonProps,
} from 'components/SwiperButtons'
import WelcomeTutorialTemplate from 'components/WelcomeTutorialTemplate'
import { useGetEmail } from 'hooks/useGetEmail'
import { useHasLoggedInAtLeastOnce } from 'hooks/useHasLoggedIn'
import { storage } from 'services/storage'
import { Color } from 'theme'
import { RootScreenProps } from 'types/navigation'

type Props = RootScreenProps<'WelcomeTutorial'>

export const WelcomeTutorialScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation()
  const { userEmail } = useGetEmail()
  const { hasLoggedInAtLeastOnce, hasLoggedInAtLeastOnceLoading } = useHasLoggedInAtLeastOnce()

  useEffect(() => {
    if (hasLoggedInAtLeastOnce) {
      navigation.navigate('SignIn', { login_hint: userEmail })
    }
  }, [navigation, hasLoggedInAtLeastOnce, userEmail])

  if (hasLoggedInAtLeastOnceLoading) {
    return <></>
  }

  return (
    <View
      style={{ flex: 1, marginLeft: 'auto', marginRight: 'auto' }}
      width="100%"
      minW="300px"
      maxW="800px"
      maxH={window.innerHeight}>
      <Swiper
        controlsProps={{
          dotsTouchable: true,
          dotsPos: 'bottom',
          dotActiveStyle: { backgroundColor: Color.blueNight },
          prevPos: false,
          nextPos: 'bottom',
          PrevComponent: ((props: SwiperPrevButtonProps) => (
            <SwiperPrevButton {...props} />
          )) as unknown as ComponentClass<Record<string, unknown>, any>,
          NextComponent: ((props: SwiperNextButtonProps) => (
            <SwiperNextButton {...props} title={t('welcomeTutorial.nextButton')} />
          )) as unknown as ComponentClass<Record<string, unknown>, any>,
          lastNextElement: (
            <SwiperNextButton
              title={t('login.signInButton')}
              onPress={async () => {
                await storage.save('@UserHasLoggedInAtLeastOnce', true)
                navigation.navigate('SignIn')
              }}
            />
          ),
        }}>
        <WelcomeTutorialTemplate
          title={t('welcomeTutorial.pageOne.title')}
          logo={<WelcomeTutorialSvgPageOne />}
          description={t('welcomeTutorial.pageOne.description')}
          number={1}
        />
        <WelcomeTutorialTemplate
          title={t('welcomeTutorial.pageTwo.title')}
          logo={<WelcomeTutorialSvgPageTwo />}
          description={t('welcomeTutorial.pageTwo.description')}
          number={2}
        />
        <WelcomeTutorialTemplate
          title={t('welcomeTutorial.pageThree.title')}
          logo={<WelcomeTutorialSvgPageThree />}
          description={t('welcomeTutorial.pageThree.description')}
          number={3}
        />
      </Swiper>
    </View>
  )
}
