import AsyncStorage from '@react-native-async-storage/async-storage'
import { Spinner } from 'native-base'
import React, { useEffect } from 'react'

import { useAuthContext } from 'auth/authProvider'
import { Color } from 'theme'
import { AuthedScreenProps } from 'types/navigation'

type Props = AuthedScreenProps<'SignOut'>

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const SignOutScreen: React.FC<Props> = () => {
  const { setContext } = useAuthContext()

  useEffect(() => {
    AsyncStorage.removeItem('@access_token').then(() => {
      sleep(2000).then(() => {
        setContext({ isAuthenticated: false, accessToken: undefined })
      })
    })
  }, [setContext])

  return <Spinner style={{ height: '100%' }} size="lg" color={Color.blueNight} />
}
