import { View, Text } from 'native-base'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleProp, ViewStyle, TextStyle } from 'react-native'
import { useCopilot } from 'react-native-copilot'

import { Color } from 'theme'
import { ButtonSolid } from 'ui/Buttons'

export const CopilotStepNumber = () => {
  const { currentStepNumber } = useCopilot()
  return (
    <View style={styles.stepNumber.view}>
      <Text style={styles.stepNumber.text}>{currentStepNumber}</Text>
    </View>
  )
}

export const CopilotTooltip = () => {
  const { t } = useTranslation()
  const { goToNext, goToPrev, stop, currentStep, isLastStep } = useCopilot()
  return (
    <View style={{ padding: 0 }}>
      <View style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Text>{currentStep?.text}</Text>
      </View>
      <View style={styles.tooltip.view}>
        {!isLastStep ? (
          <ButtonSolid
            title={t('productTour.buttons.skip')}
            onPress={() => stop()}
            rounded="md"
            colorScheme="primary"
            style={styles.tooltip.button}
          />
        ) : (
          <ButtonSolid
            title={t('productTour.buttons.prev')}
            onPress={() => goToPrev()}
            rounded="md"
            colorScheme="primary"
            style={styles.tooltip.button}
          />
        )}
        {!isLastStep ? (
          <ButtonSolid
            title={t('productTour.buttons.next')}
            onPress={() => goToNext()}
            rounded="md"
            colorScheme="primary"
            style={styles.tooltip.button}
          />
        ) : (
          <ButtonSolid
            title={t('productTour.buttons.last')}
            onPress={() => stop()}
            rounded="md"
            colorScheme="primary"
            style={styles.tooltip.button}
          />
        )}
      </View>
    </View>
  )
}

const styles = {
  stepNumber: {
    view: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 2,
      borderRadius: 14,
      borderColor: Color.white,
      backgroundColor: Color.yellow,
    } as StyleProp<ViewStyle>,
    text: {
      fontSize: 15,
      backgroundColor: 'transparent',
      color: Color.white,
      marginTop: -2,
    } as StyleProp<TextStyle>,
  },
  tooltip: {
    view: {
      padding: 10,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    } as StyleProp<ViewStyle>,
    button: {
      margin: 3,
      backgroundColor: Color.yellow,
      borderColor: Color.yellow,
    } as StyleProp<ViewStyle>,
  },
}
