export function deepIterateOnObject(object: any, fn: any) {
  const arr = []
  function recurse(obj: any, map: any, depth: any) {
    Object.keys(obj).forEach((a) => {
      fn(obj[a], obj, a, depth)
      if (typeof obj[a] == 'object' && obj[a] != null) {
        map.push(a)
        arr.push(map)
        recurse(obj[a], [...map], depth + 1)
      }
    })
  }
  recurse(object, [], 1)
}
