import { openBrowserAsync } from 'expo-web-browser'
import { Text, Box, Spacer } from 'native-base'
import React, { useCallback, useState } from 'react'
import { ImageBackground, LayoutChangeEvent, Pressable, ViewStyle } from 'react-native'

import { WebflowBlogPost } from 'api/gen'
import { Color } from 'theme'

type Props = {
  blogPost: WebflowBlogPost
}

export const BlogPostCard: React.FC<Props> = (props) => {
  const { blogPost } = props

  const [backgroundSize, setBackgroundSize] = useState({ width: 0, height: 0 })
  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setBackgroundSize({
      width: event.nativeEvent.layout.width,
      height: event.nativeEvent.layout.height,
    })
  }, [])
  const onPress = useCallback(async () => {
    const url = 'https://www.nesspay.co/blog/' + blogPost.slug
    openBrowserAsync(url)
  }, [blogPost.slug])

  // @ts-ignore: the type is wrongly generated with "_" instead of "-"
  const imageUrl = blogPost?.['thumbnail-image']?.url
  // @ts-ignore: the type is wrongly generated with "_" instead of "-"
  const readingTime = blogPost['temps-de-lecture']
  const title = blogPost.name
  return (
    <Box
      onLayout={imageUrl ? onLayout : undefined}
      backgroundColor={imageUrl ? undefined : Color.blueNight}
      height={160}
      width="100%"
      shadow="3"
      mb={7}
      rounded="lg">
      {imageUrl ? (
        <ImageBackground
          resizeMode="cover"
          style={{
            position: 'absolute',
            overflow: 'hidden',
            borderRadius: 10,
            width: backgroundSize.width,
            height: backgroundSize.height,
          }}
          source={{ uri: imageUrl }}
        />
      ) : null}

      <Pressable style={contentStyle} onPress={onPress}>
        <Text fontSize="md" fontWeight="bold" color="white">
          {readingTime}
        </Text>
        {imageUrl ? null : (
          <>
            <Spacer h={2} />
            <Text fontSize="lg" fontWeight="bold" color="white">
              {title}
            </Text>
          </>
        )}
      </Pressable>
    </Box>
  )
}

const contentStyle: ViewStyle = {
  padding: 15,
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}
