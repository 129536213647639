import { MaterialIcons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { openBrowserAsync } from 'expo-web-browser'
import { Box, Column, IconButton, Row, ScrollView, Text } from 'native-base'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenHeader } from 'components/ScreenHeader'
import { TabBarSpacer } from 'components/TabBar'
import { config } from 'config'
import { analytics } from 'services/analytics'
import { AnalyticsKey } from 'services/analytics/types'
import { ConsentsContext } from 'services/consent/provider'
import { IS_ANDROID, IS_WEB } from 'services/platform'
import { Color } from 'theme'
import { AccountScreenProps } from 'types/navigation'
import { ButtonSolid } from 'ui/Buttons'
import { InputUnderlined } from 'ui/Inputs'
import { useUserContext } from 'user/context'
import { maskIBAN } from 'utils/mask'

import { Card } from './Card'

type Props = AccountScreenProps<'Profile'>

export const ProfileScreen: React.FC<Props> = () => {
  const { contract, logout } = useUserContext()
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const { setShowWidget } = useContext(ConsentsContext)

  function goToAccount() {
    navigate('TabNav', { screen: 'AccountNav', params: { screen: 'Account' } })
  }
  function openConsentsWidget() {
    analytics.log(AnalyticsKey.OPEN_CONSENTS_WIDGET)
    setShowWidget(true)
  }
  async function goToPrivacyPolicy() {
    analytics.log(AnalyticsKey.OPEN_PRIVACY_POLICY)
    await openBrowserAsync(config.PRIVACY_POLICY_URL)
  }
  async function handleLogout() {
    analytics.log(AnalyticsKey.LOGOUT)
    await logout()
  }

  return (
    <ScrollView contentContainerStyle={{ alignItems: 'center' }}>
      <ScreenHeader title={t('account.title')} />
      <Column width="100%" alignItems="center" paddingX={5} maxW={400}>
        <Card height={300}>
          <Row width="100%" height={60} justifyContent="center" alignItems="center">
            <IconButton
              position="absolute"
              left={0}
              size="sm"
              variant="ghost"
              onPress={goToAccount}
              marginLeft={-5}
              icon={<MaterialIcons color={Color.blueNight} name="keyboard-arrow-left" size={48} />}
            />
            <Text fontSize="lg" fontWeight="medium">
              {contract?.user.name}
            </Text>
          </Row>

          <Box h={3} />

          <InputUnderlined
            label={t('Mon IBAN')}
            isReadOnly={true}
            value={maskIBAN(contract?.user.iban)}
          />

          <Box h={3} />

          <InputUnderlined
            label={t('login.email.label')}
            isReadOnly={true}
            value={contract?.user.email}
          />
        </Card>

        <Box h={20} />

        {IS_ANDROID || IS_WEB ? (
          <ButtonSolid
            title={t('account.cookies')}
            onPress={openConsentsWidget}
            colorScheme="primary"
            rounded="lg"
            width="100%"
          />
        ) : null}

        <Box h={3} />

        <ButtonSolid
          title={t('account.cgu')}
          onPress={goToPrivacyPolicy}
          colorScheme="primary"
          rounded="lg"
          width="100%"
        />

        <Box h={3} />

        <ButtonSolid
          title={t('account.logout')}
          onPress={handleLogout}
          colorScheme="primary"
          rounded="lg"
          width="100%"
        />
      </Column>

      <TabBarSpacer />
    </ScrollView>
  )
}
