import {
  DefaultTheme,
  DocumentTitleOptions,
  NavigationContainer,
  Theme,
  useNavigationContainerRef,
} from '@react-navigation/native'
import React, { PropsWithChildren, useRef } from 'react'

import { analytics } from 'services/analytics'

import { linking } from './linking'

const documentTitle: DocumentTitleOptions = {
  formatter: (options) => {
    if (options?.title) return `${options.title} - NessPay`
    return 'NessPay - Salaire à la demande'
  },
}

const theme: Theme = {
  ...DefaultTheme,
  colors: { ...DefaultTheme.colors, background: 'transparent' },
}

export const NavigationProvider: React.FC<PropsWithChildren> = (props) => {
  const routeNameRef = useRef<string>()
  const navigationRef = useNavigationContainerRef()

  function onReady() {
    routeNameRef.current = navigationRef.getCurrentRoute()?.name
  }

  function onStateChange() {
    const previousScreen = routeNameRef.current
    const currentScreen = navigationRef.getCurrentRoute()?.name
    if (currentScreen && previousScreen !== currentScreen) {
      routeNameRef.current = currentScreen
      analytics.log('Screen view', { currentScreen, previousScreen })
    }
  }

  return (
    <NavigationContainer
      linking={linking}
      theme={theme}
      documentTitle={documentTitle}
      onReady={onReady}
      onStateChange={onStateChange}
      ref={navigationRef}>
      {props.children}
    </NavigationContainer>
  )
}
