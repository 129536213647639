import React, { useContext, useEffect } from 'react'

import { config } from 'config'
import { useUserContext } from 'user/context'

import { ConsentsContext } from './provider'
import { ConsentsCookies } from './types'

function isAxeptioInitialized() {
  return window && window.__axeptioSDK && Boolean(window.__axeptioSDK)
}

export const ConsentsWidget: React.FC = () => {
  const { contract } = useUserContext()
  const { showWidget, setCookies, setShowWidget } = useContext(ConsentsContext)

  useEffect(() => {
    if (window.isReactNativeWebView) {
      return
    }
    function initializeAxeptio() {
      window.axeptioSettings = {
        clientId: config.AXEPTIO_CLIENT_ID,
        cookiesVersion: config.AXEPTIO_COOKIES_VERSION,
      }
      ;(function (d, s) {
        const t = d.getElementsByTagName(s)[0],
          e = d.createElement(s) as any
        e.async = true
        e.src = '//static.axept.io/sdk-slim.js'
        t.parentNode?.insertBefore(e, t)
      })(document, 'script')
    }
    function initializeCookiesConsentsListener() {
      void 0 === window._axcb && (window._axcb = [])
      window._axcb.push(function (axeptio: any) {
        axeptio.on('ready', (cookies: ConsentsCookies) => {
          setCookies(cookies)
        })
        axeptio.on('cookies:complete', (cookies: ConsentsCookies) => {
          setCookies(cookies)
        })
        axeptio.on('close', () => {
          setShowWidget(false)
        })
      })
    }
    initializeAxeptio()
    initializeCookiesConsentsListener()
  }, [setCookies, setShowWidget])

  useEffect(() => {
    if (window.isReactNativeWebView) {
      return
    }
    if (contract && isAxeptioInitialized()) {
      window.__axeptioSDK.setToken(contract.userId)
    }
    if (showWidget && isAxeptioInitialized()) {
      window.__axeptioSDK.openCookies()
    }
  }, [showWidget, contract])

  return <></>
}
