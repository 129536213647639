import { useToast, VStack, HStack, Text, IconButton, CloseIcon, Alert } from 'native-base'
import React, { ComponentProps } from 'react'

import { Color } from 'theme'

type AlertProps = ComponentProps<typeof Alert>

type ToastParams = Omit<Parameters<ReturnType<typeof useToast>['show']>[0], 'render'>

type ToastAlertProps = {
  onClose: () => void
  title: string
  description: string
  status?: AlertProps['status']
  isClosable?: boolean
}

type ShowToastParams = ToastParams & Omit<ToastAlertProps, 'onClose'>

export function useAlertToast() {
  const toast = useToast()
  return {
    showToast: ({
      title,
      description,
      status = 'info',
      isClosable = true,
      ...toastParams
    }: ShowToastParams) => {
      toastParams.duration = toastParams.duration ?? 8000
      toastParams.placement = toastParams.placement || 'top'
      toast.show({
        ...toastParams,
        render: ({ id }) => {
          return (
            <ToastAlert
              title={title}
              description={description}
              isClosable={isClosable}
              status={status}
              onClose={() => toast.close(id)}
            />
          )
        },
      })
    },
  }
}

const ToastAlert: React.FC<ToastAlertProps> = ({
  onClose,
  title,
  description,
  isClosable = true,
  status = 'info',
}) => {
  const backgroundColor =
    status === 'error' ? Color.lightRed : status === 'warning' ? Color.lightYellow : Color.blueNight

  return (
    <Alert
      maxWidth="90%"
      alignSelf="center"
      flexDirection="row"
      status={status}
      backgroundColor={backgroundColor}
      borderRadius={16}
      padding={4}>
      <VStack space={1} flexShrink={1} w="100%" justifyContent="center" alignItems="center">
        {isClosable && (
          <IconButton
            position="absolute"
            top={1}
            right={1}
            variant="unstyled"
            icon={<CloseIcon size="3" />}
            _icon={{ color: Color.blueNight }}
            onPress={onClose}
            padding={0}
          />
        )}
        {title && (
          <Text fontSize="md" fontWeight="bold" color={Color.blueNight} textAlign="center">
            {title}
          </Text>
        )}
        <HStack space={1} flexShrink={1} w="100%" justifyContent="center" alignItems="center">
          <Text px={6} color={Color.blueNight} fontSize="sm" textAlign="center">
            {description}
          </Text>
        </HStack>
      </VStack>
    </Alert>
  )
}
