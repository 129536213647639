import React from 'react'
import { useTranslation } from 'react-i18next'

export const UnsupportedScreen: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div style={{ padding: '10px' }}>
      <p>{t('unsupported.web.firstHint')}</p>
      <p>{t('unsupported.web.secondHint')}</p>
    </div>
  )
}
