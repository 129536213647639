import { Center, Spinner } from 'native-base'
import React, { ComponentProps } from 'react'

type Props = {
  size?: ComponentProps<typeof Spinner>['size']
}

export const Loading: React.FC<Props> = (props) => {
  const { size } = props
  return (
    <Center width="100%">
      <Spinner size={size} color="blueNight" accessibilityLabel="Loading" />
    </Center>
  )
}
