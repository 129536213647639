import { differenceInDays } from 'date-fns'

export function hasEnoughTimePassedBetweenDates(
  laterDate: Date | null,
  earlierDate: Date | null,
  timeInDays = 90
): boolean {
  if (!laterDate) return false
  if (!earlierDate) return true
  return differenceInDays(laterDate, earlierDate) > timeInDays
}
