import * as Clipboard from 'expo-clipboard'
import { HStack, Input, Box, Text } from 'native-base'
import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextInput } from 'react-native'

import { Color } from 'theme'

const OTC_REGEXP = /^\d{6}$/

type Props = {
  onChange: (res: string) => void
  height: number
  width: number
}

export const OtcInput: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { onChange, height, width } = props

  const inputsRef = useRef<Array<TextInput>>([])
  const [inputValues, setInputValues] = useState<string[]>(Array(6).fill(''))

  const [isPasteEnabled, setIsPasteEnabled] = useState(false)

  useEffect(() => {
    inputsRef.current[0]?.focus()
    isClipboardValid()
  }, [])

  const sendResult = (updatedValues: string[]) => {
    const res = updatedValues.join('')
    onChange(res)
  }

  const handleOnChangeText = (text: string, index: number) => {
    const updatedValues = [...inputValues]
    updatedValues[index] = text.charAt(0)
    setInputValues(updatedValues)

    const nextElementSibling = inputsRef.current[index + 1]
    if (nextElementSibling && text.length > 0) {
      nextElementSibling.focus()
    }

    sendResult(updatedValues)
  }

  const handleOnKeyPress = (e: any, index: number) => {
    const { key } = e.nativeEvent
    if (key === 'Backspace') {
      if (inputValues[index] === '') {
        // When the current input is already empty and backspace is pressed
        const prevIndex = index - 1
        if (prevIndex >= 0) {
          inputsRef.current[prevIndex].focus() // Move focus to the previous input
          const updatedValues = [...inputValues]
          updatedValues[prevIndex] = '' // Clear the previous input
          setInputValues(updatedValues)
          sendResult(updatedValues)
        }
      } else {
        // Normal backspace operation: clear the current input
        const updatedValues = [...inputValues]
        updatedValues[index] = ''
        setInputValues(updatedValues)
        sendResult(updatedValues)
      }
    }
  }

  const handlePasteOTP = async () => {
    const clipboardContent = await Clipboard.getStringAsync()
    if (clipboardContent.length === 6 && OTC_REGEXP.test(clipboardContent)) {
      const updatedValues = clipboardContent.split('')
      setInputValues(updatedValues)
      sendResult(updatedValues)
      inputsRef.current[5].focus() // Focus last input if full OTP is pasted
    }
  }

  const isClipboardValid = async () => {
    if (!Clipboard) return
    const clipboardContent = await Clipboard.getStringAsync()
    setIsPasteEnabled(clipboardContent.length === 6 && OTC_REGEXP.test(clipboardContent))
  }

  return (
    <>
      <HStack space={2}>
        {inputValues.map((_, i) => (
          <Box key={i}>
            <Input
              contextMenuHidden
              value={inputValues[i]}
              onKeyPress={(e) => handleOnKeyPress(e, i)}
              onChangeText={(text) => handleOnChangeText(text, i)}
              _focus={{
                borderColor: Color.blueNight,
              }}
              w={width}
              h={height}
              size="lg"
              maxLength={1}
              variant="outline"
              textAlign="center"
              keyboardType="numeric"
              onFocus={isClipboardValid}
              ref={(el) => (inputsRef.current[i] = el)}
            />
          </Box>
        ))}
      </HStack>
      {isPasteEnabled && (
        <Text mt={2} fontSize={'xs'} onPress={handlePasteOTP}>
          {t('signIn.otc.otcPaste')}
        </Text>
      )}
    </>
  )
}
