import Constants, { ExecutionEnvironment } from 'expo-constants'
import { Platform } from 'react-native'

export const IS_IOS = Platform.OS === 'ios'

export const IS_ANDROID = Platform.OS === 'android'

export const IS_NATIVE = IS_IOS || IS_ANDROID

export const IS_WEB = Platform.OS === 'web'

export const IS_EXPO_GO = Constants.executionEnvironment === ExecutionEnvironment.StoreClient

export const isWebView = () => Platform.OS === 'web' && window.ReactNativeWebView
