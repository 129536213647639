import AsyncStorage from '@react-native-async-storage/async-storage'

type StorageKey =
  | '@FronteggUser'
  | '@Contract'
  | '@LastReviewRequestDate'
  | '@UserHasLoggedInAtLeastOnce'
  | '@UserHasSeenProductTour'
  | '@UserEmail'

export const storage = {
  async getString(key: StorageKey): Promise<string | null> {
    try {
      return await AsyncStorage.getItem(key)
    } catch (error) {
      console.log(error)
      return null
    }
  },
  async getObject<T>(key: StorageKey): Promise<T | null> {
    try {
      const item = await AsyncStorage.getItem(key)
      return item ? JSON.parse(item) : null
    } catch (error) {
      console.log(error)
      return null
    }
  },
  async getDate(key: StorageKey): Promise<Date | null> {
    try {
      const dateStr = await AsyncStorage.getItem(key)
      return dateStr ? new Date(dateStr) : null
    } catch (error) {
      console.log(error)
      return null
    }
  },
  async save(key: StorageKey, value?: string | object | boolean | number | null) {
    try {
      if (value == undefined || value == null) {
        return await AsyncStorage.removeItem(key)
      }
      if (typeof value === 'string') {
        return await AsyncStorage.setItem(key, value)
      }
      return await AsyncStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.log(error)
    }
  },
  async saveDate(key: StorageKey, value?: Date) {
    try {
      if (value == undefined || value == null) {
        return await AsyncStorage.removeItem(key)
      }
      return await AsyncStorage.setItem(key, value.toISOString())
    } catch (error) {
      console.log(error)
    }
  },
  async remove(key: StorageKey) {
    try {
      return await AsyncStorage.removeItem(key)
    } catch (error) {
      console.log(error)
    }
  },
}
