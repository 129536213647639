import { useEffect, useState } from 'react'

import { storage } from 'services/storage'

export function useGetEmail() {
  const [userEmail, _setUserEmail] = useState<string>('')

  useEffect(() => {
    storage.getString('@UserEmail').then((value) => _setUserEmail(value ? value : ''))
  }, [])

  async function setUserEmail(email: string) {
    await storage.save('@UserEmail', email)
  }

  return { userEmail, setUserEmail }
}
