import { nativeApplicationVersion } from 'expo-application'
import { openBrowserAsync } from 'expo-web-browser'
import { Box, Column, ScrollView, Text } from 'native-base'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenHeader } from 'components/ScreenHeader'
import { TabBarSpacer } from 'components/TabBar'
import { config } from 'config'
import { analytics } from 'services/analytics'
import { AnalyticsKey } from 'services/analytics/types'
import { IntercomContext } from 'services/chat/provider'
import { AccountScreenProps } from 'types/navigation'
import { ButtonSolid } from 'ui/Buttons'
import { useScrollViewStyles } from 'utils/scroll'

import { BlogCard } from './BlogCard'
import { Card } from './Card'
import { ProfileCard } from './ProfileCard'
import { ToutesMesAidesCard } from './ToutesMesAidesCard'

type Props = AccountScreenProps<'Account'>

export const AccountScreen: React.FC<Props> = () => {
  const { t } = useTranslation()
  const { openChat, setChatLoading } = useContext(IntercomContext)
  const scrollViewStyles = useScrollViewStyles()

  function handleOpenChat() {
    analytics.log(AnalyticsKey.OPEN_CHAT)
    setChatLoading(true)
    openChat()
  }

  async function goToHelpCenter() {
    analytics.log(AnalyticsKey.OPEN_HELP_CENTER)
    await openBrowserAsync(config.EMPLOYEE_HELP_CENTER_URL)
  }

  return (
    <ScrollView {...scrollViewStyles}>
      <ScreenHeader title={t('account.title')} />

      <Column width="100%" alignItems="center" paddingX={5} maxW={400}>
        <ProfileCard />

        <Box h={5} />

        <Card height={400}>
          <Text alignSelf="flex-start" fontSize="lg" fontWeight="medium" color="grey">
            {t('account.appsToDiscover')}
          </Text>
          <Box h={3} />
          <ToutesMesAidesCard />
          <Box h={3} />
          <BlogCard />
        </Card>

        <Box h={5} />

        <Card height={200}>
          <Text alignSelf="flex-start" fontSize="lg" fontWeight="medium" color="grey">
            {t('account.needHelp')}
          </Text>
          <Box h={3} />
          <ButtonSolid
            title={t('account.consultHelpCenter')}
            onPress={goToHelpCenter}
            colorScheme="primary"
            rounded="lg"
            width="100%"
          />
          <Box h={2} />
          <ButtonSolid
            title="Chat"
            onPress={handleOpenChat}
            colorScheme="primary"
            rounded="lg"
            width="100%"
          />
        </Card>

        <Box h={17} />

        <Text alignSelf="flex-end" fontSize="md" fontWeight="light">
          {t('version', { version: nativeApplicationVersion })}
        </Text>
      </Column>

      <TabBarSpacer />
    </ScrollView>
  )
}
