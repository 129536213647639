export enum AnalyticsKey {
  CANCEL_TRANSACTION = 'Cancel transaction',
  CLOSE_BALANCE_MODAL = 'Close BalanceCalculation modal',
  CLOSE_REQUEST_MODAL = 'Close EarnedWagesRequest modal',
  CLOSE_TRANSACTION_MODAL = 'Close CancelTransaction modal',
  FORM_DATA_FAILED = 'Send form data to Google Sheets failed',
  FORM_DATA_SENT = 'Form data sent to Google Sheets',
  LOGOUT = 'Logout',
  OPEN_BALANCE_MODAL = 'Open BalanceCalculation modal',
  OPEN_BLOG = 'Open blog',
  OPEN_CHAT = 'Open chat',
  OPEN_CLARO_MODAL = 'Open ToutesMesAides modal',
  OPEN_CONSENTS_WIDGET = 'Open consents widget',
  OPEN_HELP_CENTER = 'Go to help center',
  OPEN_PRIVACY_POLICY = 'Go to privacy policy',
  OPEN_REQUEST_MODAL = 'Open EarnedWagesRequest modal',
  OPEN_TRANSACTION_MODAL = 'Open CancelTransaction modal',
  REQUEST_FAILED = 'Wage request failed',
  REQUEST_SENT = 'Wage request sent',
  TOGGLE_TRANSACTION_DETAIL = 'Toggle transaction details display',
  UPDATE_SLIDER_AMOUNT = 'Utilisation du slider',
  CLICK_ON_NOT_INVITED_BUTTON = 'Click on Not Invited button',
  CLICK_ON_MAIL_FORGOTTEN_BUTTON = 'Click on Mail Forgotten button',
  LOGIN = 'Login',
}

export interface Analytics {
  enable: () => void
  disable: () => void
  identify: (userId: string) => void
  /** Set properties sent with all events.
   * Be careful : every call will override previous values. */
  setEventPersistedProperties: (properties?: Record<string, any>) => void
  /* Be careful : every call will override previous values. */
  setProfilePersistedProperties: (properties: Partial<AnalyticsProfileProperties>) => void
  log: (name: string, params?: { [key: string]: any }) => void
}

export type FrontWebviewAnalyticsEvent = {
  type: 'ANALYTICS_EVENT'
  name: AnalyticsKey
  properties?: Record<string, any>
}

export type AnalyticsProfileProperties = {
  $created: string
  $distinct_id: string
  $email: string
  $name: string
  organizationName: string
  netSalary: number
  hasLoggedIn: string
}
