import { Text, Box, ScrollView } from 'native-base'
import React from 'react'
import { useTranslation } from 'react-i18next'

import NesspaySvgIcon from 'components/NesspaySvgIconBig'
import { config } from 'config'
import { Color } from 'theme'
import { RootScreenProps } from 'types/navigation'
import { ButtonSolid } from 'ui/Buttons'

type Props = RootScreenProps<'SignUp'>

export const BecomeAmbassadorScreen: React.FC<Props> = () => {
  const { t } = useTranslation()

  async function goToTypeformPage() {
    window.location.replace(config.SIGNUP_TYPEFORM_URL)
  }

  return (
    <ScrollView
      style={{ flex: 1, marginLeft: 'auto', marginRight: 'auto' }}
      width="100%"
      minW="300px"
      maxW="800px">
      <Box alignItems="center" marginTop={10}>
        <NesspaySvgIcon />
        <Text fontSize="2xl" fontWeight="medium" textAlign="center">
          {t('signUp.title')}
        </Text>
      </Box>

      <Box paddingLeft={35} paddingRight={35}>
        <Box h={110} />
        <Text fontSize="2xl" fontWeight="semibold" textAlign="center">
          {t('becomeAmbassador.mainText')}
        </Text>
        <Box h={50} />
        <Text fontSize="xl" fontWeight="normal" textAlign="center">
          {t('becomeAmbassador.secondaryText')}
        </Text>
      </Box>

      <Box h={50} />

      <Box alignItems="center">
        <ButtonSolid
          onPress={goToTypeformPage}
          title={t('becomeAmbassador.buttonText')}
          style={{ backgroundColor: Color.yellow, borderColor: 'white' }}
          rounded="lg"
          width="90%">
          {t('becomeAmbassador.buttonText')}
        </ButtonSolid>
      </Box>
    </ScrollView>
  )
}
