import { StatusBar } from 'expo-status-bar'
import React from 'react'

export const DefaultStatusBar: React.FC = () => {
  return (
    <StatusBar
      style="dark"
      translucent={true}
      hidden={false}
      networkActivityIndicatorVisible={true}
      hideTransitionAnimation="fade"
    />
  )
}
