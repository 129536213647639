import {
  Outfit_100Thin,
  Outfit_200ExtraLight,
  Outfit_300Light,
  Outfit_400Regular,
  Outfit_500Medium,
  Outfit_600SemiBold,
  Outfit_700Bold,
  Outfit_800ExtraBold,
  Outfit_900Black,
} from '@expo-google-fonts/outfit'
import { osName, manufacturer } from 'expo-device'
import { useFonts } from 'expo-font'
import { NativeBaseProvider } from 'native-base'
import React, { useEffect } from 'react'
import { CopilotProvider } from 'react-native-copilot'
import { SafeAreaProvider } from 'react-native-safe-area-context'

import { AuthProvider, useAuthContext } from 'auth/authProvider'
import { DebugErrorBoundary } from 'components/DebugErrorBoundary'
import { GlobalErrorBoundary } from 'components/GlobalErrorBoundary'
import { useGetMaintenance } from 'hooks/useGetMaintenance'
import { AuthedStack } from 'navigation/AuthedStack'
import { NavigationProvider } from 'navigation/NavigationProvider'
import { PublicStack } from 'navigation/PublicStack'
import { MaintenanceScreen } from 'screens/MaintenanceScreen'
import { UnsupportedScreen } from 'screens/UnsupportedScreen'
import { IntercomChat } from 'services/chat/IntercomChat'
import { IntercomProvider } from 'services/chat/provider'
import { ConsentsWidget } from 'services/consent/ConsentsWidget'
import { ConsentsProvider } from 'services/consent/provider'
import { initSentry } from 'services/monitoring/init'
import { isWebView } from 'services/platform'
import { ReactQueryClientProvider } from 'services/queryClient'
import { useSplashScreen } from 'services/splashscreen'
import { theme } from 'theme'
import { CopilotStepNumber, CopilotTooltip } from 'ui/ProductTour'
import { useUserContext } from 'user/context'
import { UserProvider } from 'user/UserProvider'
import { ignoreLogs } from 'utils/ignoreLogs'

import './i18n/i18n.config'
import 'react-native-gesture-handler'

initSentry()

const errorMessagesToIgnore = [
  // Despite the error message about the font "Outfit", everything related to fonts is working as intended.
  'fontFamily "Outfit" is not a system font and has not been loaded',
]
ignoreLogs('error', errorMessagesToIgnore)

export default function App() {
  return (
    <NativeBaseProvider theme={theme}>
      <GlobalErrorBoundary>
        <SafeAreaProvider>
          <CopilotProvider
            tooltipComponent={CopilotTooltip}
            stepNumberComponent={CopilotStepNumber}>
            <NavigationProvider>
              <ReactQueryClientProvider>
                <AuthProvider>
                  <ConsentsProvider>
                    <ConsentsWidget />
                    <UserProvider>
                      <IntercomProvider>
                        <IntercomChat />
                        <DebugErrorBoundary>
                          <ReadyApp />
                        </DebugErrorBoundary>
                      </IntercomProvider>
                    </UserProvider>
                  </ConsentsProvider>
                </AuthProvider>
              </ReactQueryClientProvider>
            </NavigationProvider>
          </CopilotProvider>
        </SafeAreaProvider>
      </GlobalErrorBoundary>
    </NativeBaseProvider>
  )
}

function ReadyApp() {
  const { hideSplashScreen } = useSplashScreen()
  const { accessToken } = useAuthContext()
  const { maintenance } = useGetMaintenance()
  const { loading: isLoadingUserContext, contract } = useUserContext()
  const [areFontsLoaded] = useFonts({
    Outfit_100Thin,
    Outfit_200ExtraLight,
    Outfit_300Light,
    Outfit_400Regular,
    Outfit_500Medium,
    Outfit_600SemiBold,
    Outfit_700Bold,
    Outfit_800ExtraBold,
    Outfit_900Black,
  })

  const isReady = areFontsLoaded && !isLoadingUserContext

  const isWebAppUnsupported =
    (osName?.toLowerCase().includes('android') && manufacturer?.toLowerCase().includes('oppo')) ||
    (osName?.toLowerCase().includes('android') && manufacturer?.toLowerCase().includes('huawei'))

  useEffect(() => {
    if (isReady) hideSplashScreen()
  }, [isReady, hideSplashScreen])

  if (!isReady) {
    return null
  }
  if (Boolean(isWebAppUnsupported) && !isWebView()) {
    return <UnsupportedScreen />
  }
  if (maintenance) {
    return <MaintenanceScreen />
  }
  if (accessToken && contract) {
    return <AuthedStack />
  }
  return <PublicStack />
}
