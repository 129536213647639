import AsyncStorage from '@react-native-async-storage/async-storage'
import { useState } from 'react'
import { useQuery } from 'react-query'

import { useApi } from 'api'
import { QueryKey } from 'services/queryClient'

export const useAuth = () => {
  const api = useApi()
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined)

  const { isFetching, isLoading } = useQuery(
    QueryKey.RefreshToken,
    async () => {
      const currentToken = await AsyncStorage.getItem('@access_token')
      if (!currentToken) return
      const { token: refreshToken } = await api.authControllerRefreshToken({ token: currentToken })
      setAccessToken(refreshToken)
    },
    {
      onError: async (error) => {
        await AsyncStorage.removeItem('@access_token')
        setAccessToken(undefined)
        console.error(error)
      },
    }
  )

  return { accessToken, isLoading: isFetching && isLoading }
}
