import { Dimensions } from 'react-native'

const { width, height, fontScale: rnFontScale } = Dimensions.get('window')
const [shortDimension, longDimension] = width < height ? [width, height] : [height, width]

//Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 350
const guidelineBaseHeight = 680

const widthRatio = shortDimension / guidelineBaseWidth
const heightRatio = longDimension / guidelineBaseHeight

export const scale = (value: number, ratio: number, factor: number, maxScale: number) => {
  const scaledValue = ratio * value
  const factorScaledValue = value + (scaledValue - value) * factor
  const maxValue = maxScale * value
  return Math.min(factorScaledValue, maxValue)
}

export const horizontalScale = (value: number, factor = 0.4, maxScale = 1.3) => {
  return scale(value, widthRatio, factor, maxScale)
}

export const verticalScale = (value: number, factor = 0.4, maxScale = 1.3) => {
  return scale(value, heightRatio, factor, maxScale)
}

export const fontScale = (value: number, factor = 0.4, maxScale = 1.3) => {
  return horizontalScale(value, factor, maxScale) / rnFontScale
}
