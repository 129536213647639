import React from 'react'
import Svg, { Circle } from 'react-native-svg'

import { Color } from 'theme'

interface Props {
  colored: boolean
}

const LogoSvgSmall = ({ colored }: Props) => (
  <Svg viewBox="0 0 185 185" fill="none">
    <Circle cx="92.5" cy="92.5" r="92.5" fill={colored ? Color.blueNight : Color.grey} />
    <Circle cx="92.5" cy="92.5" r="65.7778" fill="white" />
    <Circle cx="92.5" cy="92.5" r="37" fill={colored ? Color.yellow : Color.grey} />
  </Svg>
)
export default LogoSvgSmall
