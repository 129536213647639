import { createStackNavigator } from '@react-navigation/stack'
import { Center } from 'native-base'
import React from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { WhiteBackground } from 'components/Background'
import { Loading } from 'components/Loading'
import { DefaultStatusBar } from 'components/StatusBar'
import { useHasLoggedInAtLeastOnce } from 'hooks/useHasLoggedIn'
import { BecomeAmbassadorScreen } from 'screens/BecomeAmbassadorScreen'
import { EmailForgottenScreen } from 'screens/EmailForgottenScreen'
import { SignInScreen } from 'screens/SignInScreen'
import { SignUpScreen } from 'screens/SignUpScreen'
import { WelcomeTutorialScreen } from 'screens/WelcomeTutorialScreen'
import { RootStackParamList } from 'types/navigation'

const Stack = createStackNavigator<RootStackParamList>()

export const PublicStack: React.FC = () => {
  const safeAreaInsets = useSafeAreaInsets()
  const { hasLoggedInAtLeastOnceLoading } = useHasLoggedInAtLeastOnce()

  if (hasLoggedInAtLeastOnceLoading) {
    return (
      <Center height="100%" width="100%">
        <Loading size={80} />
      </Center>
    )
  }

  return (
    <>
      <DefaultStatusBar />
      <WhiteBackground />
      <Stack.Navigator
        screenOptions={{ headerShown: false, cardStyle: { paddingTop: safeAreaInsets.top } }}>
        <Stack.Screen name="WelcomeTutorial" component={WelcomeTutorialScreen} />
        <Stack.Screen name="SignIn" component={SignInScreen} />
        <Stack.Screen name="EmailForgotten" component={EmailForgottenScreen} />
        <Stack.Screen name="SignUp" component={SignUpScreen} />
        <Stack.Screen name="BecomeAmbassador" component={BecomeAmbassadorScreen} />
      </Stack.Navigator>
    </>
  )
}
