import { extendTheme } from 'native-base'

import { fontScale } from 'utils/scale'

export enum Color {
  blueNight = '#06113F',
  darkGrey = '#5B5B5B',
  darkYellow = '#EBB634',
  grey = '#A9AFC5',
  infoBlue = '#334AC0',
  lightGreen = '#D6EEC3',
  lightGrey = '#F3F3F3',
  lightRed = '#FFDBDB',
  lightYellow = '#FFEDC1',
  purple = '#E5E8F9',
  toutesMesAides = '#178af0',
  validGreen = '#11C821',
  warningRed = '#EC0000',
  white = '#FFFFFF',
  yellow = '#FAC238',
}

type Config = Parameters<typeof extendTheme>[0]

const config: Config = {
  useSystemColorMode: false,
  initialColorMode: 'dark',
  components: {
    Input: {
      baseStyle: {
        borderRadius: '12px',
        height: '48px',
        color: 'gray.700',
        backgroundColor: Color.white,
      },
    },
    Button: {
      defaultProps: { colorScheme: 'primary', variant: 'solid' },
      baseStyle: {
        minHeight: '48px',
      },
    },
    Text: {
      defaultProps: { fontSize: 'lg', fontFamily: 'body', color: 'blueNight' },
    },
  },
  fontConfig: {
    Outfit: {
      100: { normal: 'Outfit_100Thin' },
      200: { normal: 'Outfit_200ExtraLight' },
      300: { normal: 'Outfit_300Light' },
      400: { normal: 'Outfit_400Regular' },
      500: { normal: 'Outfit_500Medium' },
      600: { normal: 'Outfit_600SemiBold' },
      700: { normal: 'Outfit_700Bold' },
      800: { normal: 'Outfit_800ExtraBold' },
      900: { normal: 'Outfit_900Black' },
    },
  },
  fonts: { heading: 'Outfit', body: 'Outfit', mono: 'Outfit' },
  fontSizes: {
    '2xs': fontScale(10),
    xs: fontScale(12),
    sm: fontScale(14),
    md: fontScale(16),
    lg: fontScale(18),
    xl: fontScale(20),
    '2xl': fontScale(24),
    '3xl': fontScale(30),
    '4xl': fontScale(36),
    '5xl': fontScale(48),
    '6xl': fontScale(60),
    '7xl': fontScale(72),
    '8xl': fontScale(84),
    '9xl': fontScale(96),
  },
  radii: {
    none: 0,
    xs: 1,
    sm: 2,
    md: 5,
    lg: 10,
    xl: 20,
    '2xl': 30,
    '3xl': 40,
    full: 50,
  },
  colors: {
    blueNight: Color.blueNight,
    purple: Color.purple,
    darkGrey: Color.darkGrey,
    grey: Color.grey,
    lightGreen: Color.lightGreen,
    lightYellow: Color.lightYellow,
    lightRed: Color.lightRed,
    lightGrey: Color.lightGrey,
    yellow: Color.yellow,
    toutesMesAides: Color.toutesMesAides,
    warningRed: Color.warningRed,
    infoBlue: Color.infoBlue,
    validGreen: Color.validGreen,
  },
}

export const theme = extendTheme(config)

type MyThemeType = typeof theme

declare module 'native-base' {
  interface ICustomTheme extends MyThemeType {}
}
