import { mask, unMask } from 'react-native-mask-text'

const IBAN_MASKS = {
  fr: 'AASS SSSS SSSS SSSS SSSS SSSS SSS',
}
type IbanType = keyof typeof IBAN_MASKS

export function maskIBAN(value: string | undefined, type: IbanType = 'fr'): string {
  if (!value) return ''
  return mask(value, IBAN_MASKS[type])
}
export function unmask(value: string): string {
  return unMask(value)
}
