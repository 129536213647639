import { createContext, useContext } from 'react'

import { Contract } from 'api/gen'

type UserContextType = {
  contract?: Contract
  loading: boolean
  logout: () => Promise<void>
}

const INITIAL_USER_CONTEXT: UserContextType = {
  contract: undefined,
  loading: true,
  logout: async () => {
    // do nothing
  },
}

export const UserContext = createContext<UserContextType>(INITIAL_USER_CONTEXT)

export const useUserContext = (): UserContextType => {
  return useContext(UserContext)
}
