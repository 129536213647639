import { Text, Box, Checkbox, ScrollView, ICheckboxProps } from 'native-base'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleProp, TextInput, TextStyle, TouchableOpacity } from 'react-native'
import { useMutation } from 'react-query'

import { useApi } from 'api'
import NesspaySvgIcon from 'components/NesspaySvgIconBig'
import { analytics } from 'services/analytics'
import { AnalyticsKey } from 'services/analytics/types'
import { isWebView } from 'services/platform'
import { Color } from 'theme'
import { RootScreenProps } from 'types/navigation'
import { ButtonSolid } from 'ui/Buttons'

type Props = RootScreenProps<'SignUp'>

export const SignUpScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation()
  const api = useApi()

  let userHasAlreadyBeenInvited = false

  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [company, setCompany] = useState<string>('')
  const [hasAcceptedTOS, setHasAcceptedTOS] = useState<boolean>(false)

  const sendFormDataToSheetMutation = useMutation(
    async () => {
      const { hasBeenInvited } = await api.userControllerAddNonInvitedUserToSheet({
        firstName,
        lastName,
        company,
        email,
      })
      userHasAlreadyBeenInvited = hasBeenInvited
    },
    {
      onSuccess: () => {
        analytics.log(AnalyticsKey.FORM_DATA_SENT)
      },
      onError: (error) => {
        console.error(error)
        analytics.log(AnalyticsKey.FORM_DATA_FAILED)
      },
    }
  )

  async function subscribeToNesspay() {
    await sendFormDataToSheetMutation.mutateAsync()
    if (!userHasAlreadyBeenInvited) navigation.navigate('BecomeAmbassador')
    else goToSignInScreen(email)
  }

  async function goToSignInScreen(email?: string) {
    if (isWebView()) window.ReactNativeWebView.postMessage('SignIn')
    navigation.navigate('SignIn', { login_hint: email ? email : '' })
  }

  const isValid =
    Boolean(firstName) && Boolean(lastName) && Boolean(email) && Boolean(company) && hasAcceptedTOS

  return (
    <ScrollView
      style={{ flex: 1, marginLeft: 'auto', marginRight: 'auto' }}
      width="100%"
      minW="300px"
      maxW="800px">
      <Box alignItems="center" marginTop={10}>
        <NesspaySvgIcon />

        {!isWebView() && <Box h={5} />}

        <Text fontSize="2xl" fontWeight="medium" textAlign="center">
          {t('signUp.title')}
        </Text>
      </Box>

      {!isWebView() && <Box h={5} />}

      <Box marginLeft={5} marginRight={5} marginTop={3}>
        <Text fontSize="sm">{t('signUp.firstName')}</Text>
        <TextInput
          value={firstName}
          placeholder="Gary"
          style={TextInputStyle}
          placeholderTextColor={Color.grey}
          onChangeText={(firstName) => setFirstName(firstName)}
        />
        <Text fontSize="sm">{t('signUp.lastName')}</Text>
        <TextInput
          value={lastName}
          placeholder="Abouaf"
          style={TextInputStyle}
          placeholderTextColor={Color.grey}
          onChangeText={(lastName) => setLastName(lastName)}
        />
        <Text fontSize="sm">{t('signUp.email')}</Text>
        <TextInput
          value={email}
          style={TextInputStyle}
          placeholder="gary@nesspay.co"
          placeholderTextColor={Color.grey}
          onChangeText={(email) => setEmail(email)}
        />
        <Text fontSize="sm">{t('signUp.company')}</Text>
        <TextInput
          value={company}
          placeholder="NessPay"
          style={TextInputStyle}
          placeholderTextColor={Color.grey}
          onChangeText={(company) => setCompany(company)}
        />
        <Checkbox
          value=""
          onChange={(TOS) => setHasAcceptedTOS(TOS)}
          _checked={CheckboxCheckedStyle}>
          <Text fontSize="sm" padding={2}>
            {t('signUp.termsOfService')}
          </Text>
        </Checkbox>
      </Box>

      <Box h={3} />

      {!isWebView() && <Box h={10} />}

      <Box alignItems="center">
        <ButtonSolid
          width="90%"
          rounded={15}
          disabled={!isValid}
          onPress={subscribeToNesspay}
          _text={{ color: Color.lightGrey }}
          title={t('signUp.buttons.signUp')}
          colorScheme={isValid ? 'primary' : 'muted'}
          isLoading={sendFormDataToSheetMutation.isLoading}>
          {t('signUp.buttons.signUp')}
        </ButtonSolid>

        <Box h={5} />

        <TouchableOpacity onPress={() => goToSignInScreen()}>
          <Text fontSize="md">{t('signUp.buttons.alreadyInvited')}</Text>
        </TouchableOpacity>
      </Box>
    </ScrollView>
  )
}

const CheckboxCheckedStyle: Omit<Partial<ICheckboxProps>, '_checked'> = {
  backgroundColor: Color.yellow,
  borderColor: Color.blueNight,
  _icon: { color: Color.blueNight },
}

const TextInputStyle: StyleProp<TextStyle> = {
  height: 45,
  paddingLeft: 15,
  borderWidth: 1,
  marginTop: 5,
  marginBottom: 5,
  borderRadius: 12,
  color: Color.blueNight,
  borderColor: Color.purple,
}
