import { LinkingOptions, PathConfig } from '@react-navigation/native'

import { config } from 'config'
import { AllStackParamList } from 'types/navigation'

type AllStackLinkingOptions = LinkingOptions<AllStackParamList>

const linkingConfig: AllStackLinkingOptions['config'] = {
  screens: {
    TabNav: {
      path: '/',
      screens: {
        Transactions: { path: 'transactions' },
        Home: { path: 'home' },
        AccountNav: {
          path: 'account',
          screens: {
            Account: { path: '/' },
            Profile: { path: 'profile' },
            BlogArticles: { path: 'fiches' },
          },
        },
      },
    },
    SignIn: { path: 'account/login' },
    ResetPassword: { path: 'reset-password' },
    WelcomeTutorial: { path: 'introduction' },
    Unauthorized: { path: 'unauthorized' },
    SignOut: { path: 'account/logout' },
    SignUp: { path: 'sign-up' },
    EmailForgotten: { path: 'email-forgotten' },
    BecomeAmbassador: { path: 'become-ambassador' },
    InactiveAccount: { path: 'inactive-account' },
    ContractEnded: { path: 'contract-ended' },
  },
}

export const linking: AllStackLinkingOptions = {
  prefixes: [],
  config: linkingConfig,
}

const BASE_URL = config.EMPLOYEE_APP_URL
const { screens } = linkingConfig
export const ABSOLUTE_URL: Record<keyof (typeof linkingConfig)['screens'], string> = {
  TabNav: BASE_URL + '/',
  SignIn: BASE_URL + '/' + (screens.SignIn as PathConfig<any>).path,
  WelcomeTutorial: BASE_URL + '/' + (screens.WelcomeTutorial as PathConfig<any>).path,
  SignUp: BASE_URL + '/' + (screens.SignUp as PathConfig<any>).path,
  SignOut: BASE_URL + '/' + (screens.SignOut as PathConfig<any>).path,
  EmailForgotten: BASE_URL + '/' + (screens.EmailForgotten as PathConfig<any>).path,
  ResetPassword: BASE_URL + '/' + (screens.ResetPassword as PathConfig<any>).path,
  BecomeAmbassador: BASE_URL + '/' + (screens.BecomeAmbassador as PathConfig<any>).path,
  Unauthorized: BASE_URL + '/' + (screens.Unauthorized as PathConfig<any>).path,
  InactiveAccount: BASE_URL + '/' + (screens.InactiveAccount as PathConfig<any>).path,
  ContractEnded: BASE_URL + '/' + (screens.ContractEnded as PathConfig<any>).path,
}
