import * as Sentry from 'sentry-expo'

import { config } from 'config'

export function initSentry() {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    enableInExpoDevelopment: true,
    debug: !config.isProd(),
  })
}
