/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * NessPay internal API
 * NessPay endpoints are stateless and require only an API key to be used.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as url from "url";
import { Configuration } from "./configuration";

const fetchAPI = fetch

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration?: Configuration;
    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = fetchAPI) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}



/**
 * 
 * @export
 * @interface AccountIntegration
 */
export interface AccountIntegration {
    /**
     * 
     * @type {string}
     * @memberof AccountIntegration
     */
    name: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof AccountIntegration
     */
    categories?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof AccountIntegration
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountIntegration
     */
    square_image?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountIntegration
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountIntegration
     */
    slug?: string;
}

/**
 * 
 * @export
 * @interface ActivityLog
 */
export interface ActivityLog {
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    byUserId: string;
    /**
     * 
     * @type {User}
     * @memberof ActivityLog
     */
    byUser: User;
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    userId: string;
    /**
     * 
     * @type {User}
     * @memberof ActivityLog
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    organizationId: string;
    /**
     * 
     * @type {Organization}
     * @memberof ActivityLog
     */
    organization: Organization;
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    action: ActivityLog.ActionEnum;
    /**
     * 
     * @type {any}
     * @memberof ActivityLog
     */
    info: any;
    /**
     * 
     * @type {Array<any>}
     * @memberof ActivityLog
     */
    modifiedFields: Array<any>;
    /**
     * 
     * @type {any}
     * @memberof ActivityLog
     */
    entity: any;
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    updatedAt: string;
}

/**
 * @export
 * @namespace ActivityLog
 */
export namespace ActivityLog {
    /**
     * @export
     * @enum {string}
     */
    export enum ActionEnum {
        UserCreated = 'UserCreated',
        UserUpdated = 'UserUpdated',
        ContractCreated = 'ContractCreated',
        ContractUpdated = 'ContractUpdated',
        TransactionCreated = 'TransactionCreated',
        TransactionUpdated = 'TransactionUpdated',
        OrganizationUpdated = 'OrganizationUpdated',
    }
}

/**
 * 
 * @export
 * @interface AddNonInvitedUserToSheetBody
 */
export interface AddNonInvitedUserToSheetBody {
    /**
     * 
     * @type {string}
     * @memberof AddNonInvitedUserToSheetBody
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AddNonInvitedUserToSheetBody
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AddNonInvitedUserToSheetBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AddNonInvitedUserToSheetBody
     */
    company: string;
}

/**
 * 
 * @export
 * @interface AddNonInvitedUserToSheetResponse
 */
export interface AddNonInvitedUserToSheetResponse {
    /**
     * 
     * @type {string}
     * @memberof AddNonInvitedUserToSheetResponse
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddNonInvitedUserToSheetResponse
     */
    hasBeenInvited: boolean;
}

/**
 * 
 * @export
 * @interface AddSalaryRefundBody
 */
export interface AddSalaryRefundBody {
    /**
     * 
     * @type {number}
     * @memberof AddSalaryRefundBody
     */
    amount: number;
}

/**
 * 
 * @export
 * @interface AdvanceRequestBody
 */
export interface AdvanceRequestBody {
    /**
     * 
     * @type {number}
     * @memberof AdvanceRequestBody
     */
    amount: number;
}

/**
 * 
 * @export
 * @interface AdvanceRequestResponse
 */
export interface AdvanceRequestResponse {
    /**
     * 
     * @type {Transaction}
     * @memberof AdvanceRequestResponse
     */
    transaction: Transaction;
    /**
     * 
     * @type {ContractBalance}
     * @memberof AdvanceRequestResponse
     */
    balance: ContractBalance;
}

/**
 * 
 * @export
 * @interface AnalyticsBody
 */
export interface AnalyticsBody {
    /**
     * 
     * @type {string}
     * @memberof AnalyticsBody
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsBody
     */
    endDate: string;
}

/**
 * 
 * @export
 * @interface AnalyticsResponse
 */
export interface AnalyticsResponse {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsResponse
     */
    loggedInUsersRate: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsResponse
     */
    numberOfTransactions: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsResponse
     */
    averageTransactionAmount: number;
    /**
     * 
     * @type {Array<TimeSeriesPoint>}
     * @memberof AnalyticsResponse
     */
    numberOfLoggedUsersSeries: Array<TimeSeriesPoint>;
    /**
     * 
     * @type {Array<TimeSeriesPoint>}
     * @memberof AnalyticsResponse
     */
    numberOfTransactionsSeries: Array<TimeSeriesPoint>;
    /**
     * 
     * @type {Array<TimeSeriesPoint>}
     * @memberof AnalyticsResponse
     */
    averageTransactionAmountSeries: Array<TimeSeriesPoint>;
}

/**
 * 
 * @export
 * @interface AxeptioVendorsResponse
 */
export interface AxeptioVendorsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AxeptioVendorsResponse
     */
    mixpanel: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AxeptioVendorsResponse
     */
    microsoft_clarity: boolean;
}

/**
 * 
 * @export
 * @interface ChangeOrganizationBody
 */
export interface ChangeOrganizationBody {
    /**
     * 
     * @type {string}
     * @memberof ChangeOrganizationBody
     */
    organizationId: string;
}

/**
 * 
 * @export
 * @interface Consent
 */
export interface Consent {
    /**
     * 
     * @type {string}
     * @memberof Consent
     */
    __typename?: string;
    /**
     * 
     * @type {string}
     * @memberof Consent
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof Consent
     */
    requireSCA: boolean;
    /**
     * 
     * @type {string}
     * @memberof Consent
     */
    consentUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Consent
     */
    status: Consent.StatusEnum;
}

/**
 * @export
 * @namespace Consent
 */
export namespace Consent {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Accepted = 'Accepted',
        Canceled = 'Canceled',
        Created = 'Created',
        CredentialRefused = 'CredentialRefused',
        CustomerRefused = 'CustomerRefused',
        Expired = 'Expired',
        Failed = 'Failed',
        OperationCommitting = 'OperationCommitting',
        Started = 'Started',
    }
}

/**
 * 
 * @export
 * @interface Contract
 */
export interface Contract {
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    userId: string;
    /**
     * 
     * @type {User}
     * @memberof Contract
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    organizationId: string;
    /**
     * 
     * @type {Organization}
     * @memberof Contract
     */
    organization: Organization;
    /**
     * 
     * @type {Array<string>}
     * @memberof Contract
     */
    roles: Array<Contract.RolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    netSalary: number;
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    availableBalance: number;
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    availableSalaryFraction: number;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    contractStartDate: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    contractEndDate: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    staffNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    isNotAbsent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    isAppAccessEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    isManuallyDisabledByAdmin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contract
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    inactiveAt: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    usageStatus: Contract.UsageStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    silaePayrollFolder: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    entityName: string;
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    refundSalary: number;
    /**
     * 
     * @type {number}
     * @memberof Contract
     */
    inviteRemindersSent: number;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    updatedAt: string;
}

/**
 * @export
 * @namespace Contract
 */
export namespace Contract {
    /**
     * @export
     * @enum {string}
     */
    export enum RolesEnum {
        Employee = 'employee',
        Employer = 'employer',
        Admin = 'admin',
        Analyst = 'analyst',
        AccountManager = 'accountManager',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum UsageStatusEnum {
        NeverLoggedIn = 'NeverLoggedIn',
        LoggedInAtLeastOnce = 'LoggedInAtLeastOnce',
    }
}

/**
 * 
 * @export
 * @interface ContractBalance
 */
export interface ContractBalance {
    /**
     * 
     * @type {number}
     * @memberof ContractBalance
     */
    availableSalary: number;
    /**
     * 
     * @type {number}
     * @memberof ContractBalance
     */
    earnedSalary: number;
    /**
     * 
     * @type {number}
     * @memberof ContractBalance
     */
    earnedSalaryAfterDiscount: number;
    /**
     * 
     * @type {number}
     * @memberof ContractBalance
     */
    bookedTransactionsAmount: number;
    /**
     * 
     * @type {number}
     * @memberof ContractBalance
     */
    pendingTransactionsAmount: number;
    /**
     * 
     * @type {string}
     * @memberof ContractBalance
     */
    unavailableAdvanceReason?: ContractBalance.UnavailableAdvanceReasonEnum;
}

/**
 * @export
 * @namespace ContractBalance
 */
export namespace ContractBalance {
    /**
     * @export
     * @enum {string}
     */
    export enum UnavailableAdvanceReasonEnum {
        ENDOFSALARYPERIOD = 'END_OF_SALARY_PERIOD',
    }
}

/**
 * 
 * @export
 * @interface CreateBorrowerBody
 */
export interface CreateBorrowerBody {
    /**
     * 
     * @type {string}
     * @memberof CreateBorrowerBody
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBorrowerBody
     */
    iban: string;
    /**
     * 
     * @type {Array<DefactoDirector>}
     * @memberof CreateBorrowerBody
     */
    directors?: Array<DefactoDirector>;
}

/**
 * 
 * @export
 * @interface CreateContractBody
 */
export interface CreateContractBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateContractBody
     */
    roles: Array<CreateContractBody.RolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof CreateContractBody
     */
    netSalary: number;
    /**
     * 
     * @type {number}
     * @memberof CreateContractBody
     */
    availableSalaryFraction: number;
    /**
     * 
     * @type {string}
     * @memberof CreateContractBody
     */
    staffNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateContractBody
     */
    isAdvanceServiceEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateContractBody
     */
    refundSalary: number;
    /**
     * 
     * @type {CreateUserBody}
     * @memberof CreateContractBody
     */
    user: CreateUserBody;
}

/**
 * @export
 * @namespace CreateContractBody
 */
export namespace CreateContractBody {
    /**
     * @export
     * @enum {string}
     */
    export enum RolesEnum {
        Employee = 'employee',
        Employer = 'employer',
        Admin = 'admin',
        Analyst = 'analyst',
        AccountManager = 'accountManager',
    }
}

/**
 * 
 * @export
 * @interface CreateImportEmployeesRequest
 */
export interface CreateImportEmployeesRequest {
    /**
     * 
     * @type {boolean}
     * @memberof CreateImportEmployeesRequest
     */
    shouldCreate: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateImportEmployeesRequest
     */
    shouldUpdate: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateImportEmployeesRequest
     */
    shouldDelete: boolean;
    /**
     * 
     * @type {Array<ImportedUser>}
     * @memberof CreateImportEmployeesRequest
     */
    importedUsers: Array<ImportedUser>;
}

/**
 * 
 * @export
 * @interface CreateImportEmployeesResponse
 */
export interface CreateImportEmployeesResponse {
    /**
     * 
     * @type {Array<Contract>}
     * @memberof CreateImportEmployeesResponse
     */
    contractsCreated: Array<Contract>;
    /**
     * 
     * @type {Array<Contract>}
     * @memberof CreateImportEmployeesResponse
     */
    contractsUpdated: Array<Contract>;
    /**
     * 
     * @type {Array<Contract>}
     * @memberof CreateImportEmployeesResponse
     */
    contractsDeleted: Array<Contract>;
}

/**
 * 
 * @export
 * @interface CreateKomboIntegrationBody
 */
export interface CreateKomboIntegrationBody {
    /**
     * 
     * @type {string}
     * @memberof CreateKomboIntegrationBody
     */
    token: string;
}

/**
 * 
 * @export
 * @interface CreateLinkTokenBody
 */
export interface CreateLinkTokenBody {
    /**
     * 
     * @type {string}
     * @memberof CreateLinkTokenBody
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLinkTokenBody
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLinkTokenBody
     */
    userEmail: string;
}

/**
 * 
 * @export
 * @interface CreateLinkTokenResponse
 */
export interface CreateLinkTokenResponse {
    /**
     * Token used to generate the link to Merge on the frontend
     * @type {string}
     * @memberof CreateLinkTokenResponse
     */
    linkToken: string;
    /**
     * Previously authorized integration, otherwise null if no integration is currently authorized
     * @type {string}
     * @memberof CreateLinkTokenResponse
     */
    integrationName: string;
}

/**
 * 
 * @export
 * @interface CreateOrganization
 */
export interface CreateOrganization {
    /**
     * 
     * @type {number}
     * @memberof CreateOrganization
     */
    alertBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrganization
     */
    payPeriodStart?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrganization
     */
    bufferDays?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganization
     */
    swanAccountId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganization
     */
    swanOnboardingId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganization
     */
    name: string;
}

/**
 * 
 * @export
 * @interface CreateOrganizationResponse
 */
export interface CreateOrganizationResponse {
    /**
     * 
     * @type {Organization}
     * @memberof CreateOrganizationResponse
     */
    organization: Organization;
    /**
     * 
     * @type {Array<Contract>}
     * @memberof CreateOrganizationResponse
     */
    adminContracts: Array<Contract>;
}

/**
 * 
 * @export
 * @interface CreatePaymentBody
 */
export interface CreatePaymentBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePaymentBody
     */
    transactionIds: Array<string>;
}

/**
 * 
 * @export
 * @interface CreateTransactionFixturesBody
 */
export interface CreateTransactionFixturesBody {
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionFixturesBody
     */
    nbOfDaysFromNow?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionFixturesBody
     */
    nbOfTransactions?: number;
}

/**
 * 
 * @export
 * @interface CreateUserBody
 */
export interface CreateUserBody {
    /**
     * 
     * @type {string}
     * @memberof CreateUserBody
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserBody
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserBody
     */
    iban: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserBody
     */
    phone: string;
}

/**
 * 
 * @export
 * @interface CreateUserFixturesBody
 */
export interface CreateUserFixturesBody {
    /**
     * 
     * @type {number}
     * @memberof CreateUserFixturesBody
     */
    nbOfUsers?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateUserFixturesBody
     */
    nbOfDaysFromNow?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateUserFixturesBody
     */
    nbOfTransactions?: number;
}

/**
 * 
 * @export
 * @interface CreateUserTokenFromCodeBody
 */
export interface CreateUserTokenFromCodeBody {
    /**
     * 
     * @type {string}
     * @memberof CreateUserTokenFromCodeBody
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserTokenFromCodeBody
     */
    redirectUrl: string;
}

/**
 * 
 * @export
 * @interface CreateUserTokenFromRefreshTokenBody
 */
export interface CreateUserTokenFromRefreshTokenBody {
    /**
     * 
     * @type {string}
     * @memberof CreateUserTokenFromRefreshTokenBody
     */
    refreshToken: string;
}

/**
 * 
 * @export
 * @interface CreditTransfersResponse
 */
export interface CreditTransfersResponse {
    /**
     * 
     * @type {string}
     * @memberof CreditTransfersResponse
     */
    __typename?: string;
    /**
     * 
     * @type {SwanMultiConsentPayload}
     * @memberof CreditTransfersResponse
     */
    createMultiConsent: SwanMultiConsentPayload;
}

/**
 * 
 * @export
 * @interface DefactoDirector
 */
export interface DefactoDirector {
    /**
     * 
     * @type {DefactoDirectorAddress}
     * @memberof DefactoDirector
     */
    address: DefactoDirectorAddress;
    /**
     * 
     * @type {string}
     * @memberof DefactoDirector
     */
    business_email: string;
    /**
     * 
     * @type {string}
     * @memberof DefactoDirector
     */
    birth_date: string;
    /**
     * 
     * @type {string}
     * @memberof DefactoDirector
     */
    nationality: string;
    /**
     * 
     * @type {string}
     * @memberof DefactoDirector
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof DefactoDirector
     */
    last_name: string;
}

/**
 * 
 * @export
 * @interface DefactoDirectorAddress
 */
export interface DefactoDirectorAddress {
    /**
     * 
     * @type {string}
     * @memberof DefactoDirectorAddress
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof DefactoDirectorAddress
     */
    address_line_1?: string;
    /**
     * 
     * @type {string}
     * @memberof DefactoDirectorAddress
     */
    address_line_2?: string;
    /**
     * 
     * @type {string}
     * @memberof DefactoDirectorAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof DefactoDirectorAddress
     */
    postal_code?: string;
}

/**
 * 
 * @export
 * @interface DeleteAdminUserBody
 */
export interface DeleteAdminUserBody {
    /**
     * 
     * @type {string}
     * @memberof DeleteAdminUserBody
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteAdminUserBody
     */
    email: string;
}

/**
 * 
 * @export
 * @interface DeleteIntegrationBody
 */
export interface DeleteIntegrationBody {
    /**
     * 
     * @type {string}
     * @memberof DeleteIntegrationBody
     */
    type: DeleteIntegrationBody.TypeEnum;
}

/**
 * @export
 * @namespace DeleteIntegrationBody
 */
export namespace DeleteIntegrationBody {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        SILAE = 'SILAE',
        MERGE = 'MERGE',
        KOMBO = 'KOMBO',
        ROLLEE = 'ROLLEE',
    }
}

/**
 * 
 * @export
 * @interface DeleteRolesContractBody
 */
export interface DeleteRolesContractBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteRolesContractBody
     */
    roles: Array<DeleteRolesContractBody.RolesEnum>;
}

/**
 * @export
 * @namespace DeleteRolesContractBody
 */
export namespace DeleteRolesContractBody {
    /**
     * @export
     * @enum {string}
     */
    export enum RolesEnum {
        Employee = 'employee',
        Employer = 'employer',
        Admin = 'admin',
        Analyst = 'analyst',
        AccountManager = 'accountManager',
    }
}

/**
 * 
 * @export
 * @interface EnrollUserWithAuthenticatorAppResponse
 */
export interface EnrollUserWithAuthenticatorAppResponse {
    /**
     * 
     * @type {string}
     * @memberof EnrollUserWithAuthenticatorAppResponse
     */
    qrCode: string;
}

/**
 * 
 * @export
 * @interface ExportConfig
 */
export interface ExportConfig {
    /**
     * 
     * @type {string}
     * @memberof ExportConfig
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof ExportConfig
     */
    type?: ExportConfig.TypeEnum;
    /**
     * 
     * @type {FileFormat}
     * @memberof ExportConfig
     */
    fileFormat: FileFormat;
}

/**
 * @export
 * @namespace ExportConfig
 */
export namespace ExportConfig {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Raw = 'Raw',
        Silae = 'Silae',
        Payfit = 'Payfit',
    }
}

/**
 * 
 * @export
 * @interface ExportFilters
 */
export interface ExportFilters {
    /**
     * 
     * @type {string}
     * @memberof ExportFilters
     */
    organizationId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExportFilters
     */
    statuses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExportFilters
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ExportFilters
     */
    endDate: string;
}

/**
 * 
 * @export
 * @interface FileFormat
 */
export interface FileFormat {
    /**
     * 
     * @type {string}
     * @memberof FileFormat
     */
    type: FileFormat.TypeEnum;
}

/**
 * @export
 * @namespace FileFormat
 */
export namespace FileFormat {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Csv = 'csv',
        Xlsx = 'xlsx',
    }
}

/**
 * 
 * @export
 * @interface FinancingAddress
 */
export interface FinancingAddress {
    /**
     * 
     * @type {string}
     * @memberof FinancingAddress
     */
    address_line_1?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingAddress
     */
    address_line_2?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingAddress
     */
    postal_code?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingAddress
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingAddress
     */
    country?: string;
}

/**
 * 
 * @export
 * @interface FinancingBill
 */
export interface FinancingBill {
    /**
     * 
     * @type {string}
     * @memberof FinancingBill
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingBill
     */
    ran_at: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingBill
     */
    start_date: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingBill
     */
    end_date: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingBill
     */
    due_date: string;
    /**
     * 
     * @type {FinancingPayment}
     * @memberof FinancingBill
     */
    payment: FinancingPayment;
    /**
     * 
     * @type {string}
     * @memberof FinancingBill
     */
    status: FinancingBill.StatusEnum;
    /**
     * 
     * @type {FinancingBusinessIdentifier}
     * @memberof FinancingBill
     */
    billed_business: FinancingBusinessIdentifier;
    /**
     * 
     * @type {FinancingInvoice}
     * @memberof FinancingBill
     */
    invoice?: FinancingInvoice;
    /**
     * 
     * @type {string}
     * @memberof FinancingBill
     */
    invoice_number?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingBill
     */
    document_url?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingBill
     */
    amends_bill_id?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingBill
     */
    email_sent_to_payer_on?: string;
}

/**
 * @export
 * @namespace FinancingBill
 */
export namespace FinancingBill {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        GENERATED = 'GENERATED',
        VALIDATED = 'VALIDATED',
        SENTTOPAYER = 'SENT_TO_PAYER',
        SENTTOPAYER_3 = 'SENTTOPAYER',
        TOPAY = 'TO_PAY',
        TOPAY_5 = 'TOPAY',
        PAID = 'PAID',
        INVALID = 'INVALID',
    }
}

/**
 * 
 * @export
 * @interface FinancingBillPage
 */
export interface FinancingBillPage {
    /**
     * 
     * @type {Array<FinancingBill>}
     * @memberof FinancingBillPage
     */
    data?: Array<FinancingBill>;
    /**
     * 
     * @type {number}
     * @memberof FinancingBillPage
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof FinancingBillPage
     */
    page_size: number;
    /**
     * 
     * @type {string}
     * @memberof FinancingBillPage
     */
    next_page?: string;
}

/**
 * 
 * @export
 * @interface FinancingBirthPlace
 */
export interface FinancingBirthPlace {
    /**
     * 
     * @type {string}
     * @memberof FinancingBirthPlace
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingBirthPlace
     */
    country?: string;
}

/**
 * 
 * @export
 * @interface FinancingBorrower
 */
export interface FinancingBorrower {
    /**
     * 
     * @type {FinancingAddress}
     * @memberof FinancingBorrower
     */
    address?: FinancingAddress;
    /**
     * 
     * @type {Array<FinancingLightAccount>}
     * @memberof FinancingBorrower
     */
    accounts?: Array<FinancingLightAccount>;
    /**
     * 
     * @type {string}
     * @memberof FinancingBorrower
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingBorrower
     */
    signed_at?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingBorrower
     */
    legal_form?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingBorrower
     */
    status?: FinancingBorrower.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingBorrower
     */
    identifier: string;
    /**
     * 
     * @type {Array<FinancingEmployee>}
     * @memberof FinancingBorrower
     */
    directors: Array<FinancingEmployee>;
    /**
     * 
     * @type {string}
     * @memberof FinancingBorrower
     */
    identifier_type?: FinancingBorrower.IdentifierTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingBorrower
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingBorrower
     */
    vat_number?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingBorrower
     */
    contract_s3_url?: string;
}

/**
 * @export
 * @namespace FinancingBorrower
 */
export namespace FinancingBorrower {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        TOSIGN = 'TO_SIGN',
        TOSIGN_1 = 'TOSIGN',
        SIGNED = 'SIGNED',
        SDDSETUP = 'SDD_SETUP',
        SDDSETUP_4 = 'SDDSETUP',
        ALLPAYMENTSETUP = 'ALL_PAYMENT_SETUP',
        ALLPAYMENTSETUP_6 = 'ALLPAYMENTSETUP',
        READY = 'READY',
        CANCELED = 'CANCELED',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum IdentifierTypeEnum {
        Siret = 'siret',
        Siret_1 = 'Siret',
        Siren = 'siren',
        Siren_3 = 'Siren',
        VatNumber = 'vat_number',
        VatNumber_5 = 'VatNumber',
        Name = 'name',
        Name_7 = 'Name',
        Nif = 'nif',
        Nif_9 = 'Nif',
        Cif = 'cif',
        Cif_11 = 'Cif',
        Kvk = 'kvk',
        Kvk_13 = 'Kvk',
        Bsn = 'bsn',
        Bsn_15 = 'Bsn',
        HrNummer = 'hr_nummer',
        HrNummer_17 = 'HrNummer',
        BelgiumRegistrationNumber = 'belgium_registration_number',
        BelgiumRegistrationNumber_19 = 'BelgiumRegistrationNumber',
    }
}

/**
 * 
 * @export
 * @interface FinancingBusinessCounterparty
 */
export interface FinancingBusinessCounterparty {
    /**
     * 
     * @type {any}
     * @memberof FinancingBusinessCounterparty
     */
    business_id?: any;
    /**
     * 
     * @type {any}
     * @memberof FinancingBusinessCounterparty
     */
    id?: any;
    /**
     * 
     * @type {string}
     * @memberof FinancingBusinessCounterparty
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingBusinessCounterparty
     */
    identifier_type?: FinancingBusinessCounterparty.IdentifierTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingBusinessCounterparty
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingBusinessCounterparty
     */
    vat_number?: string;
}

/**
 * @export
 * @namespace FinancingBusinessCounterparty
 */
export namespace FinancingBusinessCounterparty {
    /**
     * @export
     * @enum {string}
     */
    export enum IdentifierTypeEnum {
        Siret = 'siret',
        Siret_1 = 'Siret',
        Siren = 'siren',
        Siren_3 = 'Siren',
        VatNumber = 'vat_number',
        VatNumber_5 = 'VatNumber',
        Name = 'name',
        Name_7 = 'Name',
        Nif = 'nif',
        Nif_9 = 'Nif',
        Cif = 'cif',
        Cif_11 = 'Cif',
        Kvk = 'kvk',
        Kvk_13 = 'Kvk',
        Bsn = 'bsn',
        Bsn_15 = 'Bsn',
        HrNummer = 'hr_nummer',
        HrNummer_17 = 'HrNummer',
        BelgiumRegistrationNumber = 'belgium_registration_number',
        BelgiumRegistrationNumber_19 = 'BelgiumRegistrationNumber',
    }
}

/**
 * 
 * @export
 * @interface FinancingBusinessIdentifier
 */
export interface FinancingBusinessIdentifier {
    /**
     * 
     * @type {string}
     * @memberof FinancingBusinessIdentifier
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingBusinessIdentifier
     */
    identifier_type?: FinancingBusinessIdentifier.IdentifierTypeEnum;
}

/**
 * @export
 * @namespace FinancingBusinessIdentifier
 */
export namespace FinancingBusinessIdentifier {
    /**
     * @export
     * @enum {string}
     */
    export enum IdentifierTypeEnum {
        Siret = 'siret',
        Siret_1 = 'Siret',
        Siren = 'siren',
        Siren_3 = 'Siren',
        VatNumber = 'vat_number',
        VatNumber_5 = 'VatNumber',
        Name = 'name',
        Name_7 = 'Name',
        Nif = 'nif',
        Nif_9 = 'Nif',
        Cif = 'cif',
        Cif_11 = 'Cif',
        Kvk = 'kvk',
        Kvk_13 = 'Kvk',
        Bsn = 'bsn',
        Bsn_15 = 'Bsn',
        HrNummer = 'hr_nummer',
        HrNummer_17 = 'HrNummer',
        BelgiumRegistrationNumber = 'belgium_registration_number',
        BelgiumRegistrationNumber_19 = 'BelgiumRegistrationNumber',
    }
}

/**
 * 
 * @export
 * @interface FinancingCreditLine
 */
export interface FinancingCreditLine {
    /**
     * 
     * @type {number}
     * @memberof FinancingCreditLine
     */
    pool_amount?: number;
    /**
     * 
     * @type {string}
     * @memberof FinancingCreditLine
     */
    start_date: string;
    /**
     * 
     * @type {FinancingLightAccountDetails}
     * @memberof FinancingCreditLine
     */
    default_repayment_to?: FinancingLightAccountDetails;
    /**
     * 
     * @type {any}
     * @memberof FinancingCreditLine
     */
    id?: any;
    /**
     * 
     * @type {number}
     * @memberof FinancingCreditLine
     */
    vat?: number;
    /**
     * 
     * @type {string}
     * @memberof FinancingCreditLine
     */
    signed_at?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingCreditLine
     */
    currency?: FinancingCreditLine.CurrencyEnum;
    /**
     * 
     * @type {FinancingLightBusiness}
     * @memberof FinancingCreditLine
     */
    fees_payer?: FinancingLightBusiness;
    /**
     * 
     * @type {FinancingLightBusiness}
     * @memberof FinancingCreditLine
     */
    default_borrower?: FinancingLightBusiness;
    /**
     * 
     * @type {string}
     * @memberof FinancingCreditLine
     */
    distribution: FinancingCreditLine.DistributionEnum;
    /**
     * 
     * @type {number}
     * @memberof FinancingCreditLine
     */
    balance_pending?: number;
    /**
     * 
     * @type {FinancingLightAccountDetails}
     * @memberof FinancingCreditLine
     */
    default_repayment_from?: FinancingLightAccountDetails;
    /**
     * 
     * @type {string}
     * @memberof FinancingCreditLine
     */
    default_borrower_strategy?: FinancingCreditLine.DefaultBorrowerStrategyEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingCreditLine
     */
    default_repayment_method?: FinancingCreditLine.DefaultRepaymentMethodEnum;
    /**
     * 
     * @type {number}
     * @memberof FinancingCreditLine
     */
    fees_interest_daily_rate: number;
    /**
     * 
     * @type {number}
     * @memberof FinancingCreditLine
     */
    balance_available?: number;
    /**
     * 
     * @type {FinancingLightBusiness}
     * @memberof FinancingCreditLine
     */
    default_guarantor?: FinancingLightBusiness;
    /**
     * 
     * @type {number}
     * @memberof FinancingCreditLine
     */
    fees_daily_rate?: number;
    /**
     * 
     * @type {string}
     * @memberof FinancingCreditLine
     */
    contract_number?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingCreditLine
     */
    end_date?: string;
    /**
     * 
     * @type {FinancingLightAccountDetails}
     * @memberof FinancingCreditLine
     */
    default_loan_from?: FinancingLightAccountDetails;
    /**
     * 
     * @type {number}
     * @memberof FinancingCreditLine
     */
    fees_operating_daily_rate: number;
}

/**
 * @export
 * @namespace FinancingCreditLine
 */
export namespace FinancingCreditLine {
    /**
     * @export
     * @enum {string}
     */
    export enum CurrencyEnum {
        EUR = 'EUR',
        GBP = 'GBP',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DistributionEnum {
        DIRECT = 'DIRECT',
        PARTNER = 'PARTNER',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DefaultBorrowerStrategyEnum {
        BUYER = 'BUYER',
        SELLER = 'SELLER',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DefaultRepaymentMethodEnum {
        DIRECTDEBIT = 'DIRECT_DEBIT',
        DIRECTDEBIT_1 = 'DIRECTDEBIT',
        SCT = 'SCT',
        P2P = 'P2P',
    }
}

/**
 * 
 * @export
 * @interface FinancingEmployee
 */
export interface FinancingEmployee {
    /**
     * 
     * @type {string}
     * @memberof FinancingEmployee
     */
    business_phone_number?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingEmployee
     */
    id?: string;
    /**
     * 
     * @type {FinancingAddress}
     * @memberof FinancingEmployee
     */
    address?: FinancingAddress;
    /**
     * 
     * @type {FinancingBirthPlace}
     * @memberof FinancingEmployee
     */
    birth_place?: FinancingBirthPlace;
    /**
     * 
     * @type {string}
     * @memberof FinancingEmployee
     */
    role?: FinancingEmployee.RoleEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingEmployee
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingEmployee
     */
    birth_date?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingEmployee
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingEmployee
     */
    business_email?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingEmployee
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingEmployee
     */
    nationality?: string;
}

/**
 * @export
 * @namespace FinancingEmployee
 */
export namespace FinancingEmployee {
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        DIRECTOR = 'DIRECTOR',
        NONDIRECTOR = 'NON_DIRECTOR',
        NONDIRECTOR_2 = 'NONDIRECTOR',
    }
}

/**
 * 
 * @export
 * @interface FinancingInvoice
 */
export interface FinancingInvoice {
    /**
     * 
     * @type {number}
     * @memberof FinancingInvoice
     */
    tax_amount?: number;
    /**
     * 
     * @type {FinancingLightAccountDetails}
     * @memberof FinancingInvoice
     */
    to_account_details?: FinancingLightAccountDetails;
    /**
     * 
     * @type {any}
     * @memberof FinancingInvoice
     */
    id?: any;
    /**
     * 
     * @type {string}
     * @memberof FinancingInvoice
     */
    due_at?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingInvoice
     */
    document_url?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingInvoice
     */
    currency?: FinancingInvoice.CurrencyEnum;
    /**
     * 
     * @type {FinancingBusinessCounterparty}
     * @memberof FinancingInvoice
     */
    seller?: FinancingBusinessCounterparty;
    /**
     * 
     * @type {string}
     * @memberof FinancingInvoice
     */
    invoice_number: string;
    /**
     * 
     * @type {number}
     * @memberof FinancingInvoice
     */
    to_pay_amount?: number;
    /**
     * 
     * @type {string}
     * @memberof FinancingInvoice
     */
    status?: FinancingInvoice.StatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof FinancingInvoice
     */
    loan_ids?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FinancingInvoice
     */
    total_amount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FinancingInvoice
     */
    is_draft?: boolean;
    /**
     * 
     * @type {FinancingBusinessCounterparty}
     * @memberof FinancingInvoice
     */
    buyer?: FinancingBusinessCounterparty;
    /**
     * 
     * @type {string}
     * @memberof FinancingInvoice
     */
    issued_at?: string;
    /**
     * 
     * @type {number}
     * @memberof FinancingInvoice
     */
    net_amount: number;
}

/**
 * @export
 * @namespace FinancingInvoice
 */
export namespace FinancingInvoice {
    /**
     * @export
     * @enum {string}
     */
    export enum CurrencyEnum {
        EUR = 'EUR',
        GBP = 'GBP',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        TOSUBMIT = 'TO_SUBMIT',
        TOSUBMIT_1 = 'TOSUBMIT',
        SUBMITTED = 'SUBMITTED',
        TOEDIT = 'TO_EDIT',
        TOEDIT_4 = 'TOEDIT',
        VERIFIED = 'VERIFIED',
        DELETED = 'DELETED',
    }
}

/**
 * 
 * @export
 * @interface FinancingLightAccount
 */
export interface FinancingLightAccount {
    /**
     * 
     * @type {string}
     * @memberof FinancingLightAccount
     */
    account_number: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingLightAccount
     */
    account_name?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingLightAccount
     */
    bank_identifier_type: FinancingLightAccount.BankIdentifierTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingLightAccount
     */
    account_number_type: FinancingLightAccount.AccountNumberTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingLightAccount
     */
    bank_identifier: string;
}

/**
 * @export
 * @namespace FinancingLightAccount
 */
export namespace FinancingLightAccount {
    /**
     * @export
     * @enum {string}
     */
    export enum BankIdentifierTypeEnum {
        Bic = 'bic',
        Bic_1 = 'Bic',
        RoutingNumber = 'routing_number',
        RoutingNumber_3 = 'RoutingNumber',
        Name = 'name',
        Name_5 = 'Name',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AccountNumberTypeEnum {
        Iban = 'iban',
        Iban_1 = 'Iban',
        AccountNumber = 'account_number',
        AccountNumber_3 = 'AccountNumber',
        InternalId = 'internal_id',
        InternalId_5 = 'InternalId',
    }
}

/**
 * 
 * @export
 * @interface FinancingLightAccountDetails
 */
export interface FinancingLightAccountDetails {
    /**
     * 
     * @type {string}
     * @memberof FinancingLightAccountDetails
     */
    bank_identifier_type: FinancingLightAccountDetails.BankIdentifierTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingLightAccountDetails
     */
    bank_identifier: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingLightAccountDetails
     */
    account_number_type: FinancingLightAccountDetails.AccountNumberTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingLightAccountDetails
     */
    account_number: string;
}

/**
 * @export
 * @namespace FinancingLightAccountDetails
 */
export namespace FinancingLightAccountDetails {
    /**
     * @export
     * @enum {string}
     */
    export enum BankIdentifierTypeEnum {
        Bic = 'bic',
        Bic_1 = 'Bic',
        RoutingNumber = 'routing_number',
        RoutingNumber_3 = 'RoutingNumber',
        Name = 'name',
        Name_5 = 'Name',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AccountNumberTypeEnum {
        Iban = 'iban',
        Iban_1 = 'Iban',
        AccountNumber = 'account_number',
        AccountNumber_3 = 'AccountNumber',
        InternalId = 'internal_id',
        InternalId_5 = 'InternalId',
    }
}

/**
 * 
 * @export
 * @interface FinancingLightBusiness
 */
export interface FinancingLightBusiness {
    /**
     * 
     * @type {string}
     * @memberof FinancingLightBusiness
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingLightBusiness
     */
    identifier_type?: FinancingLightBusiness.IdentifierTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingLightBusiness
     */
    legal_form?: string;
    /**
     * 
     * @type {number}
     * @memberof FinancingLightBusiness
     */
    share_capital?: number;
    /**
     * 
     * @type {string}
     * @memberof FinancingLightBusiness
     */
    registration_body?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingLightBusiness
     */
    vat_number?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingLightBusiness
     */
    name?: string;
    /**
     * 
     * @type {any}
     * @memberof FinancingLightBusiness
     */
    id?: any;
    /**
     * 
     * @type {any}
     * @memberof FinancingLightBusiness
     */
    address?: any;
}

/**
 * @export
 * @namespace FinancingLightBusiness
 */
export namespace FinancingLightBusiness {
    /**
     * @export
     * @enum {string}
     */
    export enum IdentifierTypeEnum {
        Siret = 'siret',
        Siret_1 = 'Siret',
        Siren = 'siren',
        Siren_3 = 'Siren',
        VatNumber = 'vat_number',
        VatNumber_5 = 'VatNumber',
        Name = 'name',
        Name_7 = 'Name',
        Nif = 'nif',
        Nif_9 = 'Nif',
        Cif = 'cif',
        Cif_11 = 'Cif',
        Kvk = 'kvk',
        Kvk_13 = 'Kvk',
        Bsn = 'bsn',
        Bsn_15 = 'Bsn',
        HrNummer = 'hr_nummer',
        HrNummer_17 = 'HrNummer',
        BelgiumRegistrationNumber = 'belgium_registration_number',
        BelgiumRegistrationNumber_19 = 'BelgiumRegistrationNumber',
    }
}

/**
 * 
 * @export
 * @interface FinancingLoan
 */
export interface FinancingLoan {
    /**
     * 
     * @type {Array<string>}
     * @memberof FinancingLoan
     */
    loan_from_references?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FinancingLoan
     */
    repayment_method?: FinancingLoan.RepaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingLoan
     */
    closed_at?: string;
    /**
     * 
     * @type {FinancingLightBusiness}
     * @memberof FinancingLoan
     */
    guarantor?: FinancingLightBusiness;
    /**
     * 
     * @type {Array<string>}
     * @memberof FinancingLoan
     */
    notification_emails?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FinancingLoan
     */
    invoice_ids?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FinancingLoan
     */
    earliest_to_repay_at?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FinancingLoan
     */
    repayment_to_references?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FinancingLoan
     */
    status?: FinancingLoan.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingLoan
     */
    transfer_contract_url?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingLoan
     */
    to_pay_at: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FinancingLoan
     */
    repayment_from_references?: Array<string>;
    /**
     * 
     * @type {FinancingLightBusiness}
     * @memberof FinancingLoan
     */
    beneficiary: FinancingLightBusiness;
    /**
     * 
     * @type {string}
     * @memberof FinancingLoan
     */
    loan_type: FinancingLoan.LoanTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingLoan
     */
    denial_reason?: string;
    /**
     * 
     * @type {FinancingLightAccountDetails}
     * @memberof FinancingLoan
     */
    repayment_to?: FinancingLightAccountDetails;
    /**
     * 
     * @type {string}
     * @memberof FinancingLoan
     */
    repaid_nominal_at?: string;
    /**
     * 
     * @type {number}
     * @memberof FinancingLoan
     */
    fees_estimated?: number;
    /**
     * 
     * @type {any}
     * @memberof FinancingLoan
     */
    id?: any;
    /**
     * 
     * @type {number}
     * @memberof FinancingLoan
     */
    repaid_amount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof FinancingLoan
     */
    loan_to_references?: Array<string>;
    /**
     * 
     * @type {FinancingLightBusiness}
     * @memberof FinancingLoan
     */
    borrower?: FinancingLightBusiness;
    /**
     * 
     * @type {string}
     * @memberof FinancingLoan
     */
    currency: FinancingLoan.CurrencyEnum;
    /**
     * 
     * @type {FinancingLightAccountDetails}
     * @memberof FinancingLoan
     */
    repayment_from?: FinancingLightAccountDetails;
    /**
     * 
     * @type {FinancingLightAccountDetails}
     * @memberof FinancingLoan
     */
    loan_from?: FinancingLightAccountDetails;
    /**
     * 
     * @type {number}
     * @memberof FinancingLoan
     */
    fees_ongoing?: number;
    /**
     * 
     * @type {FinancingLightAccountDetails}
     * @memberof FinancingLoan
     */
    loan_to?: FinancingLightAccountDetails;
    /**
     * 
     * @type {string}
     * @memberof FinancingLoan
     */
    to_repay_at: string;
    /**
     * 
     * @type {number}
     * @memberof FinancingLoan
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof FinancingLoan
     */
    submitted_at: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingLoan
     */
    contract_url?: string;
}

/**
 * @export
 * @namespace FinancingLoan
 */
export namespace FinancingLoan {
    /**
     * @export
     * @enum {string}
     */
    export enum RepaymentMethodEnum {
        DIRECTDEBIT = 'DIRECT_DEBIT',
        DIRECTDEBIT_1 = 'DIRECTDEBIT',
        SCT = 'SCT',
        P2P = 'P2P',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        SUBMITTED = 'SUBMITTED',
        TOVALIDATE = 'TO_VALIDATE',
        TOVALIDATE_2 = 'TOVALIDATE',
        DECLINED = 'DECLINED',
        VALIDATED = 'VALIDATED',
        CANCELED = 'CANCELED',
        SCHEDULED = 'SCHEDULED',
        INITIATED = 'INITIATED',
        TOREPAY = 'TO_REPAY',
        TOREPAY_9 = 'TOREPAY',
        TOREPAYFEES = 'TO_REPAY_FEES',
        TOREPAYFEES_11 = 'TOREPAYFEES',
        OVERDUE = 'OVERDUE',
        CLOSED = 'CLOSED',
        ISSUEDETECTED = 'ISSUE_DETECTED',
        ISSUEDETECTED_15 = 'ISSUEDETECTED',
        DELETED = 'DELETED',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum LoanTypeEnum {
        INVOICEBASEDFINANCING = 'INVOICE_BASED_FINANCING',
        INVOICEBASEDFINANCING_1 = 'INVOICEBASEDFINANCING',
        WALLETFINANCING = 'WALLET_FINANCING',
        WALLETFINANCING_3 = 'WALLETFINANCING',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum CurrencyEnum {
        EUR = 'EUR',
        GBP = 'GBP',
    }
}

/**
 * 
 * @export
 * @interface FinancingLoanPage
 */
export interface FinancingLoanPage {
    /**
     * 
     * @type {Array<FinancingLoan>}
     * @memberof FinancingLoanPage
     */
    data?: Array<FinancingLoan>;
    /**
     * 
     * @type {number}
     * @memberof FinancingLoanPage
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof FinancingLoanPage
     */
    page_size: number;
    /**
     * 
     * @type {string}
     * @memberof FinancingLoanPage
     */
    next_page?: string;
}

/**
 * 
 * @export
 * @interface FinancingLoanPayment
 */
export interface FinancingLoanPayment {
    /**
     * 
     * @type {number}
     * @memberof FinancingLoanPayment
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof FinancingLoanPayment
     */
    payment_type: FinancingLoanPayment.PaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingLoanPayment
     */
    loan_id: string;
}

/**
 * @export
 * @namespace FinancingLoanPayment
 */
export namespace FinancingLoanPayment {
    /**
     * @export
     * @enum {string}
     */
    export enum PaymentTypeEnum {
        LOANPAYMENT = 'LOAN_PAYMENT',
        LOANPAYMENT_1 = 'LOANPAYMENT',
        REPAYMENTNOMINAL = 'REPAYMENT_NOMINAL',
        REPAYMENTNOMINAL_3 = 'REPAYMENTNOMINAL',
        REPAYMENTFEES = 'REPAYMENT_FEES',
        REPAYMENTFEES_5 = 'REPAYMENTFEES',
        REPAYMENTFULL = 'REPAYMENT_FULL',
        REPAYMENTFULL_7 = 'REPAYMENTFULL',
        REFUND = 'REFUND',
        EXTRA = 'EXTRA',
        INTERNAL = 'INTERNAL',
        RETURNED = 'RETURNED',
        REVENUESHARE = 'REVENUE_SHARE',
        REVENUESHARE_13 = 'REVENUESHARE',
        LOANPURCHASE = 'LOAN_PURCHASE',
        LOANPURCHASE_15 = 'LOANPURCHASE',
        CHARGEBACK = 'CHARGEBACK',
        CHANNELVERIFICATION = 'CHANNEL_VERIFICATION',
        CHANNELVERIFICATION_18 = 'CHANNELVERIFICATION',
        PAYMENTPROVIDERFEES = 'PAYMENT_PROVIDER_FEES',
        PAYMENTPROVIDERFEES_20 = 'PAYMENTPROVIDERFEES',
    }
}

/**
 * 
 * @export
 * @interface FinancingPayment
 */
export interface FinancingPayment {
    /**
     * 
     * @type {FinancingProviderPaymentID}
     * @memberof FinancingPayment
     */
    provider?: FinancingProviderPaymentID;
    /**
     * 
     * @type {number}
     * @memberof FinancingPayment
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof FinancingPayment
     */
    scheduled_at?: string;
    /**
     * 
     * @type {FinancingLightAccountDetails}
     * @memberof FinancingPayment
     */
    to_account_details?: FinancingLightAccountDetails;
    /**
     * 
     * @type {any}
     * @memberof FinancingPayment
     */
    id?: any;
    /**
     * 
     * @type {string}
     * @memberof FinancingPayment
     */
    status: FinancingPayment.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingPayment
     */
    paid_at?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FinancingPayment
     */
    is_instant?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FinancingPayment
     */
    currency?: FinancingPayment.CurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingPayment
     */
    payment_method?: FinancingPayment.PaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingPayment
     */
    failure_reason?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingPayment
     */
    payment_type?: FinancingPayment.PaymentTypeEnum;
    /**
     * 
     * @type {FinancingPaymentRelation}
     * @memberof FinancingPayment
     */
    related_to?: FinancingPaymentRelation;
    /**
     * 
     * @type {Array<string>}
     * @memberof FinancingPayment
     */
    to_references?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FinancingPayment
     */
    from_references?: Array<string>;
    /**
     * 
     * @type {FinancingLightAccountDetails}
     * @memberof FinancingPayment
     */
    from_account_details?: FinancingLightAccountDetails;
}

/**
 * @export
 * @namespace FinancingPayment
 */
export namespace FinancingPayment {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        PROPOSED = 'PROPOSED',
        SCHEDULED = 'SCHEDULED',
        CANCELED = 'CANCELED',
        FAILED = 'FAILED',
        INSTRUCTED = 'INSTRUCTED',
        INTRANSIT = 'IN_TRANSIT',
        INTRANSIT_6 = 'INTRANSIT',
        PAID = 'PAID',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum CurrencyEnum {
        EUR = 'EUR',
        GBP = 'GBP',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PaymentMethodEnum {
        DIRECTDEBIT = 'DIRECT_DEBIT',
        DIRECTDEBIT_1 = 'DIRECTDEBIT',
        SCT = 'SCT',
        P2P = 'P2P',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PaymentTypeEnum {
        LOANPAYMENT = 'LOAN_PAYMENT',
        LOANPAYMENT_1 = 'LOANPAYMENT',
        REPAYMENTNOMINAL = 'REPAYMENT_NOMINAL',
        REPAYMENTNOMINAL_3 = 'REPAYMENTNOMINAL',
        REPAYMENTFEES = 'REPAYMENT_FEES',
        REPAYMENTFEES_5 = 'REPAYMENTFEES',
        REPAYMENTFULL = 'REPAYMENT_FULL',
        REPAYMENTFULL_7 = 'REPAYMENTFULL',
        REFUND = 'REFUND',
        EXTRA = 'EXTRA',
        INTERNAL = 'INTERNAL',
        RETURNED = 'RETURNED',
        REVENUESHARE = 'REVENUE_SHARE',
        REVENUESHARE_13 = 'REVENUESHARE',
        LOANPURCHASE = 'LOAN_PURCHASE',
        LOANPURCHASE_15 = 'LOANPURCHASE',
        CHARGEBACK = 'CHARGEBACK',
        CHANNELVERIFICATION = 'CHANNEL_VERIFICATION',
        CHANNELVERIFICATION_18 = 'CHANNELVERIFICATION',
        PAYMENTPROVIDERFEES = 'PAYMENT_PROVIDER_FEES',
        PAYMENTPROVIDERFEES_20 = 'PAYMENTPROVIDERFEES',
    }
}

/**
 * 
 * @export
 * @interface FinancingPaymentRelation
 */
export interface FinancingPaymentRelation {
    /**
     * 
     * @type {Array<FinancingLoanPayment>}
     * @memberof FinancingPaymentRelation
     */
    loans?: Array<FinancingLoanPayment>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FinancingPaymentRelation
     */
    invoices?: Array<string>;
}

/**
 * 
 * @export
 * @interface FinancingPaymentsPage
 */
export interface FinancingPaymentsPage {
    /**
     * 
     * @type {Array<FinancingPayment>}
     * @memberof FinancingPaymentsPage
     */
    data?: Array<FinancingPayment>;
    /**
     * 
     * @type {number}
     * @memberof FinancingPaymentsPage
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof FinancingPaymentsPage
     */
    page_size: number;
    /**
     * 
     * @type {string}
     * @memberof FinancingPaymentsPage
     */
    next_page?: string;
}

/**
 * 
 * @export
 * @interface FinancingProviderPaymentID
 */
export interface FinancingProviderPaymentID {
    /**
     * 
     * @type {string}
     * @memberof FinancingProviderPaymentID
     */
    name: FinancingProviderPaymentID.NameEnum;
    /**
     * 
     * @type {string}
     * @memberof FinancingProviderPaymentID
     */
    payment_id?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingProviderPaymentID
     */
    original_id?: string;
}

/**
 * @export
 * @namespace FinancingProviderPaymentID
 */
export namespace FinancingProviderPaymentID {
    /**
     * @export
     * @enum {string}
     */
    export enum NameEnum {
        SWAN = 'SWAN',
        SANDBOX = 'SANDBOX',
        LEMONWAY = 'LEMONWAY',
        TEST = 'TEST',
    }
}

/**
 * 
 * @export
 * @interface GenerateCodeBody
 */
export interface GenerateCodeBody {
    /**
     * 
     * @type {string}
     * @memberof GenerateCodeBody
     */
    email: string;
}

/**
 * 
 * @export
 * @interface GenerateOTCBody
 */
export interface GenerateOTCBody {
    /**
     * 
     * @type {string}
     * @memberof GenerateOTCBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOTCBody
     */
    type: GenerateOTCBody.TypeEnum;
}

/**
 * @export
 * @namespace GenerateOTCBody
 */
export namespace GenerateOTCBody {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        ChangeEmail = 'ChangeEmail',
    }
}

/**
 * 
 * @export
 * @interface GenerateOTCResponse
 */
export interface GenerateOTCResponse {
    /**
     * 
     * @type {string}
     * @memberof GenerateOTCResponse
     */
    encodedInfo: string;
}

/**
 * 
 * @export
 * @interface GenerateSwanConsentLinkBody
 */
export interface GenerateSwanConsentLinkBody {
    /**
     * 
     * @type {string}
     * @memberof GenerateSwanConsentLinkBody
     */
    clientAccountId: string;
}

/**
 * 
 * @export
 * @interface GetAccountResponse
 */
export interface GetAccountResponse {
    /**
     * 
     * @type {SwanAccount}
     * @memberof GetAccountResponse
     */
    account: SwanAccount;
}

/**
 * 
 * @export
 * @interface GetAccountStatementsBody
 */
export interface GetAccountStatementsBody {
    /**
     * 
     * @type {string}
     * @memberof GetAccountStatementsBody
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountStatementsBody
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountStatementsBody
     */
    format: GetAccountStatementsBody.FormatEnum;
}

/**
 * @export
 * @namespace GetAccountStatementsBody
 */
export namespace GetAccountStatementsBody {
    /**
     * @export
     * @enum {string}
     */
    export enum FormatEnum {
        CSV = 'CSV',
        PDF = 'PDF',
    }
}

/**
 * 
 * @export
 * @interface GetAccountTokenResponse
 */
export interface GetAccountTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof GetAccountTokenResponse
     */
    accountToken: string;
    /**
     * 
     * @type {AccountIntegration}
     * @memberof GetAccountTokenResponse
     */
    integration: AccountIntegration;
}

/**
 * 
 * @export
 * @interface GetAccountTransactionsBody
 */
export interface GetAccountTransactionsBody {
    /**
     * 
     * @type {string}
     * @memberof GetAccountTransactionsBody
     */
    isAfterUpdatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountTransactionsBody
     */
    isBeforeUpdatedAt: string;
}

/**
 * 
 * @export
 * @interface GetAccountWithTransactionsResponse
 */
export interface GetAccountWithTransactionsResponse {
    /**
     * 
     * @type {SwanAccount}
     * @memberof GetAccountWithTransactionsResponse
     */
    account: SwanAccount;
    /**
     * 
     * @type {Array<SwanTransaction>}
     * @memberof GetAccountWithTransactionsResponse
     */
    transactions: Array<SwanTransaction>;
}

/**
 * 
 * @export
 * @interface GetConnectionUrlBody
 */
export interface GetConnectionUrlBody {
    /**
     * 
     * @type {string}
     * @memberof GetConnectionUrlBody
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionUrlBody
     */
    email: string;
}

/**
 * 
 * @export
 * @interface GetConnectionUrlResponse
 */
export interface GetConnectionUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof GetConnectionUrlResponse
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionUrlResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionUrlResponse
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof GetConnectionUrlResponse
     */
    url: string;
}

/**
 * 
 * @export
 * @interface GetManyActivityLogResponseDto
 */
export interface GetManyActivityLogResponseDto {
    /**
     * 
     * @type {Array<ActivityLog>}
     * @memberof GetManyActivityLogResponseDto
     */
    data: Array<ActivityLog>;
    /**
     * 
     * @type {number}
     * @memberof GetManyActivityLogResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyActivityLogResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyActivityLogResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyActivityLogResponseDto
     */
    pageCount: number;
}

/**
 * 
 * @export
 * @interface GetManyContractResponseDto
 */
export interface GetManyContractResponseDto {
    /**
     * 
     * @type {Array<Contract>}
     * @memberof GetManyContractResponseDto
     */
    data: Array<Contract>;
    /**
     * 
     * @type {number}
     * @memberof GetManyContractResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyContractResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyContractResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyContractResponseDto
     */
    pageCount: number;
}

/**
 * 
 * @export
 * @interface GetManyIntegrationResponseDto
 */
export interface GetManyIntegrationResponseDto {
    /**
     * 
     * @type {Array<Integration>}
     * @memberof GetManyIntegrationResponseDto
     */
    data: Array<Integration>;
    /**
     * 
     * @type {number}
     * @memberof GetManyIntegrationResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyIntegrationResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyIntegrationResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyIntegrationResponseDto
     */
    pageCount: number;
}

/**
 * 
 * @export
 * @interface GetManyOrganizationResponseDto
 */
export interface GetManyOrganizationResponseDto {
    /**
     * 
     * @type {Array<Organization>}
     * @memberof GetManyOrganizationResponseDto
     */
    data: Array<Organization>;
    /**
     * 
     * @type {number}
     * @memberof GetManyOrganizationResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyOrganizationResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyOrganizationResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyOrganizationResponseDto
     */
    pageCount: number;
}

/**
 * 
 * @export
 * @interface GetManyTransactionResponseDto
 */
export interface GetManyTransactionResponseDto {
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof GetManyTransactionResponseDto
     */
    data: Array<Transaction>;
    /**
     * 
     * @type {number}
     * @memberof GetManyTransactionResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTransactionResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTransactionResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyTransactionResponseDto
     */
    pageCount: number;
}

/**
 * 
 * @export
 * @interface GetPaymentBody
 */
export interface GetPaymentBody {
    /**
     * 
     * @type {string}
     * @memberof GetPaymentBody
     */
    id: string;
}

/**
 * 
 * @export
 * @interface ImportEmployeesRequest
 */
export interface ImportEmployeesRequest {
    /**
     * 
     * @type {Array<ImportedUser>}
     * @memberof ImportEmployeesRequest
     */
    importedUsers: Array<ImportedUser>;
}

/**
 * 
 * @export
 * @interface ImportEmployeesSummaryResponse
 */
export interface ImportEmployeesSummaryResponse {
    /**
     * 
     * @type {number}
     * @memberof ImportEmployeesSummaryResponse
     */
    nbToCreate: number;
    /**
     * 
     * @type {number}
     * @memberof ImportEmployeesSummaryResponse
     */
    nbToUpdate: number;
    /**
     * 
     * @type {number}
     * @memberof ImportEmployeesSummaryResponse
     */
    nbToDelete: number;
    /**
     * 
     * @type {Array<Contract>}
     * @memberof ImportEmployeesSummaryResponse
     */
    importedContractsToCreate: Array<Contract>;
    /**
     * 
     * @type {Array<Contract>}
     * @memberof ImportEmployeesSummaryResponse
     */
    importedContractsToUpdate: Array<Contract>;
    /**
     * 
     * @type {Array<Contract>}
     * @memberof ImportEmployeesSummaryResponse
     */
    contractsToDelete: Array<Contract>;
}

/**
 * 
 * @export
 * @interface ImportEmployeesValidateResponse
 */
export interface ImportEmployeesValidateResponse {
    /**
     * 
     * @type {Array<UniquenessError>}
     * @memberof ImportEmployeesValidateResponse
     */
    errors: Array<UniquenessError>;
}

/**
 * 
 * @export
 * @interface ImportedUser
 */
export interface ImportedUser {
    /**
     * 
     * @type {string}
     * @memberof ImportedUser
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ImportedUser
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ImportedUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ImportedUser
     */
    staffNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ImportedUser
     */
    iban: string;
    /**
     * 
     * @type {number}
     * @memberof ImportedUser
     */
    netSalary?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportedUser
     */
    availableBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportedUser
     */
    availableSalaryFraction?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportedUser
     */
    phone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ImportedUser
     */
    isAdvanceServiceEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ImportedUser
     */
    contractStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportedUser
     */
    contractEndDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ImportedUser
     */
    active?: boolean;
}

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    status?: string;
    /**
     * 
     * @type {{ [key: string]: InlineResponse200Info; }}
     * @memberof InlineResponse200
     */
    info?: { [key: string]: InlineResponse200Info; };
    /**
     * 
     * @type {{ [key: string]: InlineResponse200Info; }}
     * @memberof InlineResponse200
     */
    error?: { [key: string]: InlineResponse200Info; };
    /**
     * 
     * @type {{ [key: string]: InlineResponse200Info; }}
     * @memberof InlineResponse200
     */
    details?: { [key: string]: InlineResponse200Info; };
}

/**
 * 
 * @export
 * @interface InlineResponse200Info
 */
export interface InlineResponse200Info {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Info
     */
    status?: string;
}

/**
 * 
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse400
     */
    statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse400
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse400
     */
    error?: string;
}

/**
 * 
 * @export
 * @interface InlineResponse404
 */
export interface InlineResponse404 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse404
     */
    statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse404
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse404
     */
    error?: string;
}

/**
 * 
 * @export
 * @interface InlineResponse409
 */
export interface InlineResponse409 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse409
     */
    statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse409
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse409
     */
    error?: string;
}

/**
 * 
 * @export
 * @interface InlineResponse503
 */
export interface InlineResponse503 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse503
     */
    status?: string;
    /**
     * 
     * @type {{ [key: string]: InlineResponse200Info; }}
     * @memberof InlineResponse503
     */
    info?: { [key: string]: InlineResponse200Info; };
    /**
     * 
     * @type {{ [key: string]: InlineResponse200Info; }}
     * @memberof InlineResponse503
     */
    error?: { [key: string]: InlineResponse200Info; };
    /**
     * 
     * @type {{ [key: string]: InlineResponse200Info; }}
     * @memberof InlineResponse503
     */
    details?: { [key: string]: InlineResponse200Info; };
}

/**
 * 
 * @export
 * @interface Integration
 */
export interface Integration {
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    organizationId: string;
    /**
     * 
     * @type {Organization}
     * @memberof Integration
     */
    organization: Organization;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    type: Integration.TypeEnum;
    /**
     * 
     * @type {any}
     * @memberof Integration
     */
    credentials: any;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    encryptionIV: string;
    /**
     * 
     * @type {any}
     * @memberof Integration
     */
    metadata: any;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    dataToSync: Integration.DataToSyncEnum;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Integration
     */
    updatedAt: string;
}

/**
 * @export
 * @namespace Integration
 */
export namespace Integration {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        SILAE = 'SILAE',
        MERGE = 'MERGE',
        KOMBO = 'KOMBO',
        ROLLEE = 'ROLLEE',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DataToSyncEnum {
        ALL = 'ALL',
        SALARY = 'SALARY',
        SCHEDULE = 'SCHEDULE',
    }
}

/**
 * 
 * @export
 * @interface KomboCreateLinkResponse
 */
export interface KomboCreateLinkResponse {
    /**
     * 
     * @type {string}
     * @memberof KomboCreateLinkResponse
     */
    link: string;
}

/**
 * 
 * @export
 * @interface KomboWebhookSyncFinished
 */
export interface KomboWebhookSyncFinished {
    /**
     * 
     * @type {string}
     * @memberof KomboWebhookSyncFinished
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof KomboWebhookSyncFinished
     */
    type: string;
    /**
     * 
     * @type {KomboWebhookSyncFinishedData}
     * @memberof KomboWebhookSyncFinished
     */
    data: KomboWebhookSyncFinishedData;
}

/**
 * 
 * @export
 * @interface KomboWebhookSyncFinishedData
 */
export interface KomboWebhookSyncFinishedData {
    /**
     * 
     * @type {string}
     * @memberof KomboWebhookSyncFinishedData
     */
    sync_id?: string;
    /**
     * 
     * @type {any}
     * @memberof KomboWebhookSyncFinishedData
     */
    sync_state?: any;
    /**
     * 
     * @type {string}
     * @memberof KomboWebhookSyncFinishedData
     */
    sync_started_at?: string;
    /**
     * 
     * @type {string}
     * @memberof KomboWebhookSyncFinishedData
     */
    sync_ended_at?: string;
    /**
     * 
     * @type {number}
     * @memberof KomboWebhookSyncFinishedData
     */
    sync_duration_seconds?: number;
    /**
     * 
     * @type {string}
     * @memberof KomboWebhookSyncFinishedData
     */
    integration_id?: string;
    /**
     * 
     * @type {string}
     * @memberof KomboWebhookSyncFinishedData
     */
    integration_tool?: string;
    /**
     * 
     * @type {string}
     * @memberof KomboWebhookSyncFinishedData
     */
    integration_category?: string;
}

/**
 * 
 * @export
 * @interface OnboardingResponseDto
 */
export interface OnboardingResponseDto {
    /**
     * 
     * @type {string}
     * @memberof OnboardingResponseDto
     */
    onboardingId: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingResponseDto
     */
    onboardingUrl: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingResponseDto
     */
    accountId?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingResponseDto
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingResponseDto
     */
    accountHolderId?: string;
    /**
     * 
     * @type {any}
     * @memberof OnboardingResponseDto
     */
    isInformationCollected: any;
    /**
     * 
     * @type {any}
     * @memberof OnboardingResponseDto
     */
    isUsersVerified: any;
    /**
     * 
     * @type {any}
     * @memberof OnboardingResponseDto
     */
    isDocumentsCollected: any;
    /**
     * 
     * @type {any}
     * @memberof OnboardingResponseDto
     */
    isDocumentsVerified: any;
    /**
     * 
     * @type {any}
     * @memberof OnboardingResponseDto
     */
    isComplete: any;
}

/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {Array<Contract>}
     * @memberof Organization
     */
    contracts: Array<Contract>;
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof Organization
     */
    transactions: Array<Transaction>;
    /**
     * 
     * @type {Array<ActivityLog>}
     * @memberof Organization
     */
    activityLogs: Array<ActivityLog>;
    /**
     * 
     * @type {Array<Integration>}
     * @memberof Organization
     */
    integrations: Array<Integration>;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    swanAccountId?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    swanOnboardingId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Organization
     */
    isSwanOnboardingComplete: boolean;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    borrowerId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Organization
     */
    borrowingEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    borrowingRepaymentDelay: number;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    borrowingLoanAmount: number;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    alertBalance: number;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    bufferDays: number;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    defaultNetSalary: number;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    defaultAvailableSalaryFraction: number;
    /**
     * 
     * @type {boolean}
     * @memberof Organization
     */
    canEmployeeRequest: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Organization
     */
    sendInvitationEmails: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Organization
     */
    isToutesMesAidesEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    mergeAccountToken?: string;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    payPeriodStart: number;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    reminderFrequencyUpdateUsers: Organization.ReminderFrequencyUpdateUsersEnum;
    /**
     * 
     * @type {OrganizationOnboarding}
     * @memberof Organization
     */
    onboarding: OrganizationOnboarding;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    klaroPrivateKey: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    encryptionIV: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    updatedAt: string;
}

/**
 * @export
 * @namespace Organization
 */
export namespace Organization {
    /**
     * @export
     * @enum {string}
     */
    export enum ReminderFrequencyUpdateUsersEnum {
        Weekly = 'weekly',
        Bimonthly = 'bimonthly',
        Monthly = 'monthly',
    }
}

/**
 * 
 * @export
 * @interface OrganizationOnboarding
 */
export interface OrganizationOnboarding {
    /**
     * 
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    hrSoftware?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    siren?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    legalRepresentativeEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    legalRepresentativeLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    legalRepresentativeFirstName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationOnboarding
     */
    hasBookedAnAppointment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationOnboarding
     */
    hasConnectedHrSoftware?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationOnboarding
     */
    hasAddedFundsOnAccount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationOnboarding
     */
    hasCompletedOnboarding?: boolean;
}

/**
 * 
 * @export
 * @interface OrganizationRequestDto
 */
export interface OrganizationRequestDto {
    /**
     * 
     * @type {number}
     * @memberof OrganizationRequestDto
     */
    bufferDays: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationRequestDto
     */
    canEmployeeRequest: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRequestDto
     */
    reminderFrequencyUpdateUsers: OrganizationRequestDto.ReminderFrequencyUpdateUsersEnum;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRequestDto
     */
    swanAccountId: string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationRequestDto
     */
    payPeriodStart: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationRequestDto
     */
    alertBalance: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRequestDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRequestDto
     */
    swanOnboardingId: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRequestDto
     */
    klaroPrivateKey: string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationRequestDto
     */
    defaultNetSalary: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationRequestDto
     */
    defaultAvailableSalaryFraction: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationRequestDto
     */
    borrowingEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationRequestDto
     */
    isSwanOnboardingComplete: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrganizationRequestDto
     */
    borrowingRepaymentDelay: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationRequestDto
     */
    borrowingLoanAmount: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationRequestDto
     */
    sendInvitationEmails: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationRequestDto
     */
    isToutesMesAidesEnabled: boolean;
}

/**
 * @export
 * @namespace OrganizationRequestDto
 */
export namespace OrganizationRequestDto {
    /**
     * @export
     * @enum {string}
     */
    export enum ReminderFrequencyUpdateUsersEnum {
        Weekly = 'weekly',
        Bimonthly = 'bimonthly',
        Monthly = 'monthly',
    }
}

/**
 * 
 * @export
 * @interface PaginationBody
 */
export interface PaginationBody {
    /**
     * 
     * @type {number}
     * @memberof PaginationBody
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginationBody
     */
    cursor?: string;
}

/**
 * 
 * @export
 * @interface PaymentQueryResponse
 */
export interface PaymentQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentQueryResponse
     */
    __typename?: string;
    /**
     * 
     * @type {SwanPayment}
     * @memberof PaymentQueryResponse
     */
    payment: SwanPayment;
}

/**
 * 
 * @export
 * @interface PaymentStatusInfo
 */
export interface PaymentStatusInfo {
    /**
     * 
     * @type {any}
     * @memberof PaymentStatusInfo
     */
    __typename: any;
    /**
     * 
     * @type {string}
     * @memberof PaymentStatusInfo
     */
    status?: PaymentStatusInfo.StatusEnum;
    /**
     * 
     * @type {Consent}
     * @memberof PaymentStatusInfo
     */
    consent?: Consent;
    /**
     * 
     * @type {string}
     * @memberof PaymentStatusInfo
     */
    reason?: string;
}

/**
 * @export
 * @namespace PaymentStatusInfo
 */
export namespace PaymentStatusInfo {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        ConsentPending = 'ConsentPending',
        Initiated = 'Initiated',
        Rejected = 'Rejected',
    }
}

/**
 * 
 * @export
 * @interface RefreshTokenBody
 */
export interface RefreshTokenBody {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenBody
     */
    token?: string;
}

/**
 * 
 * @export
 * @interface RequestLoanBody
 */
export interface RequestLoanBody {
    /**
     * 
     * @type {number}
     * @memberof RequestLoanBody
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof RequestLoanBody
     */
    iban: string;
    /**
     * 
     * @type {string}
     * @memberof RequestLoanBody
     */
    toPayAt: string;
    /**
     * 
     * @type {string}
     * @memberof RequestLoanBody
     */
    toRepayAt: string;
}

/**
 * 
 * @export
 * @interface RolleeAccount
 */
export interface RolleeAccount {
    /**
     * 
     * @type {string}
     * @memberof RolleeAccount
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RolleeAccount
     */
    user_id: string;
    /**
     * 
     * @type {string}
     * @memberof RolleeAccount
     */
    datasource_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof RolleeAccount
     */
    connected: boolean;
    /**
     * 
     * @type {string}
     * @memberof RolleeAccount
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RolleeAccount
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof RolleeAccount
     */
    updated_at: string;
}

/**
 * 
 * @export
 * @interface RolleeAccountResponse
 */
export interface RolleeAccountResponse {
    /**
     * 
     * @type {RolleeUser}
     * @memberof RolleeAccountResponse
     */
    user: RolleeUser;
    /**
     * 
     * @type {RolleeAccount}
     * @memberof RolleeAccountResponse
     */
    account: RolleeAccount;
}

/**
 * 
 * @export
 * @interface RolleeUser
 */
export interface RolleeUser {
    /**
     * 
     * @type {string}
     * @memberof RolleeUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RolleeUser
     */
    created_at: string;
    /**
     * 
     * @type {number}
     * @memberof RolleeUser
     */
    accounts_number: number;
    /**
     * 
     * @type {RolleeUserMetadata}
     * @memberof RolleeUser
     */
    metadata: RolleeUserMetadata;
    /**
     * 
     * @type {string}
     * @memberof RolleeUser
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof RolleeUser
     */
    session: string;
    /**
     * 
     * @type {string}
     * @memberof RolleeUser
     */
    session_expires_at: string;
}

/**
 * 
 * @export
 * @interface RolleeUserMetadata
 */
export interface RolleeUserMetadata {
    /**
     * 
     * @type {string}
     * @memberof RolleeUserMetadata
     */
    organizationId?: string;
}

/**
 * 
 * @export
 * @interface RolleeWebhookAccount
 */
export interface RolleeWebhookAccount {
    /**
     * 
     * @type {string}
     * @memberof RolleeWebhookAccount
     */
    action: string;
    /**
     * 
     * @type {RolleeAccount}
     * @memberof RolleeWebhookAccount
     */
    data: RolleeAccount;
}

/**
 * 
 * @export
 * @interface SendPowerOfAttorneyReminderBody
 */
export interface SendPowerOfAttorneyReminderBody {
    /**
     * 
     * @type {string}
     * @memberof SendPowerOfAttorneyReminderBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SendPowerOfAttorneyReminderBody
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof SendPowerOfAttorneyReminderBody
     */
    firstName: string;
}

/**
 * 
 * @export
 * @interface SignTermsAndConditionsBody
 */
export interface SignTermsAndConditionsBody {
    /**
     * 
     * @type {string}
     * @memberof SignTermsAndConditionsBody
     */
    termsAndConditionsSignedAt: string;
}

/**
 * 
 * @export
 * @interface SilaeCredentials
 */
export interface SilaeCredentials {
    /**
     * 
     * @type {Array<string>}
     * @memberof SilaeCredentials
     */
    numerosDossiers?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SilaeCredentials
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SilaeCredentials
     */
    secret: string;
    /**
     * 
     * @type {string}
     * @memberof SilaeCredentials
     */
    subscriptionKey: string;
}

/**
 * 
 * @export
 * @interface SwanAccount
 */
export interface SwanAccount {
    /**
     * 
     * @type {string}
     * @memberof SwanAccount
     */
    __typename?: string;
    /**
     * 
     * @type {string}
     * @memberof SwanAccount
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SwanAccount
     */
    BIC: string;
    /**
     * 
     * @type {string}
     * @memberof SwanAccount
     */
    IBAN?: string;
    /**
     * 
     * @type {string}
     * @memberof SwanAccount
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof SwanAccount
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SwanAccount
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof SwanAccount
     */
    country: SwanAccount.CountryEnum;
    /**
     * 
     * @type {SwanAccountStatusInfo}
     * @memberof SwanAccount
     */
    statusInfo: SwanAccountStatusInfo;
    /**
     * 
     * @type {SwanAccountBalances}
     * @memberof SwanAccount
     */
    balances?: SwanAccountBalances;
    /**
     * 
     * @type {string}
     * @memberof SwanAccount
     */
    paymentLevel: SwanAccount.PaymentLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof SwanAccount
     */
    paymentAccountType: SwanAccount.PaymentAccountTypeEnum;
    /**
     * 
     * @type {SwanAccountMembership}
     * @memberof SwanAccount
     */
    legalRepresentativeMembership: SwanAccountMembership;
}

/**
 * @export
 * @namespace SwanAccount
 */
export namespace SwanAccount {
    /**
     * @export
     * @enum {string}
     */
    export enum CountryEnum {
        DEU = 'DEU',
        ESP = 'ESP',
        FRA = 'FRA',
        ITA = 'ITA',
        NLD = 'NLD',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PaymentLevelEnum {
        Limited = 'Limited',
        Unlimited = 'Unlimited',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PaymentAccountTypeEnum {
        EMoney = 'EMoney',
        PaymentService = 'PaymentService',
    }
}

/**
 * 
 * @export
 * @interface SwanAccountBalances
 */
export interface SwanAccountBalances {
    /**
     * 
     * @type {string}
     * @memberof SwanAccountBalances
     */
    __typename?: string;
    /**
     * 
     * @type {SwanAmount}
     * @memberof SwanAccountBalances
     */
    available: SwanAmount;
    /**
     * 
     * @type {SwanAmount}
     * @memberof SwanAccountBalances
     */
    booked: SwanAmount;
    /**
     * 
     * @type {SwanAmount}
     * @memberof SwanAccountBalances
     */
    pending: SwanAmount;
    /**
     * 
     * @type {SwanAmount}
     * @memberof SwanAccountBalances
     */
    reserved: SwanAmount;
}

/**
 * 
 * @export
 * @interface SwanAccountMembership
 */
export interface SwanAccountMembership {
    /**
     * 
     * @type {string}
     * @memberof SwanAccountMembership
     */
    __typename?: string;
    /**
     * 
     * @type {string}
     * @memberof SwanAccountMembership
     */
    email: string;
    /**
     * 
     * @type {SwanUser}
     * @memberof SwanAccountMembership
     */
    user?: SwanUser;
}

/**
 * 
 * @export
 * @interface SwanAccountStatusInfo
 */
export interface SwanAccountStatusInfo {
    /**
     * 
     * @type {string}
     * @memberof SwanAccountStatusInfo
     */
    status: SwanAccountStatusInfo.StatusEnum;
}

/**
 * @export
 * @namespace SwanAccountStatusInfo
 */
export namespace SwanAccountStatusInfo {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Closed = 'Closed',
        Closing = 'Closing',
        Opened = 'Opened',
        Suspended = 'Suspended',
    }
}

/**
 * 
 * @export
 * @interface SwanAmount
 */
export interface SwanAmount {
    /**
     * 
     * @type {string}
     * @memberof SwanAmount
     */
    __typename?: string;
    /**
     * 
     * @type {string}
     * @memberof SwanAmount
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof SwanAmount
     */
    value: number;
}

/**
 * 
 * @export
 * @interface SwanMultiConsentPayload
 */
export interface SwanMultiConsentPayload {
    /**
     * 
     * @type {any}
     * @memberof SwanMultiConsentPayload
     */
    __typename: any;
    /**
     * 
     * @type {Consent}
     * @memberof SwanMultiConsentPayload
     */
    consent?: Consent;
}

/**
 * 
 * @export
 * @interface SwanPayment
 */
export interface SwanPayment {
    /**
     * 
     * @type {string}
     * @memberof SwanPayment
     */
    __typename?: string;
    /**
     * 
     * @type {string}
     * @memberof SwanPayment
     */
    id: string;
    /**
     * 
     * @type {SwanTransactionConnection}
     * @memberof SwanPayment
     */
    transactions: SwanTransactionConnection;
    /**
     * 
     * @type {PaymentStatusInfo}
     * @memberof SwanPayment
     */
    statusInfo: PaymentStatusInfo;
}

/**
 * 
 * @export
 * @interface SwanTransaction
 */
export interface SwanTransaction {
    /**
     * 
     * @type {string}
     * @memberof SwanTransaction
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SwanTransaction
     */
    paymentId: string;
    /**
     * 
     * @type {string}
     * @memberof SwanTransaction
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof SwanTransaction
     */
    externalReference: string;
    /**
     * 
     * @type {string}
     * @memberof SwanTransaction
     */
    executionDate: string;
    /**
     * 
     * @type {string}
     * @memberof SwanTransaction
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof SwanTransaction
     */
    counterparty: string;
    /**
     * 
     * @type {string}
     * @memberof SwanTransaction
     */
    side: SwanTransaction.SideEnum;
    /**
     * 
     * @type {string}
     * @memberof SwanTransaction
     */
    type: SwanTransaction.TypeEnum;
    /**
     * 
     * @type {SwanAmount}
     * @memberof SwanTransaction
     */
    amount: SwanAmount;
    /**
     * 
     * @type {SwanTransactionStatusInfo}
     * @memberof SwanTransaction
     */
    statusInfo: SwanTransactionStatusInfo;
}

/**
 * @export
 * @namespace SwanTransaction
 */
export namespace SwanTransaction {
    /**
     * @export
     * @enum {string}
     */
    export enum SideEnum {
        Credit = 'Credit',
        Debit = 'Debit',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        CardOutAuthorization = 'CardOutAuthorization',
        CardOutCredit = 'CardOutCredit',
        CardOutCreditReversal = 'CardOutCreditReversal',
        CardOutDebit = 'CardOutDebit',
        CardOutDebitReversal = 'CardOutDebitReversal',
        CheckIn = 'CheckIn',
        CheckInReturn = 'CheckInReturn',
        FeesIn = 'FeesIn',
        FeesOut = 'FeesOut',
        InternalCreditTransferIn = 'InternalCreditTransferIn',
        InternalCreditTransferInRecall = 'InternalCreditTransferInRecall',
        InternalCreditTransferInReturn = 'InternalCreditTransferInReturn',
        InternalCreditTransferOut = 'InternalCreditTransferOut',
        InternalCreditTransferOutRecall = 'InternalCreditTransferOutRecall',
        InternalCreditTransferOutReturn = 'InternalCreditTransferOutReturn',
        InternalDirectDebitIn = 'InternalDirectDebitIn',
        InternalDirectDebitInReturn = 'InternalDirectDebitInReturn',
        InternalDirectDebitOut = 'InternalDirectDebitOut',
        InternalDirectDebitOutReturn = 'InternalDirectDebitOutReturn',
        InternationalCreditTransferIn = 'InternationalCreditTransferIn',
        InternationalCreditTransferInReturn = 'InternationalCreditTransferInReturn',
        InternationalCreditTransferOut = 'InternationalCreditTransferOut',
        InternationalCreditTransferOutReturn = 'InternationalCreditTransferOutReturn',
        SepaCreditTransferIn = 'SepaCreditTransferIn',
        SepaCreditTransferInRecall = 'SepaCreditTransferInRecall',
        SepaCreditTransferInReturn = 'SepaCreditTransferInReturn',
        SepaCreditTransferOut = 'SepaCreditTransferOut',
        SepaCreditTransferOutRecall = 'SepaCreditTransferOutRecall',
        SepaCreditTransferOutReturn = 'SepaCreditTransferOutReturn',
        SepaDirectDebitIn = 'SepaDirectDebitIn',
        SepaDirectDebitInReturn = 'SepaDirectDebitInReturn',
        SepaDirectDebitInReversal = 'SepaDirectDebitInReversal',
        SepaDirectDebitOut = 'SepaDirectDebitOut',
        SepaDirectDebitOutReturn = 'SepaDirectDebitOutReturn',
        SepaDirectDebitOutReversal = 'SepaDirectDebitOutReversal',
        SepaInstantCreditTransferIn = 'SepaInstantCreditTransferIn',
        SepaInstantCreditTransferInRecall = 'SepaInstantCreditTransferInRecall',
        SepaInstantCreditTransferOut = 'SepaInstantCreditTransferOut',
        SepaInstantCreditTransferOutRecall = 'SepaInstantCreditTransferOutRecall',
    }
}

/**
 * 
 * @export
 * @interface SwanTransactionConnection
 */
export interface SwanTransactionConnection {
    /**
     * 
     * @type {string}
     * @memberof SwanTransactionConnection
     */
    __typename?: string;
    /**
     * 
     * @type {Array<SwanTransactionEdge>}
     * @memberof SwanTransactionConnection
     */
    edges: Array<SwanTransactionEdge>;
}

/**
 * 
 * @export
 * @interface SwanTransactionEdge
 */
export interface SwanTransactionEdge {
    /**
     * 
     * @type {string}
     * @memberof SwanTransactionEdge
     */
    __typename?: string;
    /**
     * 
     * @type {SwanTransaction}
     * @memberof SwanTransactionEdge
     */
    node: SwanTransaction;
}

/**
 * 
 * @export
 * @interface SwanTransactionStatusInfo
 */
export interface SwanTransactionStatusInfo {
    /**
     * 
     * @type {string}
     * @memberof SwanTransactionStatusInfo
     */
    status: SwanTransactionStatusInfo.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SwanTransactionStatusInfo
     */
    pendingEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SwanTransactionStatusInfo
     */
    canceledDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SwanTransactionStatusInfo
     */
    executionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SwanTransactionStatusInfo
     */
    bookingDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SwanTransactionStatusInfo
     */
    valueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SwanTransactionStatusInfo
     */
    releaseDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SwanTransactionStatusInfo
     */
    reason?: SwanTransactionStatusInfo.ReasonEnum;
}

/**
 * @export
 * @namespace SwanTransactionStatusInfo
 */
export namespace SwanTransactionStatusInfo {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Booked = 'Booked',
        Canceled = 'Canceled',
        Pending = 'Pending',
        Rejected = 'Rejected',
        Released = 'Released',
        Upcoming = 'Upcoming',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ReasonEnum {
        AccountClosed = 'AccountClosed',
        AccountHolderDeceased = 'AccountHolderDeceased',
        AccountMembershipRefused = 'AccountMembershipRefused',
        AccountSuspended = 'AccountSuspended',
        AccountUnknown = 'AccountUnknown',
        AmountMismatch = 'AmountMismatch',
        BankRefused = 'BankRefused',
        BeneficiaryBankNotReachable = 'BeneficiaryBankNotReachable',
        BeneficiaryMissingOrIncorrect = 'BeneficiaryMissingOrIncorrect',
        CardExpired = 'CardExpired',
        CardNotActivated = 'CardNotActivated',
        CardNumberInvalid = 'CardNumberInvalid',
        CardPermanentlyBlocked = 'CardPermanentlyBlocked',
        CardSuspended = 'CardSuspended',
        CardholderCancellation = 'CardholderCancellation',
        CheckReceivedLate = 'CheckReceivedLate',
        CreditorBankOffline = 'CreditorBankOffline',
        CreditorBankTechnicalErrorOccurred = 'CreditorBankTechnicalErrorOccurred',
        CreditorBankTimeout = 'CreditorBankTimeout',
        DateInvalid = 'DateInvalid',
        DateMissing = 'DateMissing',
        DebtorAccountClosed = 'DebtorAccountClosed',
        DebtorAccountConsumer = 'DebtorAccountConsumer',
        DebtorAccountUnknown = 'DebtorAccountUnknown',
        DebtorBankOffline = 'DebtorBankOffline',
        DebtorBankTechnicalErrorOccurred = 'DebtorBankTechnicalErrorOccurred',
        DebtorBankTimeout = 'DebtorBankTimeout',
        DebtorDeceased = 'DebtorDeceased',
        DebtorNameMissing = 'DebtorNameMissing',
        DigitalWalletDeactivated = 'DigitalWalletDeactivated',
        DigitalWalletEnrolmentInvalid = 'DigitalWalletEnrolmentInvalid',
        DigitalWalletRefusal = 'DigitalWalletRefusal',
        DigitalWalletSuspended = 'DigitalWalletSuspended',
        DigitalWalletTokenInvalid = 'DigitalWalletTokenInvalid',
        EndorsementMissing = 'EndorsementMissing',
        FraudSuspected = 'FraudSuspected',
        IbanInvalid = 'IbanInvalid',
        IbanSuspended = 'IbanSuspended',
        InsufficientFunds = 'InsufficientFunds',
        InvalidAmount = 'InvalidAmount',
        InvalidExpirationDate = 'InvalidExpirationDate',
        InvalidOrMissingAmount = 'InvalidOrMissingAmount',
        InvalidPin = 'InvalidPin',
        InvalidPinAttemptsExceeded = 'InvalidPinAttemptsExceeded',
        InvalidSecurityNumber = 'InvalidSecurityNumber',
        InvalidTransferDate = 'InvalidTransferDate',
        MandateInvalid = 'MandateInvalid',
        MerchantShouldResubmitAuthorization = 'MerchantShouldResubmitAuthorization',
        MissingExpirationDate = 'MissingExpirationDate',
        MissingPin = 'MissingPin',
        NoMandate = 'NoMandate',
        PartnerRefused = 'PartnerRefused',
        PartnerTechnicalErrorOccurred = 'PartnerTechnicalErrorOccurred',
        PeriodAmountLimitExceeded = 'PeriodAmountLimitExceeded',
        PeriodNbTransactionLimitExceeded = 'PeriodNbTransactionLimitExceeded',
        PeriodTransactionNumberLimitExceeded = 'PeriodTransactionNumberLimitExceeded',
        PinRequiredForFurtherTransaction = 'PinRequiredForFurtherTransaction',
        ReasonNotSpecifiedByBank = 'ReasonNotSpecifiedByBank',
        ReasonNotSpecifiedByDebtor = 'ReasonNotSpecifiedByDebtor',
        RegulatoryReason = 'RegulatoryReason',
        RetryWithChipAndPin = 'RetryWithChipAndPin',
        SignatureMissing = 'SignatureMissing',
        SuspiciousCheck = 'SuspiciousCheck',
        SwanOffline = 'SwanOffline',
        SwanRefused = 'SwanRefused',
        SwanTechnicalErrorOccurred = 'SwanTechnicalErrorOccurred',
        SwanTimeout = 'SwanTimeout',
        TermsAndConditionsLimitExceeded = 'TermsAndConditionsLimitExceeded',
        ThreeDsError = 'ThreeDsError',
        TransactionAmountLimitExceeded = 'TransactionAmountLimitExceeded',
        TransactionDuplicated = 'TransactionDuplicated',
        TransactionOnAccountTypeNotAllowed = 'TransactionOnAccountTypeNotAllowed',
        TransactionTypeNotAllowed = 'TransactionTypeNotAllowed',
    }
}

/**
 * 
 * @export
 * @interface SwanTransactionsDuplicates
 */
export interface SwanTransactionsDuplicates {
    /**
     * 
     * @type {Array<Array<SwanTransaction>>}
     * @memberof SwanTransactionsDuplicates
     */
    duplicates: Array<Array<SwanTransaction>>;
}

/**
 * 
 * @export
 * @interface SwanUser
 */
export interface SwanUser {
    /**
     * 
     * @type {string}
     * @memberof SwanUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SwanUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SwanUser
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof SwanUser
     */
    mobilePhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SwanUser
     */
    nationalityCCA3?: string;
    /**
     * 
     * @type {string}
     * @memberof SwanUser
     */
    birthDate?: string;
}

/**
 * 
 * @export
 * @interface SwanWebhookData
 */
export interface SwanWebhookData {
    /**
     * 
     * @type {any}
     * @memberof SwanWebhookData
     */
    eventType: any;
    /**
     * 
     * @type {string}
     * @memberof SwanWebhookData
     */
    eventId: string;
    /**
     * 
     * @type {string}
     * @memberof SwanWebhookData
     */
    eventDate: string;
    /**
     * 
     * @type {string}
     * @memberof SwanWebhookData
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof SwanWebhookData
     */
    resourceId: string;
    /**
     * 
     * @type {number}
     * @memberof SwanWebhookData
     */
    retryCount: number;
}

/**
 * 
 * @export
 * @interface TimeSeriesPoint
 */
export interface TimeSeriesPoint {
    /**
     * 
     * @type {string}
     * @memberof TimeSeriesPoint
     */
    x: string;
    /**
     * 
     * @type {number}
     * @memberof TimeSeriesPoint
     */
    y: number;
}

/**
 * 
 * @export
 * @interface ToggleAdvanceRightsBody
 */
export interface ToggleAdvanceRightsBody {
    /**
     * 
     * @type {boolean}
     * @memberof ToggleAdvanceRightsBody
     */
    newValue: boolean;
}

/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    token: string;
}

/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    userId: string;
    /**
     * 
     * @type {User}
     * @memberof Transaction
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    organizationId: string;
    /**
     * 
     * @type {Organization}
     * @memberof Transaction
     */
    organization: Organization;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    status: Transaction.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    swanStatus: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    swanId: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    swanPaymentId: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    swanExecutionDate: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    swanBookingDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    pendingSwanConsent: boolean;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    swanConsentId: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    entityName: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    updatedAt: string;
}

/**
 * @export
 * @namespace Transaction
 */
export namespace Transaction {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        INPROGRESS = 'INPROGRESS',
        CANCEL = 'CANCEL',
        DONE = 'DONE',
    }
}

/**
 * 
 * @export
 * @interface TransactionExportBody
 */
export interface TransactionExportBody {
    /**
     * 
     * @type {ExportConfig}
     * @memberof TransactionExportBody
     */
    config: ExportConfig;
    /**
     * 
     * @type {ExportFilters}
     * @memberof TransactionExportBody
     */
    filters: ExportFilters;
}

/**
 * 
 * @export
 * @interface TransactionSyncBody
 */
export interface TransactionSyncBody {
    /**
     * 
     * @type {number}
     * @memberof TransactionSyncBody
     */
    weeks: number;
}

/**
 * 
 * @export
 * @interface UniquenessError
 */
export interface UniquenessError {
    /**
     * 
     * @type {string}
     * @memberof UniquenessError
     */
    field: string;
    /**
     * 
     * @type {string}
     * @memberof UniquenessError
     */
    value: string;
}

/**
 * 
 * @export
 * @interface UpdateContractBody
 */
export interface UpdateContractBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateContractBody
     */
    roles: Array<UpdateContractBody.RolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof UpdateContractBody
     */
    netSalary: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateContractBody
     */
    availableSalaryFraction: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractBody
     */
    staffNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateContractBody
     */
    isAdvanceServiceEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateContractBody
     */
    isAppAccessEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateContractBody
     */
    isNotAbsent: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateContractBody
     */
    refundSalary: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractBody
     */
    contractStartDate: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContractBody
     */
    contractEndDate: string;
    /**
     * 
     * @type {UpdateUserBody}
     * @memberof UpdateContractBody
     */
    user: UpdateUserBody;
}

/**
 * @export
 * @namespace UpdateContractBody
 */
export namespace UpdateContractBody {
    /**
     * @export
     * @enum {string}
     */
    export enum RolesEnum {
        Employee = 'employee',
        Employer = 'employer',
        Admin = 'admin',
        Analyst = 'analyst',
        AccountManager = 'accountManager',
    }
}

/**
 * 
 * @export
 * @interface UpdateEmailBody
 */
export interface UpdateEmailBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailBody
     */
    email: string;
}

/**
 * 
 * @export
 * @interface UpdateRolesContractBody
 */
export interface UpdateRolesContractBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRolesContractBody
     */
    rolesToAdd: Array<UpdateRolesContractBody.RolesToAddEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRolesContractBody
     */
    rolesToDelete: Array<UpdateRolesContractBody.RolesToDeleteEnum>;
}

/**
 * @export
 * @namespace UpdateRolesContractBody
 */
export namespace UpdateRolesContractBody {
    /**
     * @export
     * @enum {string}
     */
    export enum RolesToAddEnum {
        Employee = 'employee',
        Employer = 'employer',
        Admin = 'admin',
        Analyst = 'analyst',
        AccountManager = 'accountManager',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RolesToDeleteEnum {
        Employee = 'employee',
        Employer = 'employer',
        Admin = 'admin',
        Analyst = 'analyst',
        AccountManager = 'accountManager',
    }
}

/**
 * 
 * @export
 * @interface UpdateUserBody
 */
export interface UpdateUserBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBody
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBody
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBody
     */
    iban: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBody
     */
    phone: string;
}

/**
 * 
 * @export
 * @interface UpsertAdminUserBody
 */
export interface UpsertAdminUserBody {
    /**
     * 
     * @type {string}
     * @memberof UpsertAdminUserBody
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertAdminUserBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertAdminUserBody
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertAdminUserBody
     */
    lastName: string;
}

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {Array<Contract>}
     * @memberof User
     */
    contracts: Array<Contract>;
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof User
     */
    transactions: Array<Transaction>;
    /**
     * 
     * @type {Array<ActivityLog>}
     * @memberof User
     */
    activityLogs: Array<ActivityLog>;
    /**
     * 
     * @type {Array<ActivityLog>}
     * @memberof User
     */
    byActivityLogs: Array<ActivityLog>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    iban: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    fronteggId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    organizationIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    mfaEnabled: boolean;
    /**
     * 
     * @type {UserMetadata}
     * @memberof User
     */
    metadata: UserMetadata;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    updatedAt: string;
}

/**
 * 
 * @export
 * @interface UserMetadata
 */
export interface UserMetadata {
    /**
     * 
     * @type {string}
     * @memberof UserMetadata
     */
    lastConnectionDate?: string;
    /**
     * 
     * @type {number}
     * @memberof UserMetadata
     */
    numberOfConnections?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserMetadata
     */
    isNotAbsent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMetadata
     */
    isManuallyDisabledByAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMetadata
     */
    isAppAccessEnabled?: boolean;
}

/**
 * 
 * @export
 * @interface UserOAuthResponseBody
 */
export interface UserOAuthResponseBody {
    /**
     * 
     * @type {string}
     * @memberof UserOAuthResponseBody
     */
    id_token: string;
    /**
     * 
     * @type {string}
     * @memberof UserOAuthResponseBody
     */
    access_token: string;
    /**
     * 
     * @type {string}
     * @memberof UserOAuthResponseBody
     */
    refresh_token: string;
    /**
     * 
     * @type {number}
     * @memberof UserOAuthResponseBody
     */
    expires_in: number;
    /**
     * 
     * @type {string}
     * @memberof UserOAuthResponseBody
     */
    scope: string;
    /**
     * 
     * @type {string}
     * @memberof UserOAuthResponseBody
     */
    token_type: string;
}

/**
 * 
 * @export
 * @interface ValidateCodeBody
 */
export interface ValidateCodeBody {
    /**
     * 
     * @type {string}
     * @memberof ValidateCodeBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateCodeBody
     */
    code: string;
}

/**
 * 
 * @export
 * @interface VerifyOTCBody
 */
export interface VerifyOTCBody {
    /**
     * 
     * @type {string}
     * @memberof VerifyOTCBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyOTCBody
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyOTCBody
     */
    encodedInfo: string;
}

/**
 * 
 * @export
 * @interface VerifyOTCResponse
 */
export interface VerifyOTCResponse {
    /**
     * 
     * @type {boolean}
     * @memberof VerifyOTCResponse
     */
    verified: boolean;
}

/**
 * 
 * @export
 * @interface VerifyUserEnrolledWithAuthenticatorAppBody
 */
export interface VerifyUserEnrolledWithAuthenticatorAppBody {
    /**
     * 
     * @type {string}
     * @memberof VerifyUserEnrolledWithAuthenticatorAppBody
     */
    token: string;
}

/**
 * 
 * @export
 * @interface VerifyUserEnrolledWithAuthenticatorAppResponse
 */
export interface VerifyUserEnrolledWithAuthenticatorAppResponse {
    /**
     * 
     * @type {string}
     * @memberof VerifyUserEnrolledWithAuthenticatorAppResponse
     */
    recoveryCode: string;
}

/**
 * 
 * @export
 * @interface WebflowBlogPost
 */
export interface WebflowBlogPost {
    /**
     * 
     * @type {boolean}
     * @memberof WebflowBlogPost
     */
    _archived: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebflowBlogPost
     */
    _draft: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    temps_de_lecture: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    date_francaise: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    post_body: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    meta_description: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    post_body_2: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    balise_title: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    post_summary: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    slug: string;
    /**
     * 
     * @type {WebflowFile}
     * @memberof WebflowBlogPost
     */
    main_image: WebflowFile;
    /**
     * 
     * @type {WebflowFile}
     * @memberof WebflowBlogPost
     */
    thumbnail_image: WebflowFile;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    author: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    theme: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    updated_on: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    updated_by: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    created_on: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    created_by: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    published_on: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    published_by: string;
    /**
     * 
     * @type {boolean}
     * @memberof WebflowBlogPost
     */
    publish_sur_l_app: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    _cid: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowBlogPost
     */
    _id: string;
}

/**
 * 
 * @export
 * @interface WebflowFile
 */
export interface WebflowFile {
    /**
     * 
     * @type {string}
     * @memberof WebflowFile
     */
    fileId: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowFile
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof WebflowFile
     */
    alt: string;
}/**
 * DefaultApi - fetch parameter creator
 * @export
 */
export const DefaultApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateTransactionFixturesBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerCreateFakeTransaction(body: CreateTransactionFixturesBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling adminControllerCreateFakeTransaction.');
            }
            const localVarPath = `/api/admin/fake-transactions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateTransactionFixturesBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserFixturesBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerCreateFakeUsers(body: CreateUserFixturesBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling adminControllerCreateFakeUsers.');
            }
            const localVarPath = `/api/admin/fake-users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateUserFixturesBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrganization} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerCreateOrganization(body: CreateOrganization, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling adminControllerCreateOrganization.');
            }
            const localVarPath = `/api/admin/organization`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateOrganization" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteAdminUserBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerDeleteAdmin(body: DeleteAdminUserBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling adminControllerDeleteAdmin.');
            }
            const localVarPath = `/api/admin/user`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeleteAdminUserBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteAdminUserBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerRemoveAdminRole(body: DeleteAdminUserBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling adminControllerRemoveAdminRole.');
            }
            const localVarPath = `/api/admin/user/remove-admin`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeleteAdminUserBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerSendEmployeeInvites(organizationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling adminControllerSendEmployeeInvites.');
            }
            const localVarPath = `/api/admin/{organizationId}/invite-employees`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertAdminUserBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerUpsertAdmin(body: UpsertAdminUserBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling adminControllerUpsertAdmin.');
            }
            const localVarPath = `/api/admin/user`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpsertAdminUserBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerGetAdminAdvancesExport(options: any = {}): FetchArgs {
            const localVarPath = `/api/advances/admin-export`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TransactionExportBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerGetAdvancesExport(body: TransactionExportBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling advancesControllerGetAdvancesExport.');
            }
            const localVarPath = `/api/advances/export`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TransactionExportBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdvanceRequestBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerRequestAdvance(body: AdvanceRequestBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling advancesControllerRequestAdvance.');
            }
            const localVarPath = `/api/advances/request`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdvanceRequestBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TransactionSyncBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerSyncNesspayTransactionsWithSwanTransactions(body: TransactionSyncBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling advancesControllerSyncNesspayTransactionsWithSwanTransactions.');
            }
            const localVarPath = `/api/advances/sync-swan-transactions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TransactionSyncBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AnalyticsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsControllerGetAnalytics(body: AnalyticsBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling analyticsControllerGetAnalytics.');
            }
            const localVarPath = `/api/analytics`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AnalyticsBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangeOrganizationBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerChangeOrganization(body: ChangeOrganizationBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerChangeOrganization.');
            }
            const localVarPath = `/api/auth/change-organization`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ChangeOrganizationBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateCodeBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGenerateCode(body: GenerateCodeBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerGenerateCode.');
            }
            const localVarPath = `/api/auth/generate-code`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GenerateCodeBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshTokenBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshToken(body: RefreshTokenBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerRefreshToken.');
            }
            const localVarPath = `/api/auth/refresh-token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RefreshTokenBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidateCodeBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerValidateCode(body: ValidateCodeBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerValidateCode.');
            }
            const localVarPath = `/api/auth/validate-code`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ValidateCodeBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        axeptioControllerGetConsents(options: any = {}): FetchArgs {
            const localVarPath = `/api/axeptio`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddSalaryRefundBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerAddRefund(body: AddSalaryRefundBody, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling contractControllerAddRefund.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contractControllerAddRefund.');
            }
            const localVarPath = `/api/contract/{id}/refund`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddSalaryRefundBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateContractBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerCreateOne(body: CreateContractBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling contractControllerCreateOne.');
            }
            const localVarPath = `/api/contract`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateContractBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerDeactivateContract(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contractControllerDeactivateContract.');
            }
            const localVarPath = `/api/contract/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerDeleteContract(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contractControllerDeleteContract.');
            }
            const localVarPath = `/api/contract/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerGetBalance(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contractControllerGetBalance.');
            }
            const localVarPath = `/api/contract/{id}/balance`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerGetEntityNames(options: any = {}): FetchArgs {
            const localVarPath = `/api/contract/entity-names`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerRemoveAdminRole(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contractControllerRemoveAdminRole.');
            }
            const localVarPath = `/api/contract/{id}/admin-role`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteRolesContractBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerRemoveRoles(body: DeleteRolesContractBody, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling contractControllerRemoveRoles.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contractControllerRemoveRoles.');
            }
            const localVarPath = `/api/contract/{id}/roles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeleteRolesContractBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerSyncUsersWithIntercomUsers(options: any = {}): FetchArgs {
            const localVarPath = `/api/contract/intercom-sync`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ToggleAdvanceRightsBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerToggleAdvanceRights(body: ToggleAdvanceRightsBody, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling contractControllerToggleAdvanceRights.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contractControllerToggleAdvanceRights.');
            }
            const localVarPath = `/api/contract/{id}/deactivate-advances`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ToggleAdvanceRightsBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateEmailBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerUpdateEmail(body: UpdateEmailBody, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling contractControllerUpdateEmail.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contractControllerUpdateEmail.');
            }
            const localVarPath = `/api/contract/{id}/email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateEmailBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateContractBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerUpdateOne(body: UpdateContractBody, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling contractControllerUpdateOne.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contractControllerUpdateOne.');
            }
            const localVarPath = `/api/contract/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateContractBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateRolesContractBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerUpdateRoles(body: UpdateRolesContractBody, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling contractControllerUpdateRoles.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contractControllerUpdateRoles.');
            }
            const localVarPath = `/api/contract/{id}/roles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateRolesContractBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseIntegrationControllerIntegration(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneBaseIntegrationControllerIntegration.');
            }
            const localVarPath = `/api/integration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBorrowerBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerCreateBorrower(body: CreateBorrowerBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling financingControllerCreateBorrower.');
            }
            const localVarPath = `/api/financing/borrower`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateBorrowerBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerDeactivateBorrower(options: any = {}): FetchArgs {
            const localVarPath = `/api/financing/borrower/deactivate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerGetBills(options: any = {}): FetchArgs {
            const localVarPath = `/api/financing/bills`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerGetBorrower(options: any = {}): FetchArgs {
            const localVarPath = `/api/financing/borrower`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerGetCreditLines(options: any = {}): FetchArgs {
            const localVarPath = `/api/financing/credit-lines`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PaginationBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerGetLoans(body: PaginationBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling financingControllerGetLoans.');
            }
            const localVarPath = `/api/financing/loans`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PaginationBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PaginationBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerGetLoansPayments(body: PaginationBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling financingControllerGetLoansPayments.');
            }
            const localVarPath = `/api/financing/loans/payments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PaginationBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestLoanBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerRequestLoan(body: RequestLoanBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling financingControllerRequestLoan.');
            }
            const localVarPath = `/api/financing/loan`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RequestLoanBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignTermsAndConditionsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerSignTermsAndConditions(body: SignTermsAndConditionsBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling financingControllerSignTermsAndConditions.');
            }
            const localVarPath = `/api/financing/sign-terms-and-conditions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SignTermsAndConditionsBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple ActivityLogs
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseActivityLogControllerActivityLog(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/activity`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS["csv"]);
            }
            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }
            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }
            if (or) {
                localVarQueryParameter['or'] = or;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            if (join) {
                localVarQueryParameter['join'] = join;
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Contracts
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseContractControllerContract(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/contract`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS["csv"]);
            }
            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }
            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }
            if (or) {
                localVarQueryParameter['or'] = or;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            if (join) {
                localVarQueryParameter['join'] = join;
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Integrations
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseIntegrationControllerIntegration(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/integration`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS["csv"]);
            }
            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }
            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }
            if (or) {
                localVarQueryParameter['or'] = or;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            if (join) {
                localVarQueryParameter['join'] = join;
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Organizations
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseOrganizationControllerOrganization(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/organization`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS["csv"]);
            }
            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }
            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }
            if (or) {
                localVarQueryParameter['or'] = or;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            if (join) {
                localVarQueryParameter['join'] = join;
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Transactions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseTransactionsControllerTransaction(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/transactions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS["csv"]);
            }
            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }
            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }
            if (or) {
                localVarQueryParameter['or'] = or;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            if (join) {
                localVarQueryParameter['join'] = join;
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single ActivityLog
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseActivityLogControllerActivityLog(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneBaseActivityLogControllerActivityLog.');
            }
            const localVarPath = `/api/activity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS["csv"]);
            }
            if (join) {
                localVarQueryParameter['join'] = join;
            }
            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Contract
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseContractControllerContract(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneBaseContractControllerContract.');
            }
            const localVarPath = `/api/contract/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS["csv"]);
            }
            if (join) {
                localVarQueryParameter['join'] = join;
            }
            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Integration
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseIntegrationControllerIntegration(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneBaseIntegrationControllerIntegration.');
            }
            const localVarPath = `/api/integration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS["csv"]);
            }
            if (join) {
                localVarQueryParameter['join'] = join;
            }
            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Organization
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseOrganizationControllerOrganization(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneBaseOrganizationControllerOrganization.');
            }
            const localVarPath = `/api/organization/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS["csv"]);
            }
            if (join) {
                localVarQueryParameter['join'] = join;
            }
            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Transaction
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseTransactionsControllerTransaction(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneBaseTransactionsControllerTransaction.');
            }
            const localVarPath = `/api/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS["csv"]);
            }
            if (join) {
                localVarQueryParameter['join'] = join;
            }
            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck(options: any = {}): FetchArgs {
            const localVarPath = `/api/health`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateImportEmployeesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importControllerImportCreate(body: CreateImportEmployeesRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling importControllerImportCreate.');
            }
            const localVarPath = `/api/import/employees/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateImportEmployeesRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ImportEmployeesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importControllerImportSummary(body: ImportEmployeesRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling importControllerImportSummary.');
            }
            const localVarPath = `/api/import/employees/summary`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ImportEmployeesRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ImportEmployeesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importControllerImportValidation(body: ImportEmployeesRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling importControllerImportValidation.');
            }
            const localVarPath = `/api/import/employees/validate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ImportEmployeesRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteIntegrationBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerDeleteIntegration(body: DeleteIntegrationBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling integrationControllerDeleteIntegration.');
            }
            const localVarPath = `/api/integration`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeleteIntegrationBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerIntegrationsSync(options: any = {}): FetchArgs {
            const localVarPath = `/api/integration/sync`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateKomboIntegrationBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerKomboIntegration(body: CreateKomboIntegrationBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling integrationControllerKomboIntegration.');
            }
            const localVarPath = `/api/integration/kombo`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateKomboIntegrationBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KomboWebhookSyncFinished} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerKomboWebhookSyncFinished(body: KomboWebhookSyncFinished, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling integrationControllerKomboWebhookSyncFinished.');
            }
            const localVarPath = `/api/integration/kombo/webhook/sync-finished`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"KomboWebhookSyncFinished" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerRolleeIntegration(options: any = {}): FetchArgs {
            const localVarPath = `/api/integration/rollee`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SilaeCredentials} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerSilaeIntegration(body: SilaeCredentials, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling integrationControllerSilaeIntegration.');
            }
            const localVarPath = `/api/integration/silae`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SilaeCredentials" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RolleeWebhookAccount} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerWebhookAccount(body: RolleeWebhookAccount, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling integrationControllerWebhookAccount.');
            }
            const localVarPath = `/api/integration/rollee/webhook/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RolleeWebhookAccount" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        komboControllerCreateLink(options: any = {}): FetchArgs {
            const localVarPath = `/api/kombo/create-link`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerGetMaintenanceState(options: any = {}): FetchArgs {
            const localVarPath = `/api/me/maintenance`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Contract} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerUpdate(body: Contract, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling meControllerUpdate.');
            }
            const localVarPath = `/api/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Contract" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateLinkTokenBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeControllerCreateLinkToken(body: CreateLinkTokenBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling mergeControllerCreateLinkToken.');
            }
            const localVarPath = `/api/hris/link-token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateLinkTokenBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeControllerDeleteAccountToken(accountToken: string, options: any = {}): FetchArgs {
            // verify required parameter 'accountToken' is not null or undefined
            if (accountToken === null || accountToken === undefined) {
                throw new RequiredError('accountToken','Required parameter accountToken was null or undefined when calling mergeControllerDeleteAccountToken.');
            }
            const localVarPath = `/api/hris/account-token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            if (accountToken !== undefined) {
                localVarQueryParameter['accountToken'] = accountToken;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeControllerGetAccountToken(publicToken: string, options: any = {}): FetchArgs {
            // verify required parameter 'publicToken' is not null or undefined
            if (publicToken === null || publicToken === undefined) {
                throw new RequiredError('publicToken','Required parameter publicToken was null or undefined when calling mergeControllerGetAccountToken.');
            }
            const localVarPath = `/api/hris/account-token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            if (publicToken !== undefined) {
                localVarQueryParameter['publicToken'] = publicToken;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeControllerGetEmployees(accountToken: string, options: any = {}): FetchArgs {
            // verify required parameter 'accountToken' is not null or undefined
            if (accountToken === null || accountToken === undefined) {
                throw new RequiredError('accountToken','Required parameter accountToken was null or undefined when calling mergeControllerGetEmployees.');
            }
            const localVarPath = `/api/hris/employees`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            if (accountToken !== undefined) {
                localVarQueryParameter['accountToken'] = accountToken;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateOTCBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oneTimeCodeControllerGenerateOTC(body: GenerateOTCBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling oneTimeCodeControllerGenerateOTC.');
            }
            const localVarPath = `/api/otc/generate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GenerateOTCBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyOTCBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oneTimeCodeControllerVerifyOTC(body: VerifyOTCBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling oneTimeCodeControllerVerifyOTC.');
            }
            const localVarPath = `/api/otc/verify`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"VerifyOTCBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerSendEmployeeInvites(options: any = {}): FetchArgs {
            const localVarPath = `/api/organization/invite-employees`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendPowerOfAttorneyReminderBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerSendPowerOfAttorneyReminder(body: SendPowerOfAttorneyReminderBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling organizationControllerSendPowerOfAttorneyReminder.');
            }
            const localVarPath = `/api/organization/power-of-attorney`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SendPowerOfAttorneyReminderBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganizationOnboarding} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdateOnboardingState(body: OrganizationOnboarding, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling organizationControllerUpdateOnboardingState.');
            }
            const localVarPath = `/api/organization/onboarding`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrganizationOnboarding" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganizationRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdateOne(body: OrganizationRequestDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling organizationControllerUpdateOne.');
            }
            const localVarPath = `/api/organization`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrganizationRequestDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolleeControllerGetAccount(options: any = {}): FetchArgs {
            const localVarPath = `/api/rollee/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageControllerTest(options: any = {}): FetchArgs {
            const localVarPath = `/api/storage/test/test-me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageControllerTestDownload(options: any = {}): FetchArgs {
            const localVarPath = `/api/storage/test/download`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageControllerTestUpload(options: any = {}): FetchArgs {
            const localVarPath = `/api/storage/test/upload`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerAddMandateToWallet(options: any = {}): FetchArgs {
            const localVarPath = `/api/banking/add-mandate-to-wallet`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} B_Authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerCreateAutoPayment(B_Authorization: string, options: any = {}): FetchArgs {
            // verify required parameter 'B_Authorization' is not null or undefined
            if (B_Authorization === null || B_Authorization === undefined) {
                throw new RequiredError('B_Authorization','Required parameter B_Authorization was null or undefined when calling swanControllerCreateAutoPayment.');
            }
            const localVarPath = `/api/banking/user/auto-payment`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            if (B_Authorization !== undefined && B_Authorization !== null) {
                localVarHeaderParameter['B-Authorization'] = String(B_Authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreatePaymentBody} body 
         * @param {string} B_Authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerCreatePayment(body: CreatePaymentBody, B_Authorization: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling swanControllerCreatePayment.');
            }
            // verify required parameter 'B_Authorization' is not null or undefined
            if (B_Authorization === null || B_Authorization === undefined) {
                throw new RequiredError('B_Authorization','Required parameter B_Authorization was null or undefined when calling swanControllerCreatePayment.');
            }
            const localVarPath = `/api/banking/user/payment`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            if (B_Authorization !== undefined && B_Authorization !== null) {
                localVarHeaderParameter['B-Authorization'] = String(B_Authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreatePaymentBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetAccount(options: any = {}): FetchArgs {
            const localVarPath = `/api/banking/project/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetAccountStatementsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetAccountStatements(body: GetAccountStatementsBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling swanControllerGetAccountStatements.');
            }
            const localVarPath = `/api/banking/project/account-statements`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetAccountStatementsBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetAccountTransactionsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetAccountWithTransactions(body: GetAccountTransactionsBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling swanControllerGetAccountWithTransactions.');
            }
            const localVarPath = `/api/banking/project/account-transactions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetAccountTransactionsBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetAccountTransactionsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetExternalIdDuplicates(body: GetAccountTransactionsBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling swanControllerGetExternalIdDuplicates.');
            }
            const localVarPath = `/api/banking/project/account-transactions-duplicates`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetAccountTransactionsBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetOnboarding(options: any = {}): FetchArgs {
            const localVarPath = `/api/banking/project/onboarding`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetPaymentBody} body 
         * @param {string} B_Authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetPayment(body: GetPaymentBody, B_Authorization: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling swanControllerGetPayment.');
            }
            // verify required parameter 'B_Authorization' is not null or undefined
            if (B_Authorization === null || B_Authorization === undefined) {
                throw new RequiredError('B_Authorization','Required parameter B_Authorization was null or undefined when calling swanControllerGetPayment.');
            }
            const localVarPath = `/api/banking/user/payment`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            if (B_Authorization !== undefined && B_Authorization !== null) {
                localVarHeaderParameter['B-Authorization'] = String(B_Authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetPaymentBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserTokenFromCodeBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetUserTokenFromCode(body: CreateUserTokenFromCodeBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling swanControllerGetUserTokenFromCode.');
            }
            const localVarPath = `/api/banking/user/token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateUserTokenFromCodeBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserTokenFromRefreshTokenBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetUserTokenFromRefreshToken(body: CreateUserTokenFromRefreshTokenBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling swanControllerGetUserTokenFromRefreshToken.');
            }
            const localVarPath = `/api/banking/user/refresh-token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateUserTokenFromRefreshTokenBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SwanWebhookData} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerSwanWebhookProcessor(body: SwanWebhookData, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling swanControllerSwanWebhookProcessor.');
            }
            const localVarPath = `/api/banking/webhook`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SwanWebhookData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateSwanConsentLinkBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerTestSwanToken(body: GenerateSwanConsentLinkBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling swanControllerTestSwanToken.');
            }
            const localVarPath = `/api/banking/generate-swan-consent-link`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GenerateSwanConsentLinkBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetConnectionUrlBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toutesMesAidesControllerGetConnectionUrl(body: GetConnectionUrlBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling toutesMesAidesControllerGetConnectionUrl.');
            }
            const localVarPath = `/api/subsidies/connection_url`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetConnectionUrlBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsControllerCancel(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling transactionsControllerCancel.');
            }
            const localVarPath = `/api/transactions/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddNonInvitedUserToSheetBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAddNonInvitedUserToSheet(body: AddNonInvitedUserToSheetBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userControllerAddNonInvitedUserToSheet.');
            }
            const localVarPath = `/api/user/non-invited-signup`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddNonInvitedUserToSheetBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerEnrollWithAuthenticatorApp(options: any = {}): FetchArgs {
            const localVarPath = `/api/user/enroll-authenticator-app-mfa`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUnenrollFromMfa(options: any = {}): FetchArgs {
            const localVarPath = `/api/user/unenroll-mfa`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyUserEnrolledWithAuthenticatorAppBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerVerifyEnrolledWithAuthenticatorApp(body: VerifyUserEnrolledWithAuthenticatorAppBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userControllerVerifyEnrolledWithAuthenticatorApp.');
            }
            const localVarPath = `/api/user/verify-authenticator-app-mfa`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"VerifyUserEnrolledWithAuthenticatorAppBody" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webflowControllerBlogPosts(options: any = {}): FetchArgs {
            const localVarPath = `/api/webflow/posts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearer required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("bearer", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (configuration && configuration.organizationId) {
                localVarHeaderParameter["Organization"] = configuration.organizationId;
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateTransactionFixturesBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerCreateFakeTransaction(body: CreateTransactionFixturesBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).adminControllerCreateFakeTransaction(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateUserFixturesBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerCreateFakeUsers(body: CreateUserFixturesBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).adminControllerCreateFakeUsers(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateOrganization} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerCreateOrganization(body: CreateOrganization, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreateOrganizationResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).adminControllerCreateOrganization(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DeleteAdminUserBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerDeleteAdmin(body: DeleteAdminUserBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Contract> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).adminControllerDeleteAdmin(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DeleteAdminUserBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerRemoveAdminRole(body: DeleteAdminUserBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Contract> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).adminControllerRemoveAdminRole(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerSendEmployeeInvites(organizationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).adminControllerSendEmployeeInvites(organizationId, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UpsertAdminUserBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerUpsertAdmin(body: UpsertAdminUserBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Contract> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).adminControllerUpsertAdmin(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerGetAdminAdvancesExport(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).advancesControllerGetAdminAdvancesExport(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {TransactionExportBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerGetAdvancesExport(body: TransactionExportBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<any>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).advancesControllerGetAdvancesExport(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AdvanceRequestBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerRequestAdvance(body: AdvanceRequestBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdvanceRequestResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).advancesControllerRequestAdvance(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {TransactionSyncBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerSyncNesspayTransactionsWithSwanTransactions(body: TransactionSyncBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).advancesControllerSyncNesspayTransactionsWithSwanTransactions(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AnalyticsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsControllerGetAnalytics(body: AnalyticsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AnalyticsResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).analyticsControllerGetAnalytics(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ChangeOrganizationBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerChangeOrganization(body: ChangeOrganizationBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).authControllerChangeOrganization(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {GenerateCodeBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGenerateCode(body: GenerateCodeBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).authControllerGenerateCode(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RefreshTokenBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshToken(body: RefreshTokenBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).authControllerRefreshToken(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ValidateCodeBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerValidateCode(body: ValidateCodeBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).authControllerValidateCode(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        axeptioControllerGetConsents(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AxeptioVendorsResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).axeptioControllerGetConsents(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AddSalaryRefundBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerAddRefund(body: AddSalaryRefundBody, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Contract> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).contractControllerAddRefund(body, id, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateContractBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerCreateOne(body: CreateContractBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).contractControllerCreateOne(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerDeactivateContract(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Contract> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).contractControllerDeactivateContract(id, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerDeleteContract(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Contract> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).contractControllerDeleteContract(id, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerGetBalance(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContractBalance> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).contractControllerGetBalance(id, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerGetEntityNames(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).contractControllerGetEntityNames(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerRemoveAdminRole(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Contract> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).contractControllerRemoveAdminRole(id, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DeleteRolesContractBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerRemoveRoles(body: DeleteRolesContractBody, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Contract> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).contractControllerRemoveRoles(body, id, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerSyncUsersWithIntercomUsers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).contractControllerSyncUsersWithIntercomUsers(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ToggleAdvanceRightsBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerToggleAdvanceRights(body: ToggleAdvanceRightsBody, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Contract> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).contractControllerToggleAdvanceRights(body, id, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UpdateEmailBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerUpdateEmail(body: UpdateEmailBody, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).contractControllerUpdateEmail(body, id, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UpdateContractBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerUpdateOne(body: UpdateContractBody, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Contract> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).contractControllerUpdateOne(body, id, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UpdateRolesContractBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerUpdateRoles(body: UpdateRolesContractBody, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Contract> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).contractControllerUpdateRoles(body, id, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete a single Integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseIntegrationControllerIntegration(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).deleteOneBaseIntegrationControllerIntegration(id, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateBorrowerBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerCreateBorrower(body: CreateBorrowerBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FinancingBorrower> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).financingControllerCreateBorrower(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerDeactivateBorrower(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).financingControllerDeactivateBorrower(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerGetBills(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FinancingBillPage> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).financingControllerGetBills(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerGetBorrower(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FinancingBorrower> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).financingControllerGetBorrower(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerGetCreditLines(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FinancingCreditLine>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).financingControllerGetCreditLines(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PaginationBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerGetLoans(body: PaginationBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FinancingLoanPage> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).financingControllerGetLoans(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PaginationBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerGetLoansPayments(body: PaginationBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FinancingPaymentsPage> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).financingControllerGetLoansPayments(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RequestLoanBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerRequestLoan(body: RequestLoanBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FinancingLoan> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).financingControllerRequestLoan(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SignTermsAndConditionsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerSignTermsAndConditions(body: SignTermsAndConditionsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FinancingBorrower> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).financingControllerSignTermsAndConditions(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve multiple ActivityLogs
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseActivityLogControllerActivityLog(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetManyActivityLogResponseDto> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getManyBaseActivityLogControllerActivityLog(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve multiple Contracts
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseContractControllerContract(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetManyContractResponseDto> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getManyBaseContractControllerContract(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve multiple Integrations
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseIntegrationControllerIntegration(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetManyIntegrationResponseDto> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getManyBaseIntegrationControllerIntegration(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve multiple Organizations
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseOrganizationControllerOrganization(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetManyOrganizationResponseDto> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getManyBaseOrganizationControllerOrganization(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve multiple Transactions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseTransactionsControllerTransaction(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetManyTransactionResponseDto> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getManyBaseTransactionsControllerTransaction(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve a single ActivityLog
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseActivityLogControllerActivityLog(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ActivityLog> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getOneBaseActivityLogControllerActivityLog(id, fields, join, cache, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve a single Contract
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseContractControllerContract(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Contract> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getOneBaseContractControllerContract(id, fields, join, cache, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve a single Integration
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseIntegrationControllerIntegration(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Integration> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getOneBaseIntegrationControllerIntegration(id, fields, join, cache, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve a single Organization
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseOrganizationControllerOrganization(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getOneBaseOrganizationControllerOrganization(id, fields, join, cache, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Retrieve a single Transaction
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseTransactionsControllerTransaction(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Transaction> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).getOneBaseTransactionsControllerTransaction(id, fields, join, cache, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).healthControllerCheck(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateImportEmployeesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importControllerImportCreate(body: CreateImportEmployeesRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreateImportEmployeesResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).importControllerImportCreate(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ImportEmployeesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importControllerImportSummary(body: ImportEmployeesRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ImportEmployeesSummaryResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).importControllerImportSummary(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ImportEmployeesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importControllerImportValidation(body: ImportEmployeesRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ImportEmployeesValidateResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).importControllerImportValidation(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DeleteIntegrationBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerDeleteIntegration(body: DeleteIntegrationBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Integration> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).integrationControllerDeleteIntegration(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerIntegrationsSync(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).integrationControllerIntegrationsSync(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateKomboIntegrationBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerKomboIntegration(body: CreateKomboIntegrationBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Integration> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).integrationControllerKomboIntegration(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {KomboWebhookSyncFinished} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerKomboWebhookSyncFinished(body: KomboWebhookSyncFinished, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Integration> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).integrationControllerKomboWebhookSyncFinished(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerRolleeIntegration(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Integration> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).integrationControllerRolleeIntegration(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SilaeCredentials} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerSilaeIntegration(body: SilaeCredentials, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Integration> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).integrationControllerSilaeIntegration(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RolleeWebhookAccount} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerWebhookAccount(body: RolleeWebhookAccount, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Integration> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).integrationControllerWebhookAccount(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        komboControllerCreateLink(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KomboCreateLinkResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).komboControllerCreateLink(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Contract> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).meControllerGet(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerGetMaintenanceState(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).meControllerGetMaintenanceState(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Contract} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerUpdate(body: Contract, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Contract> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).meControllerUpdate(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateLinkTokenBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeControllerCreateLinkToken(body: CreateLinkTokenBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreateLinkTokenResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).mergeControllerCreateLinkToken(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} accountToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeControllerDeleteAccountToken(accountToken: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).mergeControllerDeleteAccountToken(accountToken, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} publicToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeControllerGetAccountToken(publicToken: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetAccountTokenResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).mergeControllerGetAccountToken(publicToken, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} accountToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeControllerGetEmployees(accountToken: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).mergeControllerGetEmployees(accountToken, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {GenerateOTCBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oneTimeCodeControllerGenerateOTC(body: GenerateOTCBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GenerateOTCResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).oneTimeCodeControllerGenerateOTC(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {VerifyOTCBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oneTimeCodeControllerVerifyOTC(body: VerifyOTCBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VerifyOTCResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).oneTimeCodeControllerVerifyOTC(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerSendEmployeeInvites(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).organizationControllerSendEmployeeInvites(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SendPowerOfAttorneyReminderBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerSendPowerOfAttorneyReminder(body: SendPowerOfAttorneyReminderBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).organizationControllerSendPowerOfAttorneyReminder(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {OrganizationOnboarding} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdateOnboardingState(body: OrganizationOnboarding, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).organizationControllerUpdateOnboardingState(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {OrganizationRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdateOne(body: OrganizationRequestDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).organizationControllerUpdateOne(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolleeControllerGetAccount(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RolleeAccountResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).rolleeControllerGetAccount(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageControllerTest(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).storageControllerTest(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageControllerTestDownload(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).storageControllerTestDownload(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageControllerTestUpload(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).storageControllerTestUpload(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerAddMandateToWallet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).swanControllerAddMandateToWallet(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} B_Authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerCreateAutoPayment(B_Authorization: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreditTransfersResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).swanControllerCreateAutoPayment(B_Authorization, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreatePaymentBody} body 
         * @param {string} B_Authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerCreatePayment(body: CreatePaymentBody, B_Authorization: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreditTransfersResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).swanControllerCreatePayment(body, B_Authorization, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetAccount(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetAccountResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).swanControllerGetAccount(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {GetAccountStatementsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetAccountStatements(body: GetAccountStatementsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).swanControllerGetAccountStatements(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {GetAccountTransactionsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetAccountWithTransactions(body: GetAccountTransactionsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetAccountWithTransactionsResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).swanControllerGetAccountWithTransactions(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {GetAccountTransactionsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetExternalIdDuplicates(body: GetAccountTransactionsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SwanTransactionsDuplicates> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).swanControllerGetExternalIdDuplicates(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetOnboarding(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OnboardingResponseDto> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).swanControllerGetOnboarding(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {GetPaymentBody} body 
         * @param {string} B_Authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetPayment(body: GetPaymentBody, B_Authorization: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentQueryResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).swanControllerGetPayment(body, B_Authorization, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateUserTokenFromCodeBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetUserTokenFromCode(body: CreateUserTokenFromCodeBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserOAuthResponseBody> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).swanControllerGetUserTokenFromCode(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateUserTokenFromRefreshTokenBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetUserTokenFromRefreshToken(body: CreateUserTokenFromRefreshTokenBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserOAuthResponseBody> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).swanControllerGetUserTokenFromRefreshToken(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {SwanWebhookData} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerSwanWebhookProcessor(body: SwanWebhookData, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).swanControllerSwanWebhookProcessor(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {GenerateSwanConsentLinkBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerTestSwanToken(body: GenerateSwanConsentLinkBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).swanControllerTestSwanToken(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {GetConnectionUrlBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toutesMesAidesControllerGetConnectionUrl(body: GetConnectionUrlBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetConnectionUrlResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).toutesMesAidesControllerGetConnectionUrl(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsControllerCancel(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Transaction> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).transactionsControllerCancel(id, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AddNonInvitedUserToSheetBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAddNonInvitedUserToSheet(body: AddNonInvitedUserToSheetBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AddNonInvitedUserToSheetResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).userControllerAddNonInvitedUserToSheet(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerEnrollWithAuthenticatorApp(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EnrollUserWithAuthenticatorAppResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).userControllerEnrollWithAuthenticatorApp(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUnenrollFromMfa(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).userControllerUnenrollFromMfa(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {VerifyUserEnrolledWithAuthenticatorAppBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerVerifyEnrolledWithAuthenticatorApp(body: VerifyUserEnrolledWithAuthenticatorAppBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VerifyUserEnrolledWithAuthenticatorAppResponse> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).userControllerVerifyEnrolledWithAuthenticatorApp(body, options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webflowControllerBlogPosts(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WebflowBlogPost>> {
            const localVarFetchArgs = DefaultApiFetchParamCreator(configuration).webflowControllerBlogPosts(options);
            return (fetch: FetchAPI = fetchAPI, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {CreateTransactionFixturesBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerCreateFakeTransaction(body: CreateTransactionFixturesBody, options?: any) {
            return DefaultApiFp(configuration).adminControllerCreateFakeTransaction(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateUserFixturesBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerCreateFakeUsers(body: CreateUserFixturesBody, options?: any) {
            return DefaultApiFp(configuration).adminControllerCreateFakeUsers(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateOrganization} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerCreateOrganization(body: CreateOrganization, options?: any) {
            return DefaultApiFp(configuration).adminControllerCreateOrganization(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DeleteAdminUserBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerDeleteAdmin(body: DeleteAdminUserBody, options?: any) {
            return DefaultApiFp(configuration).adminControllerDeleteAdmin(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DeleteAdminUserBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerRemoveAdminRole(body: DeleteAdminUserBody, options?: any) {
            return DefaultApiFp(configuration).adminControllerRemoveAdminRole(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerSendEmployeeInvites(organizationId: string, options?: any) {
            return DefaultApiFp(configuration).adminControllerSendEmployeeInvites(organizationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {UpsertAdminUserBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerUpsertAdmin(body: UpsertAdminUserBody, options?: any) {
            return DefaultApiFp(configuration).adminControllerUpsertAdmin(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerGetAdminAdvancesExport(options?: any) {
            return DefaultApiFp(configuration).advancesControllerGetAdminAdvancesExport(options)(fetch, basePath);
        },
        /**
         * 
         * @param {TransactionExportBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerGetAdvancesExport(body: TransactionExportBody, options?: any) {
            return DefaultApiFp(configuration).advancesControllerGetAdvancesExport(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AdvanceRequestBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerRequestAdvance(body: AdvanceRequestBody, options?: any) {
            return DefaultApiFp(configuration).advancesControllerRequestAdvance(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {TransactionSyncBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancesControllerSyncNesspayTransactionsWithSwanTransactions(body: TransactionSyncBody, options?: any) {
            return DefaultApiFp(configuration).advancesControllerSyncNesspayTransactionsWithSwanTransactions(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AnalyticsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyticsControllerGetAnalytics(body: AnalyticsBody, options?: any) {
            return DefaultApiFp(configuration).analyticsControllerGetAnalytics(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ChangeOrganizationBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerChangeOrganization(body: ChangeOrganizationBody, options?: any) {
            return DefaultApiFp(configuration).authControllerChangeOrganization(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {GenerateCodeBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGenerateCode(body: GenerateCodeBody, options?: any) {
            return DefaultApiFp(configuration).authControllerGenerateCode(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {RefreshTokenBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshToken(body: RefreshTokenBody, options?: any) {
            return DefaultApiFp(configuration).authControllerRefreshToken(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ValidateCodeBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerValidateCode(body: ValidateCodeBody, options?: any) {
            return DefaultApiFp(configuration).authControllerValidateCode(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        axeptioControllerGetConsents(options?: any) {
            return DefaultApiFp(configuration).axeptioControllerGetConsents(options)(fetch, basePath);
        },
        /**
         * 
         * @param {AddSalaryRefundBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerAddRefund(body: AddSalaryRefundBody, id: string, options?: any) {
            return DefaultApiFp(configuration).contractControllerAddRefund(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateContractBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerCreateOne(body: CreateContractBody, options?: any) {
            return DefaultApiFp(configuration).contractControllerCreateOne(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerDeactivateContract(id: string, options?: any) {
            return DefaultApiFp(configuration).contractControllerDeactivateContract(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerDeleteContract(id: string, options?: any) {
            return DefaultApiFp(configuration).contractControllerDeleteContract(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerGetBalance(id: string, options?: any) {
            return DefaultApiFp(configuration).contractControllerGetBalance(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerGetEntityNames(options?: any) {
            return DefaultApiFp(configuration).contractControllerGetEntityNames(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerRemoveAdminRole(id: string, options?: any) {
            return DefaultApiFp(configuration).contractControllerRemoveAdminRole(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DeleteRolesContractBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerRemoveRoles(body: DeleteRolesContractBody, id: string, options?: any) {
            return DefaultApiFp(configuration).contractControllerRemoveRoles(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerSyncUsersWithIntercomUsers(options?: any) {
            return DefaultApiFp(configuration).contractControllerSyncUsersWithIntercomUsers(options)(fetch, basePath);
        },
        /**
         * 
         * @param {ToggleAdvanceRightsBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerToggleAdvanceRights(body: ToggleAdvanceRightsBody, id: string, options?: any) {
            return DefaultApiFp(configuration).contractControllerToggleAdvanceRights(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {UpdateEmailBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerUpdateEmail(body: UpdateEmailBody, id: string, options?: any) {
            return DefaultApiFp(configuration).contractControllerUpdateEmail(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {UpdateContractBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerUpdateOne(body: UpdateContractBody, id: string, options?: any) {
            return DefaultApiFp(configuration).contractControllerUpdateOne(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {UpdateRolesContractBody} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractControllerUpdateRoles(body: UpdateRolesContractBody, id: string, options?: any) {
            return DefaultApiFp(configuration).contractControllerUpdateRoles(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete a single Integration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseIntegrationControllerIntegration(id: string, options?: any) {
            return DefaultApiFp(configuration).deleteOneBaseIntegrationControllerIntegration(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateBorrowerBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerCreateBorrower(body: CreateBorrowerBody, options?: any) {
            return DefaultApiFp(configuration).financingControllerCreateBorrower(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerDeactivateBorrower(options?: any) {
            return DefaultApiFp(configuration).financingControllerDeactivateBorrower(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerGetBills(options?: any) {
            return DefaultApiFp(configuration).financingControllerGetBills(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerGetBorrower(options?: any) {
            return DefaultApiFp(configuration).financingControllerGetBorrower(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerGetCreditLines(options?: any) {
            return DefaultApiFp(configuration).financingControllerGetCreditLines(options)(fetch, basePath);
        },
        /**
         * 
         * @param {PaginationBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerGetLoans(body: PaginationBody, options?: any) {
            return DefaultApiFp(configuration).financingControllerGetLoans(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {PaginationBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerGetLoansPayments(body: PaginationBody, options?: any) {
            return DefaultApiFp(configuration).financingControllerGetLoansPayments(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {RequestLoanBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerRequestLoan(body: RequestLoanBody, options?: any) {
            return DefaultApiFp(configuration).financingControllerRequestLoan(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {SignTermsAndConditionsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financingControllerSignTermsAndConditions(body: SignTermsAndConditionsBody, options?: any) {
            return DefaultApiFp(configuration).financingControllerSignTermsAndConditions(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve multiple ActivityLogs
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseActivityLogControllerActivityLog(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
            return DefaultApiFp(configuration).getManyBaseActivityLogControllerActivityLog(fields, s, filter, or, sort, join, limit, offset, page, cache, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve multiple Contracts
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseContractControllerContract(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
            return DefaultApiFp(configuration).getManyBaseContractControllerContract(fields, s, filter, or, sort, join, limit, offset, page, cache, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve multiple Integrations
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseIntegrationControllerIntegration(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
            return DefaultApiFp(configuration).getManyBaseIntegrationControllerIntegration(fields, s, filter, or, sort, join, limit, offset, page, cache, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve multiple Organizations
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseOrganizationControllerOrganization(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
            return DefaultApiFp(configuration).getManyBaseOrganizationControllerOrganization(fields, s, filter, or, sort, join, limit, offset, page, cache, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve multiple Transactions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseTransactionsControllerTransaction(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
            return DefaultApiFp(configuration).getManyBaseTransactionsControllerTransaction(fields, s, filter, or, sort, join, limit, offset, page, cache, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve a single ActivityLog
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseActivityLogControllerActivityLog(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
            return DefaultApiFp(configuration).getOneBaseActivityLogControllerActivityLog(id, fields, join, cache, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve a single Contract
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseContractControllerContract(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
            return DefaultApiFp(configuration).getOneBaseContractControllerContract(id, fields, join, cache, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve a single Integration
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseIntegrationControllerIntegration(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
            return DefaultApiFp(configuration).getOneBaseIntegrationControllerIntegration(id, fields, join, cache, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve a single Organization
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseOrganizationControllerOrganization(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
            return DefaultApiFp(configuration).getOneBaseOrganizationControllerOrganization(id, fields, join, cache, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Retrieve a single Transaction
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseTransactionsControllerTransaction(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
            return DefaultApiFp(configuration).getOneBaseTransactionsControllerTransaction(id, fields, join, cache, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck(options?: any) {
            return DefaultApiFp(configuration).healthControllerCheck(options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateImportEmployeesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importControllerImportCreate(body: CreateImportEmployeesRequest, options?: any) {
            return DefaultApiFp(configuration).importControllerImportCreate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ImportEmployeesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importControllerImportSummary(body: ImportEmployeesRequest, options?: any) {
            return DefaultApiFp(configuration).importControllerImportSummary(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ImportEmployeesRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importControllerImportValidation(body: ImportEmployeesRequest, options?: any) {
            return DefaultApiFp(configuration).importControllerImportValidation(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DeleteIntegrationBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerDeleteIntegration(body: DeleteIntegrationBody, options?: any) {
            return DefaultApiFp(configuration).integrationControllerDeleteIntegration(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerIntegrationsSync(options?: any) {
            return DefaultApiFp(configuration).integrationControllerIntegrationsSync(options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateKomboIntegrationBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerKomboIntegration(body: CreateKomboIntegrationBody, options?: any) {
            return DefaultApiFp(configuration).integrationControllerKomboIntegration(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {KomboWebhookSyncFinished} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerKomboWebhookSyncFinished(body: KomboWebhookSyncFinished, options?: any) {
            return DefaultApiFp(configuration).integrationControllerKomboWebhookSyncFinished(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerRolleeIntegration(options?: any) {
            return DefaultApiFp(configuration).integrationControllerRolleeIntegration(options)(fetch, basePath);
        },
        /**
         * 
         * @param {SilaeCredentials} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerSilaeIntegration(body: SilaeCredentials, options?: any) {
            return DefaultApiFp(configuration).integrationControllerSilaeIntegration(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {RolleeWebhookAccount} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationControllerWebhookAccount(body: RolleeWebhookAccount, options?: any) {
            return DefaultApiFp(configuration).integrationControllerWebhookAccount(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        komboControllerCreateLink(options?: any) {
            return DefaultApiFp(configuration).komboControllerCreateLink(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerGet(options?: any) {
            return DefaultApiFp(configuration).meControllerGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerGetMaintenanceState(options?: any) {
            return DefaultApiFp(configuration).meControllerGetMaintenanceState(options)(fetch, basePath);
        },
        /**
         * 
         * @param {Contract} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerUpdate(body: Contract, options?: any) {
            return DefaultApiFp(configuration).meControllerUpdate(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateLinkTokenBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeControllerCreateLinkToken(body: CreateLinkTokenBody, options?: any) {
            return DefaultApiFp(configuration).mergeControllerCreateLinkToken(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} accountToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeControllerDeleteAccountToken(accountToken: string, options?: any) {
            return DefaultApiFp(configuration).mergeControllerDeleteAccountToken(accountToken, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} publicToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeControllerGetAccountToken(publicToken: string, options?: any) {
            return DefaultApiFp(configuration).mergeControllerGetAccountToken(publicToken, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} accountToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeControllerGetEmployees(accountToken: string, options?: any) {
            return DefaultApiFp(configuration).mergeControllerGetEmployees(accountToken, options)(fetch, basePath);
        },
        /**
         * 
         * @param {GenerateOTCBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oneTimeCodeControllerGenerateOTC(body: GenerateOTCBody, options?: any) {
            return DefaultApiFp(configuration).oneTimeCodeControllerGenerateOTC(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {VerifyOTCBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oneTimeCodeControllerVerifyOTC(body: VerifyOTCBody, options?: any) {
            return DefaultApiFp(configuration).oneTimeCodeControllerVerifyOTC(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerSendEmployeeInvites(options?: any) {
            return DefaultApiFp(configuration).organizationControllerSendEmployeeInvites(options)(fetch, basePath);
        },
        /**
         * 
         * @param {SendPowerOfAttorneyReminderBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerSendPowerOfAttorneyReminder(body: SendPowerOfAttorneyReminderBody, options?: any) {
            return DefaultApiFp(configuration).organizationControllerSendPowerOfAttorneyReminder(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {OrganizationOnboarding} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdateOnboardingState(body: OrganizationOnboarding, options?: any) {
            return DefaultApiFp(configuration).organizationControllerUpdateOnboardingState(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {OrganizationRequestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationControllerUpdateOne(body: OrganizationRequestDto, options?: any) {
            return DefaultApiFp(configuration).organizationControllerUpdateOne(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolleeControllerGetAccount(options?: any) {
            return DefaultApiFp(configuration).rolleeControllerGetAccount(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageControllerTest(options?: any) {
            return DefaultApiFp(configuration).storageControllerTest(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageControllerTestDownload(options?: any) {
            return DefaultApiFp(configuration).storageControllerTestDownload(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageControllerTestUpload(options?: any) {
            return DefaultApiFp(configuration).storageControllerTestUpload(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerAddMandateToWallet(options?: any) {
            return DefaultApiFp(configuration).swanControllerAddMandateToWallet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} B_Authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerCreateAutoPayment(B_Authorization: string, options?: any) {
            return DefaultApiFp(configuration).swanControllerCreateAutoPayment(B_Authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreatePaymentBody} body 
         * @param {string} B_Authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerCreatePayment(body: CreatePaymentBody, B_Authorization: string, options?: any) {
            return DefaultApiFp(configuration).swanControllerCreatePayment(body, B_Authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetAccount(options?: any) {
            return DefaultApiFp(configuration).swanControllerGetAccount(options)(fetch, basePath);
        },
        /**
         * 
         * @param {GetAccountStatementsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetAccountStatements(body: GetAccountStatementsBody, options?: any) {
            return DefaultApiFp(configuration).swanControllerGetAccountStatements(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {GetAccountTransactionsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetAccountWithTransactions(body: GetAccountTransactionsBody, options?: any) {
            return DefaultApiFp(configuration).swanControllerGetAccountWithTransactions(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {GetAccountTransactionsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetExternalIdDuplicates(body: GetAccountTransactionsBody, options?: any) {
            return DefaultApiFp(configuration).swanControllerGetExternalIdDuplicates(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetOnboarding(options?: any) {
            return DefaultApiFp(configuration).swanControllerGetOnboarding(options)(fetch, basePath);
        },
        /**
         * 
         * @param {GetPaymentBody} body 
         * @param {string} B_Authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetPayment(body: GetPaymentBody, B_Authorization: string, options?: any) {
            return DefaultApiFp(configuration).swanControllerGetPayment(body, B_Authorization, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateUserTokenFromCodeBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetUserTokenFromCode(body: CreateUserTokenFromCodeBody, options?: any) {
            return DefaultApiFp(configuration).swanControllerGetUserTokenFromCode(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateUserTokenFromRefreshTokenBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerGetUserTokenFromRefreshToken(body: CreateUserTokenFromRefreshTokenBody, options?: any) {
            return DefaultApiFp(configuration).swanControllerGetUserTokenFromRefreshToken(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {SwanWebhookData} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerSwanWebhookProcessor(body: SwanWebhookData, options?: any) {
            return DefaultApiFp(configuration).swanControllerSwanWebhookProcessor(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {GenerateSwanConsentLinkBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swanControllerTestSwanToken(body: GenerateSwanConsentLinkBody, options?: any) {
            return DefaultApiFp(configuration).swanControllerTestSwanToken(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {GetConnectionUrlBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toutesMesAidesControllerGetConnectionUrl(body: GetConnectionUrlBody, options?: any) {
            return DefaultApiFp(configuration).toutesMesAidesControllerGetConnectionUrl(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsControllerCancel(id: string, options?: any) {
            return DefaultApiFp(configuration).transactionsControllerCancel(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AddNonInvitedUserToSheetBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAddNonInvitedUserToSheet(body: AddNonInvitedUserToSheetBody, options?: any) {
            return DefaultApiFp(configuration).userControllerAddNonInvitedUserToSheet(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerEnrollWithAuthenticatorApp(options?: any) {
            return DefaultApiFp(configuration).userControllerEnrollWithAuthenticatorApp(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUnenrollFromMfa(options?: any) {
            return DefaultApiFp(configuration).userControllerUnenrollFromMfa(options)(fetch, basePath);
        },
        /**
         * 
         * @param {VerifyUserEnrolledWithAuthenticatorAppBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerVerifyEnrolledWithAuthenticatorApp(body: VerifyUserEnrolledWithAuthenticatorAppBody, options?: any) {
            return DefaultApiFp(configuration).userControllerVerifyEnrolledWithAuthenticatorApp(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webflowControllerBlogPosts(options?: any) {
            return DefaultApiFp(configuration).webflowControllerBlogPosts(options)(fetch, basePath);
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {CreateTransactionFixturesBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminControllerCreateFakeTransaction(body: CreateTransactionFixturesBody, options?: any) {
        return DefaultApiFp(this.configuration).adminControllerCreateFakeTransaction(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {CreateUserFixturesBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminControllerCreateFakeUsers(body: CreateUserFixturesBody, options?: any) {
        return DefaultApiFp(this.configuration).adminControllerCreateFakeUsers(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {CreateOrganization} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminControllerCreateOrganization(body: CreateOrganization, options?: any) {
        return DefaultApiFp(this.configuration).adminControllerCreateOrganization(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {DeleteAdminUserBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminControllerDeleteAdmin(body: DeleteAdminUserBody, options?: any) {
        return DefaultApiFp(this.configuration).adminControllerDeleteAdmin(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {DeleteAdminUserBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminControllerRemoveAdminRole(body: DeleteAdminUserBody, options?: any) {
        return DefaultApiFp(this.configuration).adminControllerRemoveAdminRole(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminControllerSendEmployeeInvites(organizationId: string, options?: any) {
        return DefaultApiFp(this.configuration).adminControllerSendEmployeeInvites(organizationId, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {UpsertAdminUserBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminControllerUpsertAdmin(body: UpsertAdminUserBody, options?: any) {
        return DefaultApiFp(this.configuration).adminControllerUpsertAdmin(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public advancesControllerGetAdminAdvancesExport(options?: any) {
        return DefaultApiFp(this.configuration).advancesControllerGetAdminAdvancesExport(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {TransactionExportBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public advancesControllerGetAdvancesExport(body: TransactionExportBody, options?: any) {
        return DefaultApiFp(this.configuration).advancesControllerGetAdvancesExport(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {AdvanceRequestBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public advancesControllerRequestAdvance(body: AdvanceRequestBody, options?: any) {
        return DefaultApiFp(this.configuration).advancesControllerRequestAdvance(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {TransactionSyncBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public advancesControllerSyncNesspayTransactionsWithSwanTransactions(body: TransactionSyncBody, options?: any) {
        return DefaultApiFp(this.configuration).advancesControllerSyncNesspayTransactionsWithSwanTransactions(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {AnalyticsBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public analyticsControllerGetAnalytics(body: AnalyticsBody, options?: any) {
        return DefaultApiFp(this.configuration).analyticsControllerGetAnalytics(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {ChangeOrganizationBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerChangeOrganization(body: ChangeOrganizationBody, options?: any) {
        return DefaultApiFp(this.configuration).authControllerChangeOrganization(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {GenerateCodeBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerGenerateCode(body: GenerateCodeBody, options?: any) {
        return DefaultApiFp(this.configuration).authControllerGenerateCode(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {RefreshTokenBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerRefreshToken(body: RefreshTokenBody, options?: any) {
        return DefaultApiFp(this.configuration).authControllerRefreshToken(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {ValidateCodeBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerValidateCode(body: ValidateCodeBody, options?: any) {
        return DefaultApiFp(this.configuration).authControllerValidateCode(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public axeptioControllerGetConsents(options?: any) {
        return DefaultApiFp(this.configuration).axeptioControllerGetConsents(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {AddSalaryRefundBody} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contractControllerAddRefund(body: AddSalaryRefundBody, id: string, options?: any) {
        return DefaultApiFp(this.configuration).contractControllerAddRefund(body, id, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {CreateContractBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contractControllerCreateOne(body: CreateContractBody, options?: any) {
        return DefaultApiFp(this.configuration).contractControllerCreateOne(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contractControllerDeactivateContract(id: string, options?: any) {
        return DefaultApiFp(this.configuration).contractControllerDeactivateContract(id, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contractControllerDeleteContract(id: string, options?: any) {
        return DefaultApiFp(this.configuration).contractControllerDeleteContract(id, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contractControllerGetBalance(id: string, options?: any) {
        return DefaultApiFp(this.configuration).contractControllerGetBalance(id, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contractControllerGetEntityNames(options?: any) {
        return DefaultApiFp(this.configuration).contractControllerGetEntityNames(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contractControllerRemoveAdminRole(id: string, options?: any) {
        return DefaultApiFp(this.configuration).contractControllerRemoveAdminRole(id, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {DeleteRolesContractBody} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contractControllerRemoveRoles(body: DeleteRolesContractBody, id: string, options?: any) {
        return DefaultApiFp(this.configuration).contractControllerRemoveRoles(body, id, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contractControllerSyncUsersWithIntercomUsers(options?: any) {
        return DefaultApiFp(this.configuration).contractControllerSyncUsersWithIntercomUsers(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {ToggleAdvanceRightsBody} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contractControllerToggleAdvanceRights(body: ToggleAdvanceRightsBody, id: string, options?: any) {
        return DefaultApiFp(this.configuration).contractControllerToggleAdvanceRights(body, id, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {UpdateEmailBody} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contractControllerUpdateEmail(body: UpdateEmailBody, id: string, options?: any) {
        return DefaultApiFp(this.configuration).contractControllerUpdateEmail(body, id, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {UpdateContractBody} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contractControllerUpdateOne(body: UpdateContractBody, id: string, options?: any) {
        return DefaultApiFp(this.configuration).contractControllerUpdateOne(body, id, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {UpdateRolesContractBody} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contractControllerUpdateRoles(body: UpdateRolesContractBody, id: string, options?: any) {
        return DefaultApiFp(this.configuration).contractControllerUpdateRoles(body, id, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @summary Delete a single Integration
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteOneBaseIntegrationControllerIntegration(id: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteOneBaseIntegrationControllerIntegration(id, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {CreateBorrowerBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public financingControllerCreateBorrower(body: CreateBorrowerBody, options?: any) {
        return DefaultApiFp(this.configuration).financingControllerCreateBorrower(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public financingControllerDeactivateBorrower(options?: any) {
        return DefaultApiFp(this.configuration).financingControllerDeactivateBorrower(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public financingControllerGetBills(options?: any) {
        return DefaultApiFp(this.configuration).financingControllerGetBills(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public financingControllerGetBorrower(options?: any) {
        return DefaultApiFp(this.configuration).financingControllerGetBorrower(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public financingControllerGetCreditLines(options?: any) {
        return DefaultApiFp(this.configuration).financingControllerGetCreditLines(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {PaginationBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public financingControllerGetLoans(body: PaginationBody, options?: any) {
        return DefaultApiFp(this.configuration).financingControllerGetLoans(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {PaginationBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public financingControllerGetLoansPayments(body: PaginationBody, options?: any) {
        return DefaultApiFp(this.configuration).financingControllerGetLoansPayments(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {RequestLoanBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public financingControllerRequestLoan(body: RequestLoanBody, options?: any) {
        return DefaultApiFp(this.configuration).financingControllerRequestLoan(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {SignTermsAndConditionsBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public financingControllerSignTermsAndConditions(body: SignTermsAndConditionsBody, options?: any) {
        return DefaultApiFp(this.configuration).financingControllerSignTermsAndConditions(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @summary Retrieve multiple ActivityLogs
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getManyBaseActivityLogControllerActivityLog(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return DefaultApiFp(this.configuration).getManyBaseActivityLogControllerActivityLog(fields, s, filter, or, sort, join, limit, offset, page, cache, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @summary Retrieve multiple Contracts
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getManyBaseContractControllerContract(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return DefaultApiFp(this.configuration).getManyBaseContractControllerContract(fields, s, filter, or, sort, join, limit, offset, page, cache, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @summary Retrieve multiple Integrations
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getManyBaseIntegrationControllerIntegration(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return DefaultApiFp(this.configuration).getManyBaseIntegrationControllerIntegration(fields, s, filter, or, sort, join, limit, offset, page, cache, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @summary Retrieve multiple Organizations
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getManyBaseOrganizationControllerOrganization(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return DefaultApiFp(this.configuration).getManyBaseOrganizationControllerOrganization(fields, s, filter, or, sort, join, limit, offset, page, cache, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @summary Retrieve multiple Transactions
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getManyBaseTransactionsControllerTransaction(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return DefaultApiFp(this.configuration).getManyBaseTransactionsControllerTransaction(fields, s, filter, or, sort, join, limit, offset, page, cache, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @summary Retrieve a single ActivityLog
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOneBaseActivityLogControllerActivityLog(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return DefaultApiFp(this.configuration).getOneBaseActivityLogControllerActivityLog(id, fields, join, cache, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @summary Retrieve a single Contract
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOneBaseContractControllerContract(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return DefaultApiFp(this.configuration).getOneBaseContractControllerContract(id, fields, join, cache, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @summary Retrieve a single Integration
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOneBaseIntegrationControllerIntegration(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return DefaultApiFp(this.configuration).getOneBaseIntegrationControllerIntegration(id, fields, join, cache, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @summary Retrieve a single Organization
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOneBaseOrganizationControllerOrganization(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return DefaultApiFp(this.configuration).getOneBaseOrganizationControllerOrganization(id, fields, join, cache, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @summary Retrieve a single Transaction
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOneBaseTransactionsControllerTransaction(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return DefaultApiFp(this.configuration).getOneBaseTransactionsControllerTransaction(id, fields, join, cache, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerCheck(options?: any) {
        return DefaultApiFp(this.configuration).healthControllerCheck(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {CreateImportEmployeesRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public importControllerImportCreate(body: CreateImportEmployeesRequest, options?: any) {
        return DefaultApiFp(this.configuration).importControllerImportCreate(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {ImportEmployeesRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public importControllerImportSummary(body: ImportEmployeesRequest, options?: any) {
        return DefaultApiFp(this.configuration).importControllerImportSummary(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {ImportEmployeesRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public importControllerImportValidation(body: ImportEmployeesRequest, options?: any) {
        return DefaultApiFp(this.configuration).importControllerImportValidation(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {DeleteIntegrationBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public integrationControllerDeleteIntegration(body: DeleteIntegrationBody, options?: any) {
        return DefaultApiFp(this.configuration).integrationControllerDeleteIntegration(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public integrationControllerIntegrationsSync(options?: any) {
        return DefaultApiFp(this.configuration).integrationControllerIntegrationsSync(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {CreateKomboIntegrationBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public integrationControllerKomboIntegration(body: CreateKomboIntegrationBody, options?: any) {
        return DefaultApiFp(this.configuration).integrationControllerKomboIntegration(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {KomboWebhookSyncFinished} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public integrationControllerKomboWebhookSyncFinished(body: KomboWebhookSyncFinished, options?: any) {
        return DefaultApiFp(this.configuration).integrationControllerKomboWebhookSyncFinished(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public integrationControllerRolleeIntegration(options?: any) {
        return DefaultApiFp(this.configuration).integrationControllerRolleeIntegration(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {SilaeCredentials} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public integrationControllerSilaeIntegration(body: SilaeCredentials, options?: any) {
        return DefaultApiFp(this.configuration).integrationControllerSilaeIntegration(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {RolleeWebhookAccount} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public integrationControllerWebhookAccount(body: RolleeWebhookAccount, options?: any) {
        return DefaultApiFp(this.configuration).integrationControllerWebhookAccount(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public komboControllerCreateLink(options?: any) {
        return DefaultApiFp(this.configuration).komboControllerCreateLink(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public meControllerGet(options?: any) {
        return DefaultApiFp(this.configuration).meControllerGet(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public meControllerGetMaintenanceState(options?: any) {
        return DefaultApiFp(this.configuration).meControllerGetMaintenanceState(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {Contract} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public meControllerUpdate(body: Contract, options?: any) {
        return DefaultApiFp(this.configuration).meControllerUpdate(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {CreateLinkTokenBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mergeControllerCreateLinkToken(body: CreateLinkTokenBody, options?: any) {
        return DefaultApiFp(this.configuration).mergeControllerCreateLinkToken(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} accountToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mergeControllerDeleteAccountToken(accountToken: string, options?: any) {
        return DefaultApiFp(this.configuration).mergeControllerDeleteAccountToken(accountToken, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} publicToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mergeControllerGetAccountToken(publicToken: string, options?: any) {
        return DefaultApiFp(this.configuration).mergeControllerGetAccountToken(publicToken, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} accountToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mergeControllerGetEmployees(accountToken: string, options?: any) {
        return DefaultApiFp(this.configuration).mergeControllerGetEmployees(accountToken, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {GenerateOTCBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public oneTimeCodeControllerGenerateOTC(body: GenerateOTCBody, options?: any) {
        return DefaultApiFp(this.configuration).oneTimeCodeControllerGenerateOTC(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {VerifyOTCBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public oneTimeCodeControllerVerifyOTC(body: VerifyOTCBody, options?: any) {
        return DefaultApiFp(this.configuration).oneTimeCodeControllerVerifyOTC(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public organizationControllerSendEmployeeInvites(options?: any) {
        return DefaultApiFp(this.configuration).organizationControllerSendEmployeeInvites(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {SendPowerOfAttorneyReminderBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public organizationControllerSendPowerOfAttorneyReminder(body: SendPowerOfAttorneyReminderBody, options?: any) {
        return DefaultApiFp(this.configuration).organizationControllerSendPowerOfAttorneyReminder(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {OrganizationOnboarding} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public organizationControllerUpdateOnboardingState(body: OrganizationOnboarding, options?: any) {
        return DefaultApiFp(this.configuration).organizationControllerUpdateOnboardingState(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {OrganizationRequestDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public organizationControllerUpdateOne(body: OrganizationRequestDto, options?: any) {
        return DefaultApiFp(this.configuration).organizationControllerUpdateOne(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rolleeControllerGetAccount(options?: any) {
        return DefaultApiFp(this.configuration).rolleeControllerGetAccount(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public storageControllerTest(options?: any) {
        return DefaultApiFp(this.configuration).storageControllerTest(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public storageControllerTestDownload(options?: any) {
        return DefaultApiFp(this.configuration).storageControllerTestDownload(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public storageControllerTestUpload(options?: any) {
        return DefaultApiFp(this.configuration).storageControllerTestUpload(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public swanControllerAddMandateToWallet(options?: any) {
        return DefaultApiFp(this.configuration).swanControllerAddMandateToWallet(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} B_Authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public swanControllerCreateAutoPayment(B_Authorization: string, options?: any) {
        return DefaultApiFp(this.configuration).swanControllerCreateAutoPayment(B_Authorization, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {CreatePaymentBody} body 
     * @param {string} B_Authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public swanControllerCreatePayment(body: CreatePaymentBody, B_Authorization: string, options?: any) {
        return DefaultApiFp(this.configuration).swanControllerCreatePayment(body, B_Authorization, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public swanControllerGetAccount(options?: any) {
        return DefaultApiFp(this.configuration).swanControllerGetAccount(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {GetAccountStatementsBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public swanControllerGetAccountStatements(body: GetAccountStatementsBody, options?: any) {
        return DefaultApiFp(this.configuration).swanControllerGetAccountStatements(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {GetAccountTransactionsBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public swanControllerGetAccountWithTransactions(body: GetAccountTransactionsBody, options?: any) {
        return DefaultApiFp(this.configuration).swanControllerGetAccountWithTransactions(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {GetAccountTransactionsBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public swanControllerGetExternalIdDuplicates(body: GetAccountTransactionsBody, options?: any) {
        return DefaultApiFp(this.configuration).swanControllerGetExternalIdDuplicates(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public swanControllerGetOnboarding(options?: any) {
        return DefaultApiFp(this.configuration).swanControllerGetOnboarding(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {GetPaymentBody} body 
     * @param {string} B_Authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public swanControllerGetPayment(body: GetPaymentBody, B_Authorization: string, options?: any) {
        return DefaultApiFp(this.configuration).swanControllerGetPayment(body, B_Authorization, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {CreateUserTokenFromCodeBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public swanControllerGetUserTokenFromCode(body: CreateUserTokenFromCodeBody, options?: any) {
        return DefaultApiFp(this.configuration).swanControllerGetUserTokenFromCode(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {CreateUserTokenFromRefreshTokenBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public swanControllerGetUserTokenFromRefreshToken(body: CreateUserTokenFromRefreshTokenBody, options?: any) {
        return DefaultApiFp(this.configuration).swanControllerGetUserTokenFromRefreshToken(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {SwanWebhookData} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public swanControllerSwanWebhookProcessor(body: SwanWebhookData, options?: any) {
        return DefaultApiFp(this.configuration).swanControllerSwanWebhookProcessor(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {GenerateSwanConsentLinkBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public swanControllerTestSwanToken(body: GenerateSwanConsentLinkBody, options?: any) {
        return DefaultApiFp(this.configuration).swanControllerTestSwanToken(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {GetConnectionUrlBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public toutesMesAidesControllerGetConnectionUrl(body: GetConnectionUrlBody, options?: any) {
        return DefaultApiFp(this.configuration).toutesMesAidesControllerGetConnectionUrl(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transactionsControllerCancel(id: string, options?: any) {
        return DefaultApiFp(this.configuration).transactionsControllerCancel(id, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {AddNonInvitedUserToSheetBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerAddNonInvitedUserToSheet(body: AddNonInvitedUserToSheetBody, options?: any) {
        return DefaultApiFp(this.configuration).userControllerAddNonInvitedUserToSheet(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerEnrollWithAuthenticatorApp(options?: any) {
        return DefaultApiFp(this.configuration).userControllerEnrollWithAuthenticatorApp(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerUnenrollFromMfa(options?: any) {
        return DefaultApiFp(this.configuration).userControllerUnenrollFromMfa(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {VerifyUserEnrolledWithAuthenticatorAppBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userControllerVerifyEnrolledWithAuthenticatorApp(body: VerifyUserEnrolledWithAuthenticatorAppBody, options?: any) {
        return DefaultApiFp(this.configuration).userControllerVerifyEnrolledWithAuthenticatorApp(body, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public webflowControllerBlogPosts(options?: any) {
        return DefaultApiFp(this.configuration).webflowControllerBlogPosts(options)(this.fetch, this.basePath);
    }
}
