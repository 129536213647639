import { Octicons, MaterialIcons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { Box, Center, Row, Text } from 'native-base'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useUserContext } from 'user/context'

import { Card } from './Card'

export const ProfileCard: React.FC = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const { contract } = useUserContext()
  function goToProfile() {
    navigate('TabNav', { screen: 'AccountNav', params: { screen: 'Profile' } })
  }
  return (
    <Card onPress={goToProfile}>
      <Row alignItems="flex-start">
        <Text fontSize="lg" fontWeight="medium" color="grey">
          {t('account.profile')}
        </Text>
      </Row>
      <Box h={3} />
      <Row alignItems="center" justifyContent="flex-start">
        <Center
          style={{ aspectRatio: 1 }}
          width="50px"
          borderColor="grey"
          borderRadius={50}
          borderWidth={1}
          marginRight={4}>
          <Octicons name="person" size={24} />
        </Center>
        <Row justifyContent="flex-start" alignItems="center">
          <Text fontSize="lg" fontWeight="medium" width={{ base: '75%' }}>
            {contract?.user?.name}
          </Text>
          <MaterialIcons name="keyboard-arrow-right" size={48} />
        </Row>
      </Row>
    </Card>
  )
}
