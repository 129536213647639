import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { Box, Center, VStack, Text, HStack, Pressable } from 'native-base'
import React, { useState, ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Transaction } from 'api/gen'
import { analytics } from 'services/analytics'
import { AnalyticsKey } from 'services/analytics/types'
import { IntercomContext } from 'services/chat/provider'

interface TransactionDetailsProps {
  transaction: Transaction
}

const dateFormat = "dd/MM/yy à HH'h'mm"

const statusColor = (transaction: Transaction): string => {
  switch (transaction.status) {
    case Transaction.StatusEnum.DONE:
      return 'lightGreen'
    case Transaction.StatusEnum.INPROGRESS:
      return 'lightYellow'
    case Transaction.StatusEnum.CANCEL:
    default:
      return 'lightRed'
  }
}

const TransactionDetails = ({ transaction }: TransactionDetailsProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const { openChat, setChatLoading } = useContext(IntercomContext)

  function openTransactionDetail() {
    analytics.log(AnalyticsKey.TOGGLE_TRANSACTION_DETAIL)
    setOpen(!open)
  }

  function handleOpenChat() {
    analytics.log(AnalyticsKey.OPEN_CHAT)
    setChatLoading(true)
    openChat()
  }

  const renderStatusDetails = (transaction: Transaction): ReactNode => {
    switch (transaction.status) {
      case Transaction.StatusEnum.DONE:
        return (
          <Text color="grey" fontSize="xs" mt="18px" mr="auto">
            {t('transaction.done')}{' '}
            {format(new Date(transaction.updatedAt), dateFormat, {
              locale: fr,
            })}
          </Text>
        )
      case Transaction.StatusEnum.CANCEL:
        return (
          <Text color="grey" fontSize="xs" mt="18px">
            {t('transaction.cancel')}{' '}
            {format(new Date(transaction.updatedAt), dateFormat, {
              locale: fr,
            })}
          </Text>
        )
      case Transaction.StatusEnum.INPROGRESS:
        return (
          <VStack w="100%">
            <Text color="grey" fontSize="sm" mt="18px">
              {t('transaction.inProgress')}
            </Text>
            <HStack w="100%" justifyContent="flex-start" mt="18px" mx={0} px={0}>
              <Pressable width="40%" onPress={handleOpenChat}>
                <Text color="infoBlue" fontSize="sm" mr="auto">
                  {t('transaction.helpButton')}
                </Text>
              </Pressable>
            </HStack>
          </VStack>
        )
      default:
        return <></>
    }
  }

  return (
    <VStack
      alignItems="center"
      borderBottomColor="#E1E3EC"
      borderBottomWidth="1px"
      w="100%"
      paddingY="18px">
      <Center flexDirection="row" w="100%">
        <Pressable onPress={openTransactionDetail} width="100%">
          <HStack width="100%" flexDirection="row" justifyContent="space-between">
            <Text fontSize="sm">
              {format(new Date(transaction.createdAt), 'dd MMMM yyyy', {
                locale: fr,
              })}
            </Text>
            <Box px="5px" borderRadius="6px" backgroundColor={statusColor(transaction)}>
              <Text fontSize="md">{transaction.amount}€</Text>
            </Box>
          </HStack>
        </Pressable>
      </Center>
      {open ? <VStack w="100%">{renderStatusDetails(transaction)}</VStack> : null}
    </VStack>
  )
}

export default TransactionDetails
