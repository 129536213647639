import * as React from 'react'
import Svg, { Path, G } from 'react-native-svg'

export const WelcomeTutorialPageOne = () => (
  <Svg width="251" height="247" fill="none">
    <Path
      d="M213.956 246.847c0 .082-40.798.153-91.115.153-50.316 0-91.12-.071-91.12-.153 0-.083 40.786-.153 91.12-.153s91.115.07 91.115.153Z"
      fill="#263238"
    />
    <Path
      d="M174.204 174.739c-9.078-6.356-11.134-18.16-10.776-28.762.059-1.773-.135-3.634.705-5.189.84-1.555 2.803-2.615 4.383-1.867 1.31.618 1.886 2.149 2.615 3.41a10.317 10.317 0 0 0 4.142 3.964c1.122.583 2.514.943 3.625.33 1.522-.842 1.704-2.951 1.692-4.712-.023-3.281-.041-6.562-.059-9.849-.011-1.761-.017-3.557.529-5.23.546-1.673 1.751-3.222 3.425-3.693 1.669-.472 3.737.494 4.09 2.22.146.719 0 1.461.047 2.191.047.731.387 1.538 1.081 1.744.699.206 1.404-.295 1.927-.807 1.815-1.779 3.043-4.07 4.195-6.344 1.151-2.273 2.268-4.612 3.913-6.55 1.645-1.938 3.919-3.475 6.433-3.616 2.515-.142 5.194 1.413 5.834 3.887.635 2.48-.804 5.001-2.379 7.01a39.303 39.303 0 0 1-8.161 7.798c-.552.395-1.151.819-1.363 1.473-.382 1.172.729 2.338 1.886 2.727 1.31.436 2.732.3 4.101.465 1.369.159 2.855.766 3.372 2.068.717 1.802-.781 3.687-2.262 4.924a33.7 33.7 0 0 1-9.941 5.755c-1.292.471-2.649.889-3.695 1.802-1.04.913-1.669 2.492-1.04 3.735.628 1.242 2.244 1.637 3.601 1.384 1.352-.254 2.568-.99 3.855-1.479 2.414-.913 5.528-.748 7.079 1.343 1.017 1.373 1.105 3.287.594 4.93-.511 1.638-1.557 3.051-2.662 4.353-3.866 4.553-8.819 8.217-14.424 10.161-5.599 1.944-10.787 2.474-16.351.436"
      fill="#06113F"
    />
    <Path
      d="M209.467 113.445s-.053.059-.17.165c-.129.118-.3.271-.523.466-.458.406-1.14 1.007-1.992 1.808a65.651 65.651 0 0 0-6.709 7.263c-1.305 1.631-2.668 3.481-4.09 5.489-1.416 2.015-2.92 4.176-4.418 6.509-1.492 2.332-2.99 4.83-4.336 7.528-.676 1.348-1.281 2.756-1.898 4.193-.611 1.438-1.233 2.893-1.862 4.365-2.497 5.908-4.689 11.616-6.257 16.946-1.581 5.331-2.515 10.267-2.926 14.449-.423 4.188-.335 7.604-.165 9.948.094 1.173.182 2.086.265 2.698.035.295.058.524.082.701.018.159.023.242.018.242 0 0-.024-.077-.047-.236-.03-.171-.065-.401-.112-.695-.106-.613-.212-1.52-.323-2.698-.212-2.344-.329-5.772.064-9.978.382-4.199 1.305-9.165 2.868-14.513 1.557-5.354 3.748-11.08 6.245-16.988.629-1.472 1.251-2.933 1.868-4.37.617-1.431 1.228-2.851 1.91-4.206 1.351-2.709 2.861-5.213 4.365-7.545 1.504-2.333 3.02-4.494 4.448-6.503 1.433-2.008 2.808-3.852 4.13-5.478a61.916 61.916 0 0 1 6.804-7.209 53.091 53.091 0 0 1 2.033-1.762c.229-.188.405-.329.54-.441.124-.1.188-.148.194-.142l-.006-.006Z"
      fill="#263238"
    />
    <Path
      d="M187.047 142.514s-.082-.265-.17-.754c-.094-.489-.2-1.196-.324-2.074-.246-1.755-.511-4.188-.787-6.868-.276-2.686-.57-5.107-.846-6.85-.135-.872-.252-1.579-.335-2.062-.082-.488-.117-.759-.1-.765.024 0 .1.259.224.736.123.477.276 1.178.44 2.05.335 1.743.664 4.17.94 6.856.265 2.651.506 5.048.694 6.874l.223 2.085c.053.489.07.766.047.772h-.006Z"
      fill="#263238"
    />
    <Path
      d="M209.632 138.284s-.323.059-.899.106c-.582.053-1.416.124-2.45.242-2.068.224-4.918.636-8.038 1.225a116.7 116.7 0 0 0-7.931 1.791c-1.011.259-1.822.494-2.38.654-.558.159-.875.235-.881.217-.006-.023.294-.141.846-.341a41.206 41.206 0 0 1 2.356-.754c2.003-.595 4.806-1.29 7.938-1.891 3.131-.589 5.992-.966 8.072-1.131a45.643 45.643 0 0 1 2.468-.141c.582-.012.905 0 .905.018l-.006.005ZM177.212 167.671s-.065-.089-.153-.277c-.106-.224-.229-.489-.388-.819a82.658 82.658 0 0 1-1.328-3.051c-1.086-2.592-2.479-6.226-3.965-10.249-1.487-4.029-2.838-7.669-3.872-10.278-.506-1.273-.923-2.315-1.234-3.093a51.91 51.91 0 0 1-.329-.842c-.071-.194-.106-.3-.094-.3.012 0 .065.088.153.276.105.224.229.489.387.819.335.713.788 1.755 1.328 3.051 1.087 2.592 2.48 6.226 3.966 10.255 1.481 4.029 2.838 7.663 3.872 10.278.505 1.273.922 2.315 1.234 3.093.135.336.241.618.329.842.07.195.106.301.094.301v-.006ZM207.223 157.975s-.1.053-.3.124c-.235.077-.523.177-.869.295-.805.259-1.892.612-3.214 1.042a968.984 968.984 0 0 0-10.57 3.546c-4.124 1.402-7.873 2.627-10.605 3.458-1.369.412-2.473.736-3.243.942-.358.094-.652.171-.887.236-.206.047-.311.07-.317.059 0-.012.1-.053.299-.124.235-.077.523-.177.87-.295.805-.259 1.892-.612 3.214-1.042a962.571 962.571 0 0 0 10.569-3.546c4.125-1.402 7.873-2.627 10.605-3.452 1.369-.412 2.474-.736 3.244-.942.358-.094.652-.171.887-.236.205-.053.311-.071.317-.059v-.006Z"
      fill="#263238"
    />
    <Path
      d="m194.979 15.09-3.026 1.084-1.293-3.964C188.275 4.9 180.655.99 173.681 3.487c-6.974 2.503-10.705 10.485-8.314 17.794l1.293 3.964-3.038 1.09c-2.362.848-3.643 3.575-2.832 6.055l8.425 25.794c.823 2.515 3.432 3.858 5.793 3.01l31.357-11.245c2.397-.86 3.678-3.599 2.855-6.114l-8.425-25.793c-.811-2.48-3.419-3.811-5.816-2.951Zm-23.901 4.142c-1.31-4.006.734-8.382 4.559-9.755 3.819-1.372 7.996.778 9.301 4.783l1.292 3.964-13.86 4.972-1.292-3.965Zm17.632 22.883 2.15 6.585a.534.534 0 0 1-.335.66l-3.008 1.078a.492.492 0 0 1-.617-.318l-2.15-6.585c-2.333-.1-4.495-1.65-5.306-4.124-1.04-3.186.588-6.667 3.637-7.763s6.369.613 7.409 3.8c.811 2.48 0 5.088-1.78 6.667Z"
      fill="#06113F"
    />
    <Path
      d="M191.818 16.11c.029.082-5.611 2.197-12.597 4.73-6.986 2.532-12.673 4.517-12.702 4.44-.03-.082 5.611-2.196 12.596-4.73 6.986-2.532 12.673-4.517 12.697-4.44h.006ZM198.48 17.194s.03.053.071.153c.047.117.1.27.17.46.153.43.359 1.024.629 1.784l2.256 6.585c.946 2.792 2.062 6.102 3.308 9.778.611 1.843 1.287 3.764 1.927 5.79.3 1.019.546 2.103.441 3.234a4.388 4.388 0 0 1-.476 1.631 4.488 4.488 0 0 1-1.111 1.314c-.905.724-1.962 1.148-2.984 1.525-1.029.371-2.039.73-3.032 1.09-1.986.713-3.901 1.402-5.729 2.056-3.648 1.301-6.932 2.474-9.7 3.463-2.749.972-4.982 1.755-6.551 2.31-.758.258-1.357.464-1.786.612-.194.064-.346.112-.464.153-.106.035-.159.047-.164.041 0 0 .047-.03.152-.07l.459-.183 1.768-.666 6.516-2.403c2.761-1.007 6.04-2.197 9.682-3.522l5.723-2.074 3.026-1.095c1.01-.377 2.044-.795 2.896-1.479a4.088 4.088 0 0 0 1.034-1.219c.253-.471.394-.99.441-1.514.1-1.06-.135-2.114-.423-3.116-.635-2.002-1.304-3.94-1.91-5.784-1.222-3.681-2.32-7.003-3.243-9.801-.905-2.774-1.645-5.03-2.162-6.615-.241-.766-.435-1.372-.576-1.802l-.141-.472c-.029-.106-.041-.165-.041-.165h-.006Z"
      fill="#263238"
    />
    <Path
      d="M181.935 9.07s-.311-.158-.887-.388a7.804 7.804 0 0 0-2.567-.518 8.664 8.664 0 0 0-3.784.718 8.422 8.422 0 0 0-3.59 3.01 7.982 7.982 0 0 0-1.38 4.471c-.012 1.473.299 2.78.599 3.852.311 1.078.628 1.926.834 2.521.212.595.323.92.306.93-.018.007-.171-.305-.418-.889-.24-.583-.593-1.425-.928-2.503-.329-1.078-.67-2.403-.676-3.917a8.138 8.138 0 0 1 1.411-4.641 8.643 8.643 0 0 1 3.73-3.099 8.645 8.645 0 0 1 3.907-.671 7.365 7.365 0 0 1 2.603.624c.282.142.5.254.641.348.141.088.211.141.205.153h-.006ZM182.206 32.12s.1-.06.299-.148c.2-.094.5-.212.905-.33a6.54 6.54 0 0 1 1.528-.253 5.257 5.257 0 0 1 2.062.306c1.498.53 2.885 2.068 3.566 4.106a5.902 5.902 0 0 1 .03 3.38 7.73 7.73 0 0 1-1.922 3.187l.042-.159c.781 2.144 1.627 4.483 2.514 6.915l.053.148-.147.047c-.423.141-.881.294-1.345.453-.987.33-1.968.66-2.926.984l-.147.047-.047-.147c-.805-2.445-1.686-4.742-2.327-6.945l.13.1c-1.205-.082-2.286-.589-3.144-1.248a7.077 7.077 0 0 1-1.974-2.362c-.44-.872-.658-1.797-.623-2.65a6.263 6.263 0 0 1 .494-2.275c.546-1.313 1.363-2.138 1.968-2.591.617-.454 1.017-.572 1.017-.572.011.024-.371.177-.964.642a6.273 6.273 0 0 0-1.868 2.58c-.511 1.26-.735 3.07.182 4.76a6.916 6.916 0 0 0 1.921 2.261c.834.63 1.868 1.102 3.002 1.173h.1l.03.1c.646 2.185 1.533 4.476 2.344 6.933l-.194-.095c.958-.324 1.933-.648 2.92-.983.458-.154.917-.307 1.346-.454l-.095.194c-.881-2.432-1.721-4.77-2.497-6.92l-.029-.089.071-.07a7.381 7.381 0 0 0 1.856-3.046 5.708 5.708 0 0 0 0-3.233c-.64-1.98-1.962-3.482-3.396-4.012a5.034 5.034 0 0 0-1.991-.335c-.594.023-1.105.094-1.505.206-.804.206-1.216.406-1.227.383l-.012.011Z"
      fill="#263238"
    />
    <Path
      d="m164.045 202.187-58.683-.271c-7.056-.029-12.755-5.796-12.726-12.87l.623-147.49c.03-7.08 5.781-12.794 12.844-12.765l58.682.271c7.057.036 12.756 5.796 12.726 12.87l-.622 147.491c-.03 7.08-5.782 12.794-12.844 12.758v.006Z"
      fill="#455A64"
    />
    <Path
      d="m164.703 34.157-11.38-.053c-1.528-.006-2.768 1.284-2.774 2.88l-.011 2.068c-.006 1.596-1.252 2.892-2.773 2.88l-22.18-.1c-1.527-.006-2.755-1.307-2.749-2.91l.011-2.067c.006-1.596-1.222-2.904-2.749-2.91l-4.001-.017-9.959-.047c-4.947-.024-8.972 3.981-8.995 8.935l-.605 143.963c-.024 4.953 3.965 8.988 8.912 9.012l58.566.27c4.947.024 8.971-3.975 8.995-8.935l.605-143.963c.024-4.953-3.966-8.988-8.913-9.012v.006Z"
      fill="#fff"
    />
    <Path
      d="m156.818 176.865-43.548-.2c-.452 0-.816-.495-.81-1.101l.047-11.422c0-.606.37-1.095.822-1.089l43.548.2c.453 0 .817.495.811 1.101l-.047 11.422c0 .606-.37 1.095-.823 1.089ZM121.954 76.455l-15.111-.069-.013 2.78 15.111.07.013-2.78Z"
      fill="#06113F"
    />
    <Path opacity={0.1} d="m121.954 76.455-15.111-.069-.013 2.78 15.111.07.013-2.78Z" fill="#000" />
    <Path
      d="M166.254 93.519s.124.006.347-.083a.93.93 0 0 0 .57-.795c0-.936 0-2.303-.006-4.058v-3.758c.006-.289.006-.554-.165-.772a.857.857 0 0 0-.693-.359h-.922c-2.497 0-5.347-.012-8.484-.018-12.585-.047-29.959-.112-49.141-.182-.253 0-.5.123-.647.33-.164.206-.158.453-.153.771 0 .595 0 1.196-.005 1.785 0 1.19-.012 2.368-.018 3.54 0 .583 0 1.172-.006 1.75.012.276-.029.612.024.83a.794.794 0 0 0 .458.53.908.908 0 0 0 .364.065h1.287c.57.011 1.14.011 1.704.017 4.524.024 8.889.047 13.049.071 8.313.053 15.799.094 22.091.135 6.281.048 11.369.089 14.894.118 1.751.018 3.114.036 4.048.047.452.006.805.018 1.052.018.235.006.358.018.358.018s-.123.012-.358.012c-.247 0-.6.005-1.052.011-.94 0-2.297 0-4.048.012-3.525 0-8.613-.012-14.894-.017-6.292-.018-13.778-.042-22.091-.071-4.16-.018-8.525-.035-13.049-.053-.564 0-1.134 0-1.704-.006h-1.287c-.147 0-.329-.024-.482-.094a1.11 1.11 0 0 1-.634-.73c-.047-.166-.035-.337-.035-.472v-.436c0-.583.005-1.166.011-1.75 0-1.171.012-2.35.012-3.54 0-.594 0-1.189.006-1.784 0-.153-.006-.289.018-.471a1.095 1.095 0 0 1 1.087-.93l49.141.27c3.143.024 5.987.041 8.49.065.311 0 .617 0 .922.006.329 0 .64.17.823.43.199.253.199.6.188.866 0 .276 0 .553-.006.818-.012 1.072-.018 2.05-.024 2.934-.023 1.755-.041 3.121-.047 4.064a.981.981 0 0 1-.617.819c-.229.082-.358.053-.358.053l-.018-.006Z"
      fill="#E0E0E0"
    />
    <Path d="m138.538 87.204-27.637-.126-.012 2.597 27.637.126.012-2.597Z" fill="#06113F" />
    <Path
      opacity={0.2}
      d="m138.538 87.204-27.637-.126-.012 2.597 27.637.126.012-2.597Z"
      fill="#fff"
    />
    <Path d="m121.851 101.818-15.111-.069-.013 2.78 15.111.069.013-2.78Z" fill="#06113F" />
    <Path
      opacity={0.1}
      d="m121.851 101.818-15.111-.069-.013 2.78 15.111.069.013-2.78Z"
      fill="#000"
    />
    <Path
      d="M165.866 118.882s.124 0 .347-.065a1.23 1.23 0 0 0 .746-.665c.1-.206.112-.46.1-.748V110.394c.012-.282-.023-.542-.17-.777a1.149 1.149 0 0 0-.641-.495c-.264-.082-.575-.035-.887-.053-2.491 0-5.323-.012-8.46-.018-12.544-.047-29.853-.112-48.971-.182-.529-.012-1.022.424-1.075.948-.018.56-.012 1.178-.018 1.761 0 1.184-.012 2.362-.018 3.528 0 .584 0 1.167-.005 1.744.011.289-.03.589.035.825.064.241.223.459.429.606.217.148.446.201.728.189h.852c.57.006 1.134.012 1.698.012 4.506.023 8.86.047 13.002.07 8.284.053 15.746.094 22.015.136 6.257.047 11.328.088 14.841.117 1.745.024 3.102.036 4.037.048.452.011.799.017 1.051.017.235.006.359.018.359.018s-.124.012-.359.012c-.247 0-.593.006-1.051.012-.935 0-2.292 0-4.037.011-3.513 0-8.584-.011-14.841-.017-6.269-.018-13.731-.042-22.015-.071-4.142-.018-8.496-.035-13.002-.053-.564 0-1.128 0-1.698-.006h-.852c-.288.012-.64-.059-.899-.241a1.417 1.417 0 0 1-.552-.772c-.088-.33-.035-.619-.047-.901 0-.578 0-1.161.006-1.744 0-1.166.012-2.344.012-3.528.005-.601 0-1.166.017-1.797.065-.677.694-1.236 1.381-1.219 19.118.106 36.427.2 48.971.271 3.131.024 5.963.041 8.454.065.306.018.617-.035.935.065.305.094.564.306.722.571.171.259.206.589.188.86 0 .277 0 .554-.006.819-.011 1.066-.017 2.044-.023 2.921-.012.878-.018 1.655-.024 2.333 0 .336-.005.648-.011.936 0 .283-.012.56-.124.772a1.23 1.23 0 0 1-.793.672c-.235.058-.358.035-.358.035l.011-.012Z"
      fill="#E0E0E0"
    />
    <Path d="m121.737 128.276-15.111-.069-.013 2.78 15.111.069.013-2.78Z" fill="#06113F" />
    <Path
      opacity={0.1}
      d="m121.737 128.276-15.111-.069-.013 2.78 15.111.069.013-2.78Z"
      fill="#000"
    />
    <Path
      d="M165.755 145.341s.123 0 .347-.065c.211-.071.558-.247.746-.665.1-.207.111-.46.1-.749V136.853c.011-.283-.024-.542-.171-.777a1.15 1.15 0 0 0-.64-.495c-.265-.083-.576-.036-.887-.053-2.492 0-5.323-.012-8.455-.018-12.544-.047-29.853-.112-48.971-.182a1.085 1.085 0 0 0-1.075.948c-.018.559-.012 1.178-.018 1.761 0 1.184-.011 2.362-.017 3.528 0 .583 0 1.167-.006 1.744.012.288-.03.589.035.824.065.242.223.46.429.607.217.147.446.2.728.189h.852c.57.005 1.134.011 1.698.011 4.507.024 8.86.047 13.002.071 8.285.053 15.746.094 22.015.136 6.258.047 11.328.088 14.841.117 1.745.024 3.103.036 4.037.047.452.006.805.018 1.051.018.236.006.359.018.359.018s-.123.012-.359.012c-.246 0-.593 0-1.051.011-.934 0-2.292 0-4.037.012-3.513 0-8.583-.012-14.841-.018-6.269-.017-13.73-.041-22.015-.07-4.142-.018-8.495-.036-13.002-.053-.564 0-1.128 0-1.698-.006h-.852c-.287.012-.64-.059-.898-.242a1.408 1.408 0 0 1-.553-.771c-.088-.33-.035-.619-.047-.907 0-.578 0-1.161.006-1.744 0-1.166.012-2.344.012-3.528.006-.601 0-1.166.018-1.797.064-.677.693-1.237 1.38-1.219 19.119.106 36.427.2 48.971.271 3.132.024 5.964.041 8.455.065.305.018.617-.035.934.065.305.094.564.306.723.571.17.259.205.589.188.86 0 .277 0 .554-.006.819-.012 1.066-.018 2.044-.024 2.921-.012.878-.017 1.655-.023 2.333 0 .336-.006.648-.012.936 0 .283-.012.56-.123.772-.206.436-.57.613-.794.671-.235.059-.358.036-.358.036l.006-.006Z"
      fill="#E0E0E0"
    />
    <Path d="m138.429 112.566-27.637-.126-.012 2.598 27.637.126.012-2.598Z" fill="#06113F" />
    <Path
      opacity={0.2}
      d="m138.429 112.566-27.637-.126-.012 2.598 27.637.126.012-2.598Z"
      fill="#fff"
    />
    <Path
      d="M113.294 140.529c0 .712-.546 1.29-1.216 1.284-.67 0-1.205-.583-1.205-1.296 0-.713.547-1.29 1.217-1.284.669 0 1.204.583 1.204 1.296ZM117.037 140.546c0 .713-.547 1.29-1.217 1.284-.669-.006-1.204-.583-1.204-1.296 0-.712.546-1.289 1.216-1.284.67 0 1.205.584 1.205 1.296ZM120.785 140.564c0 .713-.546 1.29-1.216 1.284-.67 0-1.204-.583-1.204-1.296 0-.713.546-1.29 1.216-1.284.669 0 1.204.583 1.204 1.296ZM124.528 140.582c0 .712-.547 1.29-1.216 1.284-.67 0-1.205-.583-1.205-1.296 0-.713.546-1.29 1.216-1.284.67 0 1.205.583 1.205 1.296ZM128.27 140.599c0 .713-.546 1.29-1.216 1.284-.67 0-1.204-.583-1.204-1.296 0-.712.546-1.289 1.216-1.284.67 0 1.204.584 1.204 1.296ZM132.013 140.617c0 .713-.546 1.29-1.216 1.284-.67 0-1.205-.583-1.205-1.296 0-.713.547-1.29 1.216-1.284.67 0 1.205.583 1.205 1.296ZM135.761 140.635c0 .712-.546 1.29-1.216 1.284-.67 0-1.204-.583-1.204-1.296 0-.713.546-1.29 1.216-1.284.67 0 1.204.583 1.204 1.296ZM139.504 140.652c0 .713-.547 1.29-1.216 1.284-.67 0-1.205-.583-1.205-1.296 0-.712.547-1.289 1.216-1.284.67 0 1.205.584 1.205 1.296ZM143.246 140.67c0 .713-.546 1.29-1.216 1.284-.67 0-1.204-.583-1.204-1.296 0-.713.546-1.29 1.216-1.284.67 0 1.204.583 1.204 1.296Z"
      fill="#06113F"
    />
    <G opacity={0.2} fill="#fff">
      <Path
        opacity={0.2}
        d="M113.294 140.529c0 .712-.546 1.29-1.216 1.284-.67 0-1.205-.583-1.205-1.296 0-.713.547-1.29 1.217-1.284.669 0 1.204.583 1.204 1.296ZM117.037 140.546c0 .713-.547 1.29-1.217 1.284-.669-.006-1.204-.583-1.204-1.296 0-.712.546-1.289 1.216-1.284.67 0 1.205.584 1.205 1.296ZM120.785 140.564c0 .713-.546 1.29-1.216 1.284-.67 0-1.204-.583-1.204-1.296 0-.713.546-1.29 1.216-1.284.669 0 1.204.583 1.204 1.296ZM124.528 140.582c0 .712-.547 1.29-1.216 1.284-.67 0-1.205-.583-1.205-1.296 0-.713.546-1.29 1.216-1.284.67 0 1.205.583 1.205 1.296ZM128.27 140.599c0 .713-.546 1.29-1.216 1.284-.67 0-1.204-.583-1.204-1.296 0-.712.546-1.289 1.216-1.284.67 0 1.204.584 1.204 1.296ZM132.013 140.617c0 .713-.546 1.29-1.216 1.284-.67 0-1.205-.583-1.205-1.296 0-.713.547-1.29 1.216-1.284.67 0 1.205.583 1.205 1.296ZM135.761 140.635c0 .712-.546 1.29-1.216 1.284-.67 0-1.204-.583-1.204-1.296 0-.713.546-1.29 1.216-1.284.67 0 1.204.583 1.204 1.296ZM139.504 140.652c0 .713-.547 1.29-1.216 1.284-.67 0-1.205-.583-1.205-1.296 0-.712.547-1.289 1.216-1.284.67 0 1.205.584 1.205 1.296ZM143.246 140.67c0 .713-.546 1.29-1.216 1.284-.67 0-1.204-.583-1.204-1.296 0-.713.546-1.29 1.216-1.284.67 0 1.204.583 1.204 1.296Z"
      />
    </G>
    <Path
      d="M137.929 55.633c.794-.33 1.352-1.149 1.358-2.115 0-1.26-.946-2.29-2.127-2.297-1.181 0-2.139 1.013-2.145 2.274 0 .972.564 1.796 1.363 2.132-3.29.495-3.137 4.082-3.137 4.082l7.773.035s.176-3.622-3.085-4.117v.006Z"
      fill="#06113F"
    />
    <Path
      opacity={0.1}
      d="M137.929 55.633c.794-.33 1.352-1.149 1.358-2.115 0-1.26-.946-2.29-2.127-2.297-1.181 0-2.139 1.013-2.145 2.274 0 .972.564 1.796 1.363 2.132-3.29.495-3.137 4.082-3.137 4.082l7.773.035s.176-3.622-3.085-4.117v.006Z"
      fill="#000"
    />
    <Path d="m150.567 62.084-27.726-.126-.015 3.322 27.726.126.015-3.322Z" fill="#06113F" />
    <Path
      opacity={0.2}
      d="m150.567 62.084-27.726-.126-.015 3.322 27.726.126.015-3.322Z"
      fill="#fff"
    />
    <Path
      d="M48.636 79.294s-.094.018-.27.03c-.194.011-.459.035-.8.058-.352.006-.78.083-1.286.171-.253.053-.529.07-.817.153-.288.083-.587.165-.91.254-1.27.4-2.797 1.036-4.33 2.155-1.534 1.108-3.067 2.692-4.266 4.76-.629 1.013-1.087 2.19-1.492 3.422-.335 1.255-.629 2.586-.653 3.993-.064.701.006 1.414.036 2.133.017.359.094.718.14 1.084.048.365.1.73.206 1.09.3 1.466.893 2.897 1.645 4.275 1.505 2.763 4.031 5.143 7.157 6.521 1.563.689 3.29 1.066 5.047 1.137 1.756.112 3.56-.171 5.27-.76l.517-.189.07-.023.06.029c2.525 1.284 5.005 2.551 7.408 3.77l-.218.189c-.793-2.463-1.556-4.848-2.29-7.139l-.03-.089.064-.065a15.859 15.859 0 0 0 3.89-6.485c.723-2.338.846-4.718.564-6.903a15.887 15.887 0 0 0-5.288-9.954c-1.228-1.06-2.503-1.773-3.643-2.321-1.169-.49-2.209-.801-3.073-.972a68.92 68.92 0 0 0-1.151-.194c-.335-.024-.617-.042-.852-.06-.217-.017-.394-.029-.523-.04-.117-.012-.176-.018-.176-.024 0-.006.059 0 .176 0 .13 0 .306.006.523.017.235.012.523.024.858.036.34.053.728.106 1.163.17.876.154 1.927.454 3.114.937 1.157.542 2.45 1.255 3.702 2.315a15.868 15.868 0 0 1 3.419 4.14 16.16 16.16 0 0 1 2.003 5.932c.294 2.215.183 4.636-.546 7.022a16.12 16.12 0 0 1-3.948 6.614l.035-.153 2.303 7.133.118.359-.335-.17c-2.403-1.22-4.883-2.48-7.415-3.77h.13l-.53.2c-1.744.601-3.59.89-5.387.778-1.798-.077-3.56-.46-5.159-1.167-3.19-1.413-5.763-3.846-7.29-6.661-.765-1.402-1.364-2.869-1.664-4.36a7.204 7.204 0 0 1-.205-1.107c-.047-.37-.124-.736-.141-1.101-.03-.736-.094-1.46-.03-2.168.03-1.425.335-2.78.682-4.052.423-1.249.893-2.439 1.533-3.464 1.228-2.09 2.791-3.68 4.354-4.788a14.805 14.805 0 0 1 4.4-2.127c.324-.082.635-.165.923-.241.288-.083.57-.094.823-.141.51-.083.946-.148 1.298-.142.34-.012.611-.018.805-.03h.276l.006-.017Z"
      fill="#FAC238"
    />
    <Path
      d="M51.99 93.048h-1.086l-2.368-3.31v-2.174s-1.68-.106-1.48 2.739c.2 2.845-.018 2.12-.018 2.12s-3.566.012-3.86.324c-.217.224-.311 3.776-.353 5.702-.017.966.576 1.867 1.475 2.097.13.035.259.053.382.053.887 0 5.429-.725 5.429-.725h1.874v-6.826h.006ZM55.099 92.453l-.67 7.445h-1.857V92.6l2.527-.147Z"
      fill="#FAC238"
    />
    <Path
      d="M77.243 244.025v-5.042l-9.007-.058-.076 7.61.558.041c2.485.159 12.644.395 14.306-.124 1.857-.577-5.787-2.432-5.787-2.432l.006.005Z"
      fill="#06113F"
    />
    <Path
      d="M83.242 245.987c0 .082-3.367.153-7.527.153s-7.526-.071-7.526-.153c0-.083 3.367-.153 7.526-.153 4.16 0 7.527.07 7.527.153ZM77.402 245.05c-.077-.041-.012-.306.182-.565.194-.265.429-.401.487-.342.06.059-.064.283-.24.524-.177.242-.353.425-.43.389v-.006ZM77.977 245.386c-.076-.029-.035-.33.141-.642.177-.318.412-.507.476-.454.065.054-.047.313-.211.601-.165.295-.323.525-.406.489v.006Z"
      fill="#263238"
    />
    <Path
      d="M70.903 245.963c-.035.024-.17-.106-.364-.33-.194-.217-.452-.53-.787-.795-.335-.259-.705-.394-.975-.424-.277-.035-.453 0-.47-.041-.018-.035.152-.159.475-.177.318-.023.77.1 1.152.401.382.294.623.654.776.907.152.259.223.436.188.459h.005ZM77.431 244.102c.047.071-.112.247-.37.342-.259.094-.488.064-.5-.024-.011-.082.17-.183.394-.265.223-.082.429-.124.476-.053ZM77.284 243.619c.006.082-.23.159-.535.159-.305 0-.546-.059-.54-.147 0-.083.247-.159.534-.159.288 0 .535.059.541.147Z"
      fill="#263238"
    />
    <Path
      d="M99.422 244.025v-5.042l-9.007-.058-.076 7.533.558.041c2.485.159 12.644.472 14.307-.041 1.856-.577-5.788-2.433-5.788-2.433h.006Z"
      fill="#06113F"
    />
    <Path
      d="M105.421 245.999c0 .082-3.367.153-7.526.153-4.16 0-7.527-.071-7.527-.153 0-.083 3.367-.153 7.527-.153 4.159 0 7.526.07 7.526.153ZM99.58 245.05c-.076-.041-.01-.306.183-.565.194-.265.429-.401.488-.342.058.059-.065.283-.241.524-.177.242-.353.425-.43.389v-.006ZM100.157 245.386c-.077-.029-.036-.33.141-.642.176-.318.411-.507.476-.454.064.054-.047.313-.212.601-.164.295-.329.525-.405.489v.006Z"
      fill="#263238"
    />
    <Path
      d="M93.083 245.963c-.035.024-.17-.106-.364-.33-.194-.217-.453-.53-.788-.795-.335-.259-.705-.394-.975-.424-.276-.035-.453 0-.47-.041-.018-.035.153-.159.476-.177.317-.023.77.1 1.151.401.382.294.623.654.776.907.153.259.223.436.188.459h.006ZM99.61 244.102c.041.071-.111.247-.37.342-.258.094-.487.064-.5-.024-.011-.082.171-.183.394-.265.224-.082.43-.124.476-.053ZM99.47 243.619c.005.082-.23.159-.535.159-.306 0-.547-.059-.541-.147.006-.083.247-.159.535-.159.288 0 .534.059.54.147Z"
      fill="#263238"
    />
    <Path
      d="M132.606 94.473c-.235-.241-.811-.13-.811-.13s.1-.689 0-1.019c-.105-.335-.846-.27-.846-.27s-.047-.319.018-.837c.018-.13-.053-.224-.17-.294-.353-.213-1.105-.2-1.105-.2-.452-.325 1.381-2.899 2.062-3.723.67-.813.558-1.467-.047-1.467-.411 0-2.556 2.474-3.208 3.305-.652.83-2.409 3.139-2.467 1.295-.059-1.843-.799-3.24-1.258-3.475-.464-.236-.957.318-.705.83.206.413.335 1.214.412 1.815a4.394 4.394 0 0 1-.071 1.49c-.305 1.378-.634 2.28-.793 2.662l-.047-.023s-12.826 12.328-13.748 13.206c-.835.789-17.497-.937-20.834-1.29-.91-.1-2.262-.289-3.437-.236-1.446.065-1.704.271-3.155.289-4.436.059-8.138.495-10.887.96a9.026 9.026 0 0 0-7.086 6.161c-3.367 10.543-10.993 35.5-12.35 49.519-1.07 2.014-2.268 4.37-2.75 5.696-.946 2.58-.716 2.662-.4 2.721.853.159 1.834-4.471 2.198-3.351.364 1.119-1.85 5.519-.952 5.648 1.175.171 2.11-4.694 2.215-5.101.106-.394.7-.259.582.1-.17.519-1.821 6.014-.676 6.191.635.1.705-1.066.705-1.066s1.17-5.254 1.71-5.154c.547.1-.035 3.21-.305 4.247-.265 1.025.111 1.567.658 1.307.376-.176 1.275-3.322 1.521-4.352.241-1.031.864-3.864 1.693-2.221.828 1.643 2.085 2.598 2.602 2.621.517.018.735-.695.288-1.054-.364-.289-.816-.954-1.134-1.473a4.341 4.341 0 0 1-.564-1.378c-.411-1.885-.411-2.98-.411-2.98l-.094-.607c1.316-6.532 5.106-25.705 10.558-37.951l2.244 4.63.976 3.428-5.981 14.808 25.504-.336-3.801-14.69 4.618-15.314c5 .111 17.843.3 20-.672 2.69-1.207 16.615-18.802 16.615-18.802.94-.748 1.898-1.56 2.603-2.261 0 0 .828-.672.317-1.202h-.006Z"
      fill="#FFBE9D"
    />
    <Path
      d="M65.633 136.847c.74-2.533 4.542-15.055 4.542-15.055S72.043 132 71.55 133.231c-.564 1.419-1.604 3.039-1.604 3.039-4.313 6.585-3.749 9.878-3.749 9.878l26.052-.795c.064-3.652-2.092-12.146-2.544-13.501-.453-1.354 1.045-5.796 1.045-5.796s2.944-2.998 2.832-4.541c-.229-3.11-.51-4.111-.51-4.111l15.851.583-.5-10.785-22.784-2.286c-.787 2.25-7.967 1.149-7.967 1.149-7.75.253-8.214 1.685-11.445 4.194-3.232 2.509-8.549 24.044-8.549 24.044l7.955 2.544Z"
      fill="#06113F"
    />
    <Path
      d="M81.038 134.303c0 .041-.522.076-1.374.07-.846 0-2.022-.059-3.308-.188a36.003 36.003 0 0 1-3.279-.477c-.834-.165-1.34-.301-1.333-.342.006-.041.528.018 1.369.13.84.106 1.991.253 3.272.383 1.281.129 2.444.217 3.284.276.84.059 1.364.1 1.364.148h.005ZM82.825 132.082c.011.035-.53.242-1.422.489-.893.241-2.15.512-3.56.683-1.41.165-2.698.2-3.62.177-.928-.024-1.498-.1-1.498-.141 0-.048.576-.048 1.498-.077.922-.03 2.192-.094 3.584-.265a41.667 41.667 0 0 0 3.549-.589c.904-.189 1.457-.318 1.474-.277h-.005ZM91.585 122.175c.04.006-.024.518-.177 1.331a63.215 63.215 0 0 1-.687 3.163 58.902 58.902 0 0 1-.846 3.122c-.241.795-.417 1.272-.458 1.266-.041-.012.058-.512.246-1.319.224-.919.476-1.974.758-3.14.288-1.166.547-2.214.776-3.133.206-.801.346-1.296.388-1.284v-.006ZM65.91 135.351c-.024.083-1.822-.336-4.013-.936-2.198-.601-3.954-1.155-3.93-1.237.023-.083 1.82.341 4.012.936 2.191.601 3.954 1.155 3.93 1.237ZM93.353 117.268s-.24.041-.675 0a6.984 6.984 0 0 1-1.793-.43 7.542 7.542 0 0 1-2.338-1.425 7.32 7.32 0 0 1-1.91-2.763 7.662 7.662 0 0 1-.528-3.322c.07-1.037.34-1.973.805-2.627.458-.665 1.063-.984 1.486-1.095.212-.059.388-.071.5-.065.117 0 .176.017.176.023 0 .03-.241.018-.64.153-.388.142-.94.472-1.346 1.108-.799 1.26-1.022 3.563-.17 5.713a7.277 7.277 0 0 0 1.815 2.663 7.778 7.778 0 0 0 2.227 1.437c1.41.589 2.397.565 2.385.636l.006-.006ZM107.048 117.775c-.082 0-.146-2.433-.141-5.425 0-2.998.077-5.431.165-5.431.082 0 .147 2.433.141 5.431-.006 2.998-.076 5.425-.165 5.425Z"
      fill="#263238"
    />
    <Path
      d="M85.498 105.582c.064-1.314.2-2.798.2-2.798s3.243-.359 3.437-3.717c.194-3.357 0-11.103 0-11.103L83.23 84.95l-6.498 5.136.987 16.787 5.975.383c.94.059 1.75-.695 1.798-1.679l.006.006Z"
      fill="#FFBE9D"
    />
    <Path
      d="M86.08 96.405s.011.065-.053.147a.451.451 0 0 1-.37.165c-.377-.017-.74-.436-.87-.942a1.703 1.703 0 0 1-.018-.748c.041-.224.17-.448.353-.507a.324.324 0 0 1 .247 0c.07.035.111.089.135.136.04.1.011.159 0 .153-.012 0-.012-.053-.06-.118a.213.213 0 0 0-.275-.059c-.124.059-.212.218-.241.418-.035.2-.035.436.03.666.117.453.434.83.71.866a.42.42 0 0 0 .317-.089c.065-.053.089-.1.1-.094l-.005.006Z"
      fill="#EB996E"
    />
    <Path
      d="M91.15 86.15c-.453-1.543-2.092-2.315-3.584-2.739-2.274-.648-4.736-.93-6.95-.112-2.221.82-4.889 2.262-4.889 4.718-1.31-.106-2.056 1.043-2.58 2.468a9.463 9.463 0 0 0-.557 2.71h-.024c-.17 3.21-1.063 7.645-3.143 10.025-1.152 1.314-2.803 2.221-3.472 3.858-.582 1.426-.282 3.104-.829 4.541-.487 1.267-1.563 2.168-2.332 3.27-1.68 2.403-1.792 5.795-.559 8.476 1.234 2.68 3.678 4.641 6.393 5.542 2.714.901 5.675.801 8.437.077 2.039-.53 4.024-1.414 5.652-2.81 1.627-1.39 2.873-3.328 3.243-5.49.411-2.409-.258-4.847-.752-7.239-1.563-7.563-1.116-7.951.858-14.967.047-.123.07-.253.106-.377.023-.094.052-.182.076-.276.053-.277.094-.554.094-.843a1.599 1.599 0 0 1-1.698-.37c-.44-.443-.6-1.161-.4-1.762.23-.695.864-1.154 1.516-1.431.653-.277 1.352-.413 1.992-.719 1.357-.648 2.297-1.99 2.938-3.399.446-.983.781-2.114.476-3.157l-.012.006Z"
      fill="#263238"
    />
    <Path
      d="M86.314 96.552s.136.212.312.583.411.896.67 1.502l.047.112-.124.012-.599.053c-.411.03-.752.077-1.151.07a.21.21 0 0 1-.153-.135.327.327 0 0 1-.012-.165c.018-.094.035-.159.053-.241l.106-.418c.129-.507.223-.819.246-.813.024 0-.023.33-.123.842l-.082.424c-.03.142-.083.354 0 .342.293-.012.71-.059 1.098-.088.206-.018.4-.03.6-.048l-.077.124a59.588 59.588 0 0 1-.61-1.525c-.148-.383-.224-.619-.2-.63Z"
      fill="#06113F"
    />
    <Path
      d="m66.197 146.148 26.052-.795 11.327 44.376-2.086 50.904H87.701l1.71-49.82-10.076-30.894 1.55 82.687-13.918.206-1.469-77.721c-.059-2.162-.77-7.033-.505-9.177l1.204-9.766Z"
      fill="#263238"
    />
    <Path
      d="M82.807 160.543c0 .042-.494.018-1.275-.076a40.734 40.734 0 0 1-6.04-1.219c-.758-.218-1.216-.383-1.21-.424.023-.083 1.915.377 4.265.848 2.35.477 4.272.783 4.26.871ZM101.367 237.835c0 .082-3.12.153-6.974.153s-6.974-.071-6.974-.153c0-.083 3.12-.153 6.974-.153s6.974.07 6.974.153ZM80.768 240.456c0 .082-3.026.153-6.762.153-3.737 0-6.763-.071-6.763-.153s3.026-.153 6.763-.153c3.736 0 6.762.071 6.762.153Z"
      fill="#455A64"
    />
    <Path
      d="M78.97 240.244c-.082 0-.452-18.065-.816-40.348-.365-22.289-.594-40.36-.511-40.36.082 0 .452 18.066.816 40.354.364 22.283.594 40.354.511 40.354ZM99.516 237.835s-.006-.083-.006-.242l.012-.707c.018-.636.047-1.549.077-2.709l.346-9.972c.323-8.435.764-20.056 1.258-32.897 0-.118.011-.236.011-.347v.041c-3.084-12.393-5.875-23.596-7.896-31.719-.993-4.047-1.798-7.322-2.356-9.607-.27-1.125-.476-2.003-.623-2.615-.064-.289-.117-.513-.153-.683-.035-.154-.047-.236-.04-.236 0 0 .029.077.07.23.047.165.106.388.182.671.159.613.388 1.484.681 2.604.582 2.279 1.422 5.548 2.45 9.583 2.045 8.117 4.865 19.314 7.991 31.701v.041c0 .106 0 .23-.012.348-.523 12.835-.999 24.462-1.345 32.891-.188 4.194-.335 7.598-.441 9.966-.059 1.166-.106 2.073-.135 2.709-.018.301-.035.531-.041.707-.012.159-.018.242-.024.242h-.006Z"
      fill="#455A64"
    />
    <Path
      d="M95.022 189.93c.011.082-1.352.389-3.044.677-1.269.218-2.362.365-2.837.395v-.118c.158.041.24.077.24.1 0 .024-.094.03-.258.024l-.359-.018.353-.106c.458-.135 1.54-.359 2.808-.577 1.698-.295 3.085-.46 3.097-.377ZM94.757 191.62c-.011.083-1.234-.012-2.744-.123-1.51-.118-2.732-.218-2.726-.301 0-.082 1.234-.124 2.756 0 1.516.118 2.732.348 2.72.43l-.006-.006Z"
      fill="#455A64"
    />
    <Path
      opacity={0.2}
      d="M66.086 147.084c.628.89 1.527 1.202 2.573 1.373 1.046.171 2.11.059 3.161-.059 6.727-.748 13.848-1.437 20.434-3.045-8.525.477-17.526.318-26.05.795l-.089.73M74.87 159.13l4.805 18.69-.334-17.901-4.472-.789Z"
      fill="#000"
    />
  </Svg>
)

export default WelcomeTutorialPageOne
