import React, { FC } from 'react'
import Svg, { Path } from 'react-native-svg'

const Congrats: FC = () => {
  return (
    <Svg width="371" height="153" viewBox="0 0 371 153" fill="none">
      <Path
        d="M182.26 152.762C201.581 152.762 217.244 137.1 217.244 117.779C217.244 98.4581 201.581 82.7956 182.26 82.7956C162.94 82.7956 147.277 98.4581 147.277 117.779C147.277 137.1 162.94 152.762 182.26 152.762Z"
        fill="#FAC238"
      />
      <Path
        d="M199.485 103.027C199.926 103.387 193.391 112.18 184.893 122.656C183.076 124.861 181.338 126.972 179.735 128.91L179.027 129.765L178.265 128.99C171.624 122.148 167.081 117.057 167.401 116.737C167.722 116.416 172.8 120.946 179.655 127.6L178.198 127.681C179.775 125.716 181.472 123.578 183.263 121.36C191.775 110.884 199.03 102.679 199.485 103.053V103.027Z"
        fill="white"
      />
      <Path
        d="M231.956 103.695C230.914 100.02 226.143 100.381 226.143 100.381L226.277 102.933C229.043 102.452 230.753 105.699 230.753 105.699L231.956 103.708V103.695Z"
        fill="#FAC238"
      />
      <Path
        d="M130.627 111.392C129.585 107.717 124.815 108.078 124.815 108.078L124.948 110.63C127.714 110.149 129.425 113.383 129.425 113.383L130.627 111.392Z"
        fill="#E0E0E0"
      />
      <Path
        d="M186.443 60.5603C191.988 62.244 195.436 55.8433 195.436 55.8433L192.069 53.9191C190.425 57.8343 184.893 57.38 184.893 57.38L186.456 60.5469L186.443 60.5603Z"
        fill="#E0E0E0"
      />
      <Path
        d="M155.696 67.442C153.117 67.7093 152.809 70.9564 152.809 70.9564L154.533 71.1568C154.533 69.2593 156.898 68.4709 156.898 68.4709L155.696 67.442Z"
        fill="#FAC238"
      />
      <Path
        d="M261.968 91.1071C262.342 88.5415 259.282 87.4458 259.282 87.4458L258.654 89.0627C260.498 89.5304 260.659 92.0158 260.659 92.0158L261.955 91.1071H261.968Z"
        fill="#E0E0E0"
      />
      <Path
        d="M170.742 33.6613C171.116 31.0957 168.056 30 168.056 30L167.428 31.6169C169.272 32.0846 169.432 34.57 169.432 34.57L170.729 33.6613H170.742Z"
        fill="#FAC238"
      />
      <Path
        d="M107.029 75.0587C106.561 79.0808 111.893 80.0563 111.893 80.0563L112.855 77.4239C109.661 77.1299 109.194 73.375 109.194 73.375L107.029 75.072V75.0587Z"
        fill="#FAC238"
      />
      <Path
        d="M225.635 60.4801C228.228 60.6271 229.056 57.4869 229.056 57.4869L227.386 57.0059C227.079 58.89 224.62 59.2641 224.62 59.2641L225.635 60.4801Z"
        fill="#FAC238"
      />
      <Path
        d="M239.773 71.0365C239.773 71.1568 238.811 71.2503 237.635 71.2503C236.459 71.2503 235.497 71.1568 235.497 71.0365C235.497 70.9162 236.459 70.8227 237.635 70.8227C238.811 70.8227 239.773 70.9162 239.773 71.0365Z"
        fill="#E0E0E0"
      />
      <Path
        d="M242.045 65.2104C242.165 65.2104 242.258 66.1859 242.258 67.3751C242.258 68.5644 242.165 69.5399 242.045 69.5399C241.924 69.5399 241.831 68.5644 241.831 67.3751C241.831 66.1859 241.924 65.2104 242.045 65.2104Z"
        fill="#E0E0E0"
      />
      <Path
        d="M244.463 70.8894C244.463 70.7691 245.425 70.6756 246.601 70.6756C247.777 70.6756 248.739 70.7691 248.739 70.8894C248.739 71.0097 247.777 71.1032 246.601 71.1032C245.425 71.1032 244.463 71.0097 244.463 70.8894Z"
        fill="#E0E0E0"
      />
      <Path
        d="M242.192 76.7021C242.071 76.7021 241.978 75.7267 241.978 74.5374C241.978 73.3481 242.071 72.3727 242.192 72.3727C242.312 72.3727 242.406 73.3481 242.406 74.5374C242.406 75.7267 242.312 76.7021 242.192 76.7021Z"
        fill="#E0E0E0"
      />
      <Path
        d="M245.653 74.2168C245.559 74.2969 245.158 73.9495 244.731 73.4417C244.316 72.9339 244.049 72.4663 244.129 72.3994C244.223 72.3193 244.624 72.6667 245.051 73.1745C245.466 73.6823 245.733 74.1499 245.653 74.2168Z"
        fill="#E0E0E0"
      />
      <Path
        d="M245.452 67.6157C245.532 67.6958 245.252 68.1234 244.811 68.5644C244.37 69.0054 243.956 69.2994 243.875 69.2192C243.795 69.139 244.076 68.7114 244.517 68.2704C244.958 67.8295 245.372 67.5355 245.452 67.6157Z"
        fill="#E0E0E0"
      />
      <Path
        d="M240.548 69.2993C240.468 69.3929 240.08 69.1791 239.666 68.8316C239.252 68.4842 238.971 68.1368 239.051 68.0432C239.132 67.9497 239.519 68.1635 239.933 68.5109C240.348 68.8584 240.628 69.2058 240.548 69.2993Z"
        fill="#E0E0E0"
      />
      <Path
        d="M240.361 72.199C240.441 72.2792 240.201 72.6934 239.8 73.121C239.399 73.5486 239.011 73.8426 238.931 73.7624C238.851 73.6822 239.092 73.268 239.492 72.8404C239.893 72.4128 240.281 72.1188 240.361 72.199Z"
        fill="#E0E0E0"
      />
      <Path
        d="M123.371 59.1171C123.371 59.2374 122.409 59.3309 121.233 59.3309C120.058 59.3309 119.095 59.2374 119.095 59.1171C119.095 58.9968 120.058 58.9033 121.233 58.9033C122.409 58.9033 123.371 58.9968 123.371 59.1171Z"
        fill="#FAC238"
      />
      <Path
        d="M125.643 53.3044C125.763 53.3044 125.857 54.2798 125.857 55.4691C125.857 56.6584 125.763 57.6338 125.643 57.6338C125.523 57.6338 125.429 56.6584 125.429 55.4691C125.429 54.2798 125.523 53.3044 125.643 53.3044Z"
        fill="#FAC238"
      />
      <Path
        d="M128.048 58.9836C128.048 58.8633 129.01 58.7698 130.186 58.7698C131.362 58.7698 132.324 58.8633 132.324 58.9836C132.324 59.1038 131.362 59.1974 130.186 59.1974C129.01 59.1974 128.048 59.1038 128.048 58.9836Z"
        fill="#FAC238"
      />
      <Path
        d="M125.79 64.7963C125.67 64.7963 125.576 63.8209 125.576 62.6316C125.576 61.4423 125.67 60.4669 125.79 60.4669C125.91 60.4669 126.004 61.4423 126.004 62.6316C126.004 63.8209 125.91 64.7963 125.79 64.7963Z"
        fill="#FAC238"
      />
      <Path
        d="M129.238 62.2974C129.144 62.3776 128.743 62.0301 128.316 61.5224C127.901 61.0146 127.634 60.5469 127.714 60.4801C127.808 60.3999 128.209 60.7473 128.636 61.2551C129.051 61.7629 129.318 62.2306 129.238 62.2974Z"
        fill="#FAC238"
      />
      <Path
        d="M129.037 55.7097C129.117 55.7898 128.837 56.2174 128.396 56.6584C127.955 57.0994 127.541 57.3933 127.46 57.3132C127.38 57.233 127.661 56.8054 128.102 56.3644C128.543 55.9234 128.957 55.6295 129.037 55.7097Z"
        fill="#FAC238"
      />
      <Path
        d="M124.133 57.38C124.053 57.4736 123.665 57.2598 123.251 56.9123C122.837 56.5649 122.556 56.2175 122.637 56.1239C122.717 56.0304 123.104 56.2442 123.518 56.5916C123.933 56.939 124.213 57.2865 124.133 57.38Z"
        fill="#FAC238"
      />
      <Path
        d="M123.946 60.293C124.026 60.3732 123.786 60.7875 123.385 61.2151C122.984 61.6427 122.597 61.9366 122.516 61.8565C122.436 61.7763 122.677 61.362 123.078 60.9344C123.478 60.5068 123.866 60.2129 123.946 60.293Z"
        fill="#FAC238"
      />
      <Path
        d="M124.956 73.6947C123.914 70.02 119.143 70.3808 119.143 70.3808L119.277 72.933C122.043 72.452 123.753 75.6991 123.753 75.6991L124.956 73.708V73.6947Z"
        fill="#FAC238"
      />
      <Path
        d="M23.6274 81.3915C22.5851 77.7168 17.8146 78.0776 17.8146 78.0776L17.9482 80.6298C20.7143 80.1488 22.4247 83.3825 22.4247 83.3825L23.6274 81.3915Z"
        fill="#E0E0E0"
      />
      <Path
        d="M297.627 81.3915C296.585 77.7168 291.815 78.0776 291.815 78.0776L291.948 80.6298C294.714 80.1488 296.425 83.3825 296.425 83.3825L297.627 81.3915Z"
        fill="#E0E0E0"
      />
      <Path
        d="M79.4429 30.5603C84.9884 32.244 88.436 25.8433 88.436 25.8433L85.0686 23.9191C83.425 27.8343 77.8929 27.38 77.8929 27.38L79.4563 30.5469L79.4429 30.5603Z"
        fill="#E0E0E0"
      />
      <Path
        d="M353.443 30.5603C358.988 32.244 362.436 25.8433 362.436 25.8433L359.069 23.9191C357.425 27.8343 351.893 27.38 351.893 27.38L353.456 30.5469L353.443 30.5603Z"
        fill="#E0E0E0"
      />
      <Path
        d="M48.6956 37.442C46.1166 37.7093 45.8093 40.9564 45.8093 40.9564L47.533 41.1568C47.533 39.2593 49.8982 38.4709 49.8982 38.4709L48.6956 37.442Z"
        fill="#FAC238"
      />
      <Path
        d="M322.696 37.442C320.117 37.7093 319.809 40.9564 319.809 40.9564L321.533 41.1568C321.533 39.2593 323.898 38.4709 323.898 38.4709L322.696 37.442Z"
        fill="#FAC238"
      />
      <Path
        d="M154.968 61.1071C155.342 58.5415 152.282 57.4457 152.282 57.4457L151.654 59.0626C153.498 59.5303 153.659 62.0157 153.659 62.0157L154.955 61.1071H154.968Z"
        fill="#E0E0E0"
      />
      <Path
        d="M63.7419 3.66135C64.116 1.09573 61.056 0 61.056 0L60.428 1.61687C62.272 2.08456 62.4324 4.57 62.4324 4.57L63.7285 3.66135H63.7419Z"
        fill="#FAC238"
      />
      <Path
        d="M337.742 3.66135C338.116 1.09573 335.056 0 335.056 0L334.428 1.61687C336.272 2.08456 336.432 4.57 336.432 4.57L337.729 3.66135H337.742Z"
        fill="#FAC238"
      />
      <Path
        d="M0.0290635 45.0587C-0.438627 49.0808 4.89305 50.0563 4.89305 50.0563L5.85515 47.4239C2.66149 47.1299 2.1938 43.375 2.1938 43.375L0.0290635 45.072V45.0587Z"
        fill="#FAC238"
      />
      <Path
        d="M274.029 45.0587C273.561 49.0808 278.893 50.0563 278.893 50.0563L279.855 47.4239C276.661 47.1299 276.194 43.375 276.194 43.375L274.029 45.072V45.0587Z"
        fill="#FAC238"
      />
      <Path
        d="M118.635 30.4801C121.228 30.627 122.056 27.4869 122.056 27.4869L120.386 27.0058C120.079 28.8899 117.62 29.2641 117.62 29.2641L118.635 30.4801Z"
        fill="#FAC238"
      />
      <Path
        d="M132.773 41.0365C132.773 41.1568 131.811 41.2503 130.635 41.2503C129.459 41.2503 128.497 41.1568 128.497 41.0365C128.497 40.9162 129.459 40.8227 130.635 40.8227C131.811 40.8227 132.773 40.9162 132.773 41.0365Z"
        fill="#E0E0E0"
      />
      <Path
        d="M135.045 35.2104C135.165 35.2104 135.258 36.1859 135.258 37.3751C135.258 38.5644 135.165 39.5399 135.045 39.5399C134.924 39.5399 134.831 38.5644 134.831 37.3751C134.831 36.1859 134.924 35.2104 135.045 35.2104Z"
        fill="#E0E0E0"
      />
      <Path
        d="M137.463 40.8894C137.463 40.7691 138.425 40.6756 139.601 40.6756C140.777 40.6756 141.739 40.7691 141.739 40.8894C141.739 41.0097 140.777 41.1032 139.601 41.1032C138.425 41.1032 137.463 41.0097 137.463 40.8894Z"
        fill="#E0E0E0"
      />
      <Path
        d="M135.192 46.7021C135.071 46.7021 134.978 45.7267 134.978 44.5374C134.978 43.3481 135.071 42.3727 135.192 42.3727C135.312 42.3727 135.406 43.3481 135.406 44.5374C135.406 45.7267 135.312 46.7021 135.192 46.7021Z"
        fill="#E0E0E0"
      />
      <Path
        d="M138.653 44.2168C138.559 44.2969 138.158 43.9495 137.731 43.4417C137.316 42.9339 137.049 42.4663 137.129 42.3994C137.223 42.3193 137.624 42.6667 138.051 43.1745C138.466 43.6823 138.733 44.1499 138.653 44.2168Z"
        fill="#E0E0E0"
      />
      <Path
        d="M138.452 37.6157C138.532 37.6958 138.252 38.1234 137.811 38.5644C137.37 39.0054 136.956 39.2994 136.875 39.2192C136.795 39.139 137.076 38.7114 137.517 38.2704C137.958 37.8295 138.372 37.5355 138.452 37.6157Z"
        fill="#E0E0E0"
      />
      <Path
        d="M133.548 39.2993C133.468 39.3929 133.08 39.1791 132.666 38.8316C132.252 38.4842 131.971 38.1368 132.051 38.0432C132.132 37.9497 132.519 38.1635 132.933 38.5109C133.348 38.8584 133.628 39.2058 133.548 39.2993Z"
        fill="#E0E0E0"
      />
      <Path
        d="M133.361 42.199C133.441 42.2792 133.201 42.6934 132.8 43.121C132.399 43.5486 132.011 43.8426 131.931 43.7624C131.851 43.6822 132.092 43.268 132.492 42.8404C132.893 42.4128 133.281 42.1188 133.361 42.199Z"
        fill="#E0E0E0"
      />
      <Path
        d="M16.3715 29.117C16.3715 29.2373 15.4094 29.3309 14.2335 29.3309C13.0576 29.3309 12.0955 29.2373 12.0955 29.117C12.0955 28.9968 13.0576 28.9033 14.2335 28.9033C15.4094 28.9033 16.3715 28.9968 16.3715 29.117Z"
        fill="#FAC238"
      />
      <Path
        d="M18.6432 23.3043C18.7635 23.3043 18.857 24.2798 18.857 25.4691C18.857 26.6583 18.7635 27.6338 18.6432 27.6338C18.5229 27.6338 18.4294 26.6583 18.4294 25.4691C18.4294 24.2798 18.5229 23.3043 18.6432 23.3043Z"
        fill="#FAC238"
      />
      <Path
        d="M21.0484 28.9835C21.0484 28.8632 22.0105 28.7697 23.1864 28.7697C24.3623 28.7697 25.3244 28.8632 25.3244 28.9835C25.3244 29.1037 24.3623 29.1973 23.1864 29.1973C22.0105 29.1973 21.0484 29.1037 21.0484 28.9835Z"
        fill="#FAC238"
      />
      <Path
        d="M18.7902 34.7962C18.6699 34.7962 18.5764 33.8207 18.5764 32.6315C18.5764 31.4422 18.6699 30.4667 18.7902 30.4667C18.9104 30.4667 19.004 31.4422 19.004 32.6315C19.004 33.8207 18.9104 34.7962 18.7902 34.7962Z"
        fill="#FAC238"
      />
      <Path
        d="M22.2377 32.2973C22.1442 32.3775 21.7433 32.0301 21.3157 31.5223C20.9015 31.0145 20.6342 30.5468 20.7144 30.48C20.8079 30.3998 21.2088 30.7473 21.6364 31.255C22.0507 31.7628 22.3179 32.2305 22.2377 32.2973Z"
        fill="#FAC238"
      />
      <Path
        d="M22.0373 25.7096C22.1175 25.7898 21.8368 26.2174 21.3959 26.6583C20.9549 27.0993 20.5407 27.3933 20.4605 27.3131C20.3803 27.2329 20.6609 26.8053 21.1019 26.3643C21.5429 25.9234 21.9571 25.6294 22.0373 25.7096Z"
        fill="#FAC238"
      />
      <Path
        d="M17.1332 27.38C17.053 27.4735 16.6655 27.2597 16.2513 26.9123C15.8371 26.5648 15.5564 26.2174 15.6366 26.1239C15.7168 26.0303 16.1043 26.2441 16.5186 26.5916C16.9328 26.939 17.2134 27.2864 17.1332 27.38Z"
        fill="#FAC238"
      />
      <Path
        d="M16.9462 30.293C17.0264 30.3732 16.7858 30.7874 16.385 31.215C15.9841 31.6426 15.5966 31.9366 15.5164 31.8564C15.4362 31.7762 15.6767 31.362 16.0776 30.9344C16.4785 30.5068 16.866 30.2128 16.9462 30.293Z"
        fill="#FAC238"
      />
      <Path
        d="M290.371 29.117C290.371 29.2373 289.409 29.3309 288.233 29.3309C287.058 29.3309 286.095 29.2373 286.095 29.117C286.095 28.9968 287.058 28.9033 288.233 28.9033C289.409 28.9033 290.371 28.9968 290.371 29.117Z"
        fill="#FAC238"
      />
      <Path
        d="M292.643 23.3043C292.763 23.3043 292.857 24.2798 292.857 25.4691C292.857 26.6583 292.763 27.6338 292.643 27.6338C292.523 27.6338 292.429 26.6583 292.429 25.4691C292.429 24.2798 292.523 23.3043 292.643 23.3043Z"
        fill="#FAC238"
      />
      <Path
        d="M295.048 28.9835C295.048 28.8632 296.01 28.7697 297.186 28.7697C298.362 28.7697 299.324 28.8632 299.324 28.9835C299.324 29.1037 298.362 29.1973 297.186 29.1973C296.01 29.1973 295.048 29.1037 295.048 28.9835Z"
        fill="#FAC238"
      />
      <Path
        d="M292.79 34.7962C292.67 34.7962 292.576 33.8207 292.576 32.6315C292.576 31.4422 292.67 30.4667 292.79 30.4667C292.91 30.4667 293.004 31.4422 293.004 32.6315C293.004 33.8207 292.91 34.7962 292.79 34.7962Z"
        fill="#FAC238"
      />
      <Path
        d="M296.238 32.2973C296.144 32.3775 295.743 32.0301 295.316 31.5223C294.901 31.0145 294.634 30.5468 294.714 30.48C294.808 30.3998 295.209 30.7473 295.636 31.255C296.051 31.7628 296.318 32.2305 296.238 32.2973Z"
        fill="#FAC238"
      />
      <Path
        d="M296.037 25.7096C296.117 25.7898 295.837 26.2174 295.396 26.6583C294.955 27.0993 294.541 27.3933 294.46 27.3131C294.38 27.2329 294.661 26.8053 295.102 26.3643C295.543 25.9234 295.957 25.6294 296.037 25.7096Z"
        fill="#FAC238"
      />
      <Path
        d="M291.133 27.38C291.053 27.4735 290.666 27.2597 290.251 26.9123C289.837 26.5648 289.556 26.2174 289.637 26.1239C289.717 26.0303 290.104 26.2441 290.519 26.5916C290.933 26.939 291.213 27.2864 291.133 27.38Z"
        fill="#FAC238"
      />
      <Path
        d="M290.946 30.293C291.026 30.3732 290.786 30.7874 290.385 31.215C289.984 31.6426 289.597 31.9366 289.516 31.8564C289.436 31.7762 289.677 31.362 290.078 30.9344C290.478 30.5068 290.866 30.2128 290.946 30.293Z"
        fill="#FAC238"
      />
    </Svg>
  )
}

export default Congrats
