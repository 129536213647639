import { useNavigation } from '@react-navigation/native'
import { Column, ScrollView } from 'native-base'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { useApi } from 'api'
import { Loading } from 'components/Loading'
import { ScreenHeader } from 'components/ScreenHeader'
import { TabBarSpacer } from 'components/TabBar'
import { QueryKey } from 'services/queryClient'
import { AccountScreenProps } from 'types/navigation'
import { useScrollViewStyles } from 'utils/scroll'

import { BlogPostCard } from './BlogPostCard'

type Props = AccountScreenProps<'BlogArticles'>

const TEN_MINUTES_IN_MS = 10 * 60 * 1000

export const BlogArticlesScreen: React.FC<Props> = () => {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const api = useApi()
  const scrollViewStyles = useScrollViewStyles()

  const { data: posts, isLoading } = useQuery(
    QueryKey.BlogItems,
    async () => {
      return await api.webflowControllerBlogPosts()
    },
    { staleTime: TEN_MINUTES_IN_MS }
  )

  const renderBlogPostsCards = () => {
    if (isLoading) return <Loading size={50} />
    if (!posts) return null
    return posts.map((post) => <BlogPostCard key={post.name} blogPost={post} />)
  }

  return (
    <ScrollView {...scrollViewStyles}>
      <ScreenHeader
        title={t('blog.title')}
        onGoBack={() => navigate('TabNav', { screen: 'AccountNav', params: { screen: 'Account' } })}
      />
      <Column width="100%" alignItems="center" paddingX={5} maxW={400}>
        {renderBlogPostsCards()}
      </Column>
      <TabBarSpacer />
    </ScrollView>
  )
}
