import MixpanelAnalytics from '@bothrs/expo-mixpanel-analytics'
import { Platform } from 'react-native'

import { config } from 'config'

import { Analytics, AnalyticsProfileProperties } from './types'

let mixpanelAnalytics: MixpanelAnalytics | undefined = undefined

export const analytics: Analytics = {
  enable: () => {
    mixpanelAnalytics = new MixpanelAnalytics(config.MIXPANEL_TOKEN)
  },
  setEventPersistedProperties: (properties: Record<string, any> = {}) => {
    if (!mixpanelAnalytics) return
    mixpanelAnalytics.register({ platform: Platform.OS, ...properties })
  },
  identify: (userId: string) => {
    if (!mixpanelAnalytics) return
    mixpanelAnalytics.identify(userId)
  },
  disable: () => {
    mixpanelAnalytics = undefined
  },
  log: (name, params) => {
    if (!mixpanelAnalytics) return
    mixpanelAnalytics.track(name, params)
  },
  setProfilePersistedProperties: (properties: Partial<AnalyticsProfileProperties>) => {
    if (!mixpanelAnalytics) return
    mixpanelAnalytics.people_set(properties)
  },
}
