import extendWith from 'lodash/extendWith'
import React, { PropsWithChildren, useCallback, useMemo, useState, useEffect } from 'react'

import { analytics } from 'services/analytics'

import { ConsentsCookies } from './types'

type ConsentContextType = {
  cookies: ConsentsCookies
  setCookies: (cookies: ConsentsCookies) => void
  showWidget: boolean
  setShowWidget: (show: boolean) => void
  shouldNotTrackPersonalInfo: boolean
  setShouldNotTrackPersonalInfo: (shouldNotTrackPersonalInfo: boolean) => void
}

const INITIAL_CONSENTS_CONTEXT: ConsentContextType = {
  cookies: { mixpanel: false, microsoft_clarity: false },
  setCookies: () => null,
  showWidget: false,
  setShowWidget: () => null,
  shouldNotTrackPersonalInfo: true,
  setShouldNotTrackPersonalInfo: () => null,
}

export const ConsentsContext = React.createContext<ConsentContextType>(INITIAL_CONSENTS_CONTEXT)

export const ConsentsProvider: React.FC<PropsWithChildren> = (props) => {
  const [cookies, _setCookies] = useState<ConsentsCookies>(INITIAL_CONSENTS_CONTEXT.cookies)
  const [showWidget, setShowWidget] = useState<boolean>(INITIAL_CONSENTS_CONTEXT.showWidget)
  const [shouldNotTrackPersonalInfo, setShouldNotTrackPersonalInfo] = useState<boolean>(
    INITIAL_CONSENTS_CONTEXT.shouldNotTrackPersonalInfo
  )

  useEffect(() => {
    if (cookies.mixpanel) {
      setShouldNotTrackPersonalInfo(false)
    }
    analytics.enable()
  }, [cookies])

  const setCookies = useCallback(
    (newCookies: ConsentsCookies) => {
      _setCookies((previousCookies) => {
        const updatedCookies = extendWith(newCookies, previousCookies, (dest: any, src: any) =>
          dest === undefined ? src : dest
        )
        return updatedCookies
      })
    },
    [_setCookies]
  )

  const contextValue = useMemo(
    () => ({
      cookies,
      setCookies,
      showWidget,
      setShowWidget,
      shouldNotTrackPersonalInfo,
      setShouldNotTrackPersonalInfo,
    }),
    [
      cookies,
      setCookies,
      showWidget,
      setShowWidget,
      shouldNotTrackPersonalInfo,
      setShouldNotTrackPersonalInfo,
    ]
  )
  return <ConsentsContext.Provider value={contextValue}>{props.children}</ConsentsContext.Provider>
}
