import * as Sentry from 'sentry-expo'

import { Monitoring } from './types'

export const monitoring: Monitoring = {
  captureException(...params) {
    console.error(params[0])
    Sentry.Native.captureException(...params)
  },
}
