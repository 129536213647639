import * as SplashScreen from 'expo-splash-screen'
import { useCallback, useEffect, useMemo } from 'react'

SplashScreen.preventAutoHideAsync()

export function useSplashScreen() {
  useEffect(() => {
    SplashScreen.preventAutoHideAsync()
  }, [])

  const hideSplashScreen = useCallback(async () => {
    await new Promise((resolve) => setTimeout(resolve, 800))
    await SplashScreen.hideAsync()
  }, [])

  return useMemo(() => ({ hideSplashScreen }), [hideSplashScreen])
}
