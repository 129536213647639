import { Button } from 'native-base'
import React, { ComponentProps } from 'react'

type ButtonProps = ComponentProps<typeof Button> & {
  title?: string
}

export const ButtonSolid: React.FC<ButtonProps> = (_props) => {
  const { title, ...props } = _props
  const scheme = selectScheme.bind(null, props.colorScheme)
  const variantProps: ButtonProps = {
    borderColor: 'blueNight',
    backgroundColor: scheme({ primary: 'blueNight', secondary: 'white' }),
    borderWidth: 1,
    borderRadius: 'full',
    ...props,
    _pressed: {
      backgroundColor: scheme({ primary: 'coolGray.400', secondary: 'blueGray.300' }),
    },
    _text: {
      fontSize: 'md',
      color: scheme({ primary: 'white', secondary: 'blueNight' }),
      ...props._text,
    },
  }
  return (
    <Button {...variantProps} variant="solid">
      {title}
    </Button>
  )
}

export const ButtonOutline: React.FC<ButtonProps> = (_props) => {
  const { title, ...props } = _props
  const variantProps: ButtonProps = {
    backgroundColor: 'transparent',
    borderColor: 'grey',
    borderWidth: 1,
    borderRadius: 'lg',
    ...props,
    _text: {
      color: 'blueNight',
      fontSize: 'md',
      fontWeight: 'normal',
      ...props._text,
    },
  }
  return (
    <Button {...variantProps} variant="outline">
      {title}
    </Button>
  )
}

export const ButtonGhost: React.FC<ButtonProps> = (props) => {
  const { title, ...restOfProps } = props
  const variantProps: ButtonProps = {
    borderWidth: 0,
    _text: { color: 'darkGrey', fontWeight: 200 },
    _hover: { backgroundColor: 'grey', _text: { color: 'blueNight' } },
  }
  return (
    <Button {...restOfProps} {...variantProps} variant="ghost">
      {title}
    </Button>
  )
}

type ColorSchemeType = ButtonProps['colorScheme']

function selectScheme(colorScheme: ColorSchemeType, obj: { primary?: any; secondary?: any }) {
  // @ts-ignore : we can use the colorScheme as index
  return obj[colorScheme]
}
