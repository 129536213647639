import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

import { Color } from 'theme'

interface Props {
  colored: boolean
}

const HelpIcon = ({ colored }: Props) => (
  <Svg fill="none">
    <Path
      d="M17.93 6.526a8.248 8.248 0 0 1 0 8.95"
      stroke={colored ? Color.blueNight : Color.grey}
      strokeWidth={1.353}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="m13.127 6.922 2.369-2.878a.92.92 0 0 1 1.359-.066l1.167 1.167a.919.919 0 0 1-.066 1.36l-2.877 2.368M8.873 15.078l-2.368 2.878a.92.92 0 0 1-1.36.066l-1.167-1.167a.919.919 0 0 1 .066-1.36l2.878-2.368M6.922 8.872 4.044 6.504a.92.92 0 0 1-.066-1.36l1.167-1.167a.919.919 0 0 1 1.36.066l2.368 2.878M15.079 13.127l2.877 2.37a.92.92 0 0 1 .066 1.358l-1.167 1.167a.919.919 0 0 1-1.36-.066l-2.368-2.877"
      stroke={colored ? Color.blueNight : Color.grey}
      strokeWidth={1.353}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M15.475 17.931a8.248 8.248 0 0 1-8.95 0M4.069 6.525a8.248 8.248 0 0 0 0 8.95M15.475 4.07a8.248 8.248 0 0 0-8.95-.001"
      stroke={colored ? Color.blueNight : Color.grey}
      strokeWidth={1.353}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M14.251 7.748a4.599 4.599 0 1 1-6.504 6.504 4.599 4.599 0 0 1 6.504-6.504"
      stroke={colored ? Color.yellow : Color.grey}
      strokeWidth={1.353}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default HelpIcon
