import { locale } from 'expo-localization'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { enTranslations } from './translations/en'
import { frTranslations } from './translations/fr'

const resources = {
  en: { translation: enTranslations },
  fr: { translation: frTranslations },
}

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  resources,
  lng: locale,
  fallbackLng: 'fr', //language to use if translations in user language are not available
  interpolation: {
    escapeValue: false, // not needed for react
  },
  compatibilityJSON: 'v3', // need to solve this issue with android https://stackoverflow.com/questions/70493788/i18nextpluralresolver-your-environment-seems-not-to-be-intl-api-compatible-u
})

export default i18n
