import * as StoreReview from 'expo-store-review'

import { monitoring } from 'services/monitoring'
import { storage } from 'services/storage'
import { hasEnoughTimePassedBetweenDates } from 'utils/dates'

import { Review } from './types'

export const review: Review = {
  request: async () => {
    try {
      const laterDate = new Date()
      const earlierDate = await storage.getDate('@LastReviewRequestDate')
      const canReview =
        (await StoreReview.hasAction()) && hasEnoughTimePassedBetweenDates(laterDate, earlierDate)
      if (canReview) {
        await storage.saveDate('@LastReviewRequestDate', laterDate)
        await StoreReview.requestReview()
      }
    } catch (error) {
      monitoring.captureException(error)
    }
  },
}
